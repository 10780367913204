import axios from 'axios'
import { directUSAPIToken } from '../../../../_metronic/helpers/components/utils';
import {API_URL} from '../../../../_metronic/helpers/components/constant'

export const CLIENTS = `${API_URL}/items/clients`
export const CLIENTSADDRESS = `${API_URL}/items/client_address`
const headers = {
  'Authorization': `Bearer ${directUSAPIToken()}`
}
// Server should return AuthModel
export function ClientList(fields = null) {
  // console.log("filedss", fields)
  let param = "?fields="
  param = fields ? param + fields : param;
  return axios.get(`${CLIENTS}${param}`, {
    params: {},
    headers
  })
}

export function getClientDetailData(queryParam) {
  // console.log("filedss", fields)
  // let param = "?fields="
  // param = fields ? param+fields: param;
  return axios.get(`${CLIENTS}${queryParam}`, {
    params: {},
    headers
  })
}

export function getClientAddressDetailData(queryParam) {
  // console.log("filedss", fields)
  // let param = "?fields="
  // param = fields ? param+fields: param;
  return axios.get(`${CLIENTSADDRESS}${queryParam}`, {
    params: {},
    headers
  })
}

export function getClientsAddressList(queryParam) {
  return axios.get(`${CLIENTSADDRESS}${queryParam}`, {
    params: {},
    headers
  })
}

export function ClientAddessList(fields = null, filter = null) {
  // console.log("filedss", fields)
  // let filters = ''
  let param = "?filter[partner][_nnull]=true&[client][_nnull]=true&fields="
  param = fields ? param + fields : param;
  param = filter ? filter + fields : param;
  return axios.get(`${CLIENTSADDRESS}${param}`, {
    params: {},
    headers
  })
}

export function Create(param) {
  return axios.post(CLIENTS, param, { headers })
}
export function CreateClientwithPartner(param) {
  return axios.post(CLIENTSADDRESS, param, { headers })
}

export function UpdateClientwithPartner(param, id) {
  delete param.id
  return axios.patch(`${CLIENTSADDRESS}/${id}`, param, { headers })
}

export function UpdateClient(param, id) {
  delete param.id
  return axios.patch(`${CLIENTS}/${id}`, param, { headers })
}
