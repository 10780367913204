import axios from 'axios'
import { AuthModel } from '../models/AuthModel'
import { UserModel } from '../models/UserModel'
import { directUSAPIToken } from '../../../../_metronic/helpers/components/utils';
import { API_URL } from '../../../../_metronic/helpers/components/constant'
// const API_URL = "https://assetapi.inspirepro.co.in"

export const GET_USER_BY_accessToken_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const AUTH_LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const CURRENT_USER = `${API_URL}/users/me?fields[]=id,country,city,address,phone_number,email,first_name,last_name,role.name,role.id,role.description,client,partner.partner_name,partner.company_org_no,partner.id,client.id,client.name,client.clients_org_no`

// Server should return AuthModel
export function login(email: string, password: string) {

  return axios.post(AUTH_LOGIN_URL, {
    email: email,
    password: password,
    mode: 'cookie'
  })
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.get<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    params: {
      email: email,
    },
  })
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(CURRENT_USER)
  // return axios.get<UserModel>(GET_USER_BY_accessToken_URL)
}
