/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { AsideMenuItem } from './AsideMenuItem'
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'

export default function AsideMenuMain() {
  const intl = useIntl()
  const { data } = useSelector(({ auth }) => auth.user);

  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    if (data && data.id && data.role) {
      setUserInfo(data)
    }
  }, [data])

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen008.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/agreements'
        icon='/media/icons/duotune/general/gen054.svg'
        title='Agreements'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/assets'
        icon='/media/icons/duotune/electronics/elc001.svg'
        title='Assets'
        fontIcon='bi-layers'
      />
      {
        (userInfo?.role?.name === 'master_admin' || userInfo?.role?.name === 'admin_user' || userInfo?.role?.name === 'partner_admin' || userInfo?.role?.name === 'partner_user') &&
        <AsideMenuItem
          to='/assettypes'
          icon='/media/icons/duotune/abstract/abs039.svg'
          title='Asset Type'
          fontIcon='bi-layers'
        />
      }

      {
        (userInfo?.role?.name === 'client_admin' || userInfo?.role?.name === 'client_user' || userInfo?.role?.name === 'master_admin' || userInfo?.role?.name === 'admin_user') && <AsideMenuItem
          to='/partners'
          icon='/media/icons/duotune/abstract/abs039.svg'
          title='Partner'
          fontIcon='bi-layers'
        />
      }
      {
        (userInfo?.role?.name === 'master_admin' || userInfo?.role?.name === 'partner_admin' || userInfo?.role?.name === 'partner_user' || userInfo?.role?.name === 'admin_user') &&
        <AsideMenuItem
          to='/clients'
          icon='/media/icons/duotune/general/gen019.svg'
          title='Client'
          fontIcon='bi-layers'
        />
      }

      <AsideMenuItem
        to='/suppliers'
        icon='/media/icons/duotune/layouts/lay006.svg'
        title='Suppliers'
        fontIcon='bi-layers'
      />
      {
        (userInfo?.role?.name === 'client_admin' || userInfo?.role?.name === 'client_user') &&
        <AsideMenuItem
          to={`/clientsusers/${userInfo.client.id}/${userInfo.id}`}
          icon='/media/icons/duotune/electronics/elc001.svg'
          title='Users'
          fontIcon='bi-layers'
        />
      }
      {
        (userInfo?.role?.name === 'partner_admin' || userInfo?.role?.name === 'partner_user') &&
        <AsideMenuItem
          to={`/partnersusers/${userInfo.partner.id}/${userInfo.id}`}
          icon='/media/icons/duotune/electronics/elc001.svg'
          title='Users'
          fontIcon='bi-layers'
        />
      }
      {
        (userInfo?.role?.name === 'master_admin') &&
        <AsideMenuItem
          to={`/adminusers/${userInfo.id}`}
          icon='/media/icons/duotune/electronics/elc001.svg'
          title='Users'
          fontIcon='bi-layers'
        />
      }

      <div className='menu-item'>
        {/* <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span> */}
        <Link className='menu-link my-account' to="#">
          <Button variant="contained">
            <span className='menu-title'>My Account</span>
          </Button>
        </Link>

      </div>
    </>
  )
}
