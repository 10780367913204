import axios from 'axios'
import { directUSAPIToken } from '../../../../_metronic/helpers/components/utils';
import {API_URL} from '../../../../_metronic/helpers/components/constant'

export const ASSETS = `${API_URL}/items/Asset`
const headers = {
  'Authorization': `Bearer ${directUSAPIToken()}`
}
// Server should return AuthModel
export function getassetsList(queryParam) {
  return axios.get(`${ASSETS}${queryParam}`, {
    params: {},
    headers
  })
}

export function CreateAsset(param) {
  delete param.id
  return axios.post(ASSETS, param, { headers })
}
export function UpdateAsset(param, id) {
  // console.log("param ", param)
  delete param.id
  return axios.patch(`${ASSETS}/${id}`, param, { headers })
}

export function assetDetail(queryParam) {
  return axios.get(`${ASSETS}${queryParam}`, {
    params: {},
    headers
  })
}
