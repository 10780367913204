
import React, { useRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Asset from '../../../../_metronic/helpers/components/aggrid/index';
import { SVformatter } from '../../../../_metronic/helpers/components/utils';

const useStyles = makeStyles(theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    dialogRoot: {
        width: '600px',
        height: '450px',
        display: 'flex',
        flexDirection: 'column',
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '40px',
        background: 'linear-gradient(to right, #eef2f3, #8e9eab)',
        boxShadow: '1px 1px 3px #8e9eab',
    },
    dialogTitleText: {
        display: 'flex',
        justifyContent: 'space-between',
        // fontFamily: "'Poppins'",
        fontWeight: 700,
        textTransform: 'uppercase',
        fontSize: '0.85rem',
    },
    dialogContent: {
        //   marginTop: '10px',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '50%',
        marginBottom: '15px',
        marginRight: '15px',
    },
    button: {
        marginLeft: '15px',
    },
    buttonProgress: { marginLeft: '5px' },
    actionIcon: {
        fontSize: '1.25rem',
        cursor: 'pointer',
    },
    stepContent: {
        width: '100%',
    },
    steps: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        //   paddingLeft: '20px',
    },
    stepsText: {
        marginTop: '15px',
        // fontFamily: 'Poppins',
        fontSize: '20px',
    },
    stepsTextError: {
        marginTop: '15px',
        // fontFamily: 'Poppins',
        fontSize: '20px',
        color: 'red',
        textAlign: 'center',
    },
    stepsSubText: {
        marginTop: '5px',
        // fontFamily: 'Poppins',
        fontSize: '12px',
    },
    uploadArea: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#ababab',
        '&:hover': {
            color: '#003366',
            cursor: 'pointer',
        },
    },
    uploadIcon: {
        width: '6rem !important',
        height: '6rem !important',
        // color: '#cdcdcd',
        // '&:hover': {
        //   color: '#003366',
        //   cursor: 'pointer',
        // },
    },
    successIcon: {
        color: '#003366',
        marginBottom: '15px',
    },
    errorIcon: {
        color: '#eb8034',
        marginBottom: '15px',
    },
    successArea: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    spinnerArea: {
        marginBottom: '10px',
    },
    animatedItem: {
        animation: `$myEffect 300ms ${theme.transitions.easing.easeInOut}`,
    },
    '@keyframes myEffect': {
        '0%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },
    recordsCount: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: 500,
        fontSize: '14px',
        marginBottom: '10px'
    }
}));

export default function AssetDialog({
    open,
    setOpen,
    searchValue
}) {
    const classes = useStyles();
    const [isloading, setIsloading] = useState(true)
    useEffect(() => {
        setIsloading(false)
    }, [searchValue])
    // const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');
    const fileUploader = useRef(null);

    const columns = [
        {
            title: `Action`,
            field: 'actions',
        },
        {
            title: 'Asset Id',
            field: 'id',
        },
        {
            title: 'AG. ref',
            filterPlaceholder: 'Agreement',
            field: 'agrement_info_no',
            valueGetter: function (params) {
                return params?.data?.aggrement?.agrement_info_no && (params.data.aggrement?.agrement_info_no);
            },
        },
        {
            title: 'Productgroup',
            filterPlaceholder: 'Productgroup',
            field: 'product_group',
        },
        {
            title: 'product',
            filterPlaceholder: 'product',
            field: 'name',
        },
        {
            title: 'Serial Number',
            filterPlaceholder: 'Serial Number',
            field: 'serial_number',
        },
        {
            title: 'Acquisition value(kr)',
            filterPlaceholder: 'Acquisition value(kr)',
            field: 'price_total',
        },
        {
            title: 'Rent(kr)',
            filterPlaceholder: 'Rent(kr)',
            field: 'rent_period',
        },
        {
            title: 'Lease Start',
            filterPlaceholder: 'Lease Start',
            field: 'lease_start_date',
            type: 'date',
            valueGetter: function (params) {
                return params?.data?.aggrement?.lease_start_date && (params?.data?.aggrement?.lease_start_date);
            },
        },
        {
            title: 'Lease End',
            filterPlaceholder: 'Lease End',
            field: 'eol_date',
            type: 'date',
            valueGetter: function (params) {
                return params?.data?.aggrement?.eol_date && (params?.data?.aggrement?.eol_date);
            },
        },
        {
            title: 'No of monthe lease',
            filterPlaceholder: 'No of monthe lease',
            field: 'no_of_month_lease',
            valueGetter: function (params) {
                return params?.data?.aggrement?.no_of_month_lease && (params?.data?.aggrement?.no_of_month_lease);
            },
        },
        {
            title: 'EOL value',
            filterPlaceholder: 'EOL value',
            field: 'EOL_value_amt',
            valueGetter: function (params) {
                let values = 0
                if (params?.data?.aggrement && params?.data?.aggrement?.EOL_value_amt) {
                    values = SVformatter(params?.data?.aggrement?.EOL_value_amt)
                }
                return values;
            }

        },
        {
            title: 'Insurance',
            filterPlaceholder: 'Insurance',
            field: 'insurance_company'

        },
        {
            title: 'Bank',
            filterPlaceholder: 'Bank',
            field: 'supplier_name'
        },
        {
            title: 'Username',
            field: 'user_name',
            editable: true
        },
        {
            title: 'User email',
            field: 'user_email',
            editable: true,
        },
        {
            title: 'User phone no',
            field: 'user_phoneno',
            editable: true,
        },
        {
            title: 'Cost centre',
            field: 'cost_centre',
            editable: true,
            bulk_update: true
        },
        {
            title: 'Created By',
            filterPlaceholder: 'Created By',
            field: 'created_user_by',
            valueGetter: (params) => {
                // console.log("params.data", params.datas)
                return params.data?.user_created?.first_name + ' ' + params.data?.user_created?.last_name
            },

        }
    ];

    function handleClickOpen() {
        setOpen(true);
    }

    const handleClose = () => {
        console.log("closeeeeee")
        setOpen(false);
    }


    return (
        <React.Fragment>
            {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Open max-width dialog
      </Button> */}
            <Dialog
                fullWidth={'xl'}
                // maxWidth={maxWidth}
                // TransitionComponent={Transition}
                open={open}
                disableBackdropClick
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                maxWidth='xl'
            >
                {/* <DialogTitle id="max-width-dialog-title">Asset File Upload</DialogTitle> */}
                <DialogContent>
                    <Asset
                        isloading={isloading}
                        page={'searchmodal'}
                        searchString={searchValue}
                        createPath={false}
                        handleClose={handleClose}
                        searchcolumns={columns}
                        createTitle={false}
                        setOpen={setOpen}

                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" variant="contained">
                        Close
          </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
