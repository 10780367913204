import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { SVformatter } from './utils';
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

const TAX_RATE = 0.07;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  card: {
    minWidth: 275,
  },
  table: {
    minWidth: 400,
  },
}));

function ccyFormat(num) {
  return `${SVformatter(num.toFixed())}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, price) {
  return { desc, price };
}


export default function SpanningTable({
  totalAssetsList
}) {
  const [productSubtotal, setproductSubtotal] = useState(0)
  const [pieChartData, setPieChartData] = useState([])
  const [totalAssetssum, setTotalAssetssum] = useState([])

  const [noOfAggByPartner, setNoOfAggByPartner] = useState([])
  const [sumPurchaseAmountByPartner, setSumPurchaseAmountByPartner] = useState([])

  const classes = useStyles();
  // const [totalAssets, setTotalAssets] = useState(0)
  // const [upcomingrents, setUpcomingrents] = useState([])
  // const [totalAssetsList, setTotalAssetsList] = useState([])
  // const { user } = useSelector(({ auth }) => auth);
  useEffect(() => {
    if (totalAssetsList) {
      // console.log("totalAssetsList", totalAssetsList)
      let noofAggByPartner = [];
      let sumPurchaseAmountByPartner = [];
      let total_Assetssum = [];
      const result = _(totalAssetsList)
        .groupBy('product_group')
        .map(group => ({
          name: group[0].product_group || 'Other',
          "value": _.countBy(group, 'product_group')[group[0].product_group],
        }))
        .value();
      setPieChartData(result)
      total_Assetssum = _(totalAssetsList)
        .groupBy('product_group')
        .map(group => ({
          name: group[0].product_group || 'Other',
          "value": _.sumBy(group, x => x.price_total ? parseFloat(x.price_total) : 0),
        }))
        .value();
      let subtotal = total_Assetssum.map(({ value }) => value).reduce((sum, i) => sum + i, 0);
      setTotalAssetssum(total_Assetssum)
      // const invoiceSubtotal = subtotal(totalAssetssum);
      // console.log("invoiceSubtotal", invoiceSubtotal)
      setproductSubtotal(subtotal)
    }
  }, [totalAssetsList])
  // function subtotal(items) {
  //   console.log("itemmm", items)
  //   return items.map(({ value }) => value).reduce((sum, i) => sum + i, 0);
  // }

  return (
    <div className={`card card-custom gutter-b ${classes.card}`}>
      <div className='card-body dasboard-overflow-auto'>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="text-bold" style={{ width: "180px" }}>Product Group</TableCell>
              <TableCell className="text-bold" align="left">Financing Volume</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {totalAssetssum && totalAssetssum.map(row => (
              <TableRow key={row.name}>
                <TableCell>{row.name}</TableCell>
                <TableCell align="right">{ccyFormat(row.value)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={1}><span className="font-weight-bolder">Total Amount</span></TableCell>
              <TableCell className="font-weight-bolder" align="right">{ccyFormat(productSubtotal)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div>
  );
}