import clsx from 'clsx'
import React, { FC, useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, QuickLinks, Search } from '../../../partials'
import { useLayout } from '../../core'
import Badge from '@mui/material/Badge';
import Input from '@material-ui/core/Input';
import AssetDialog from '../../../../app/modules/assets/components/ModalDialog';
import { useSelector, shallowEqual } from "react-redux";

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px'

export default function Topbar() {
  const { config } = useLayout()
  const [modalOpen, setModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const { data } = useSelector(({ auth }) => auth.user);
  const [loggeduser, setLoggedUser] = useState(data)

  const afterSubmission = (event) => {
    event.preventDefault();
    setModalOpen(true)
    // console.log("searchValue inside", searchValue)
  }

  useEffect(() => {
    if (data && data.role) {
      // console.log("loggeduser?.role?.description", loggeduser?.role)
      setLoggedUser(data)
    }
  }, [data])

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  // console.log("searchValue", searchValue)

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Search */}
      <div className={clsx('d-flex align-items-stretch topbar', toolbarButtonMarginClass)}>
        {/* <Search /> */}

        <div className="container">
          <form
            autoComplete="off"
            // className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            // noValidate
            // id='kt_login_signup_form'
            onChange={handleChange}
            name="searchvalue"
            onSubmit={afterSubmission}
          >
            <Input
              id="adornment-amount"

              placeholder='Search...' className='js-search'
              // onChange={handleChange()}
              startAdornment={<i onClick={afterSubmission} className="fas fa-search" />}
            />
          </form>
          {/* <input placeholder='Search...' className='js-search' type="text" />
          <i className="fa fa-search"></i> */}
        </div>
      </div>
      {/* Activities */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
      </div>
      {/* Quick links */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>

      </div>

      {/* CHAT */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>

      </div>

      {/* NOTIFICATIONS */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {/* begin::Menu- wrapper */}
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          {/* <Badge badgeContent={4} color="primary">
            <i className="fas fa-bell" />
          </Badge> */}
          {/* <KTSVG
            path='/media/icons/duotune/general/gen022.svg'
            className={toolbarButtonIconSizeClass}
          /> */}
        </div>
        {/* <HeaderNotificationsMenu /> */}
        {/* end::Menu wrapper */}
      </div>
      <div className="vertical_line vertical_line_left"></div>

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div className="topbar-item"
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'>
          <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 profile-box">
            <span className="font-size-base d-none d-md-inline mr-3">
              {
                (loggeduser?.partner || loggeduser?.client) ? <>
                  {loggeduser?.partner && <span className="menu_username">{loggeduser ? loggeduser.partner.partner_name : null}</span>}
                  {loggeduser?.client && <span className="menu_username">{loggeduser ? loggeduser.client.name : null}</span>}
                  <br />
                  <span className="email">{loggeduser && loggeduser?.role?.description}</span>

                </> : <span className="menu_username">{loggeduser && loggeduser?.role?.description}</span>
              }

              {/* <span className="menu_username">{userDetail ? userDetail && userDetail.role?.name === "Administrator" ? 'Super Admin' : userDetail.first_name +" "+ userDetail.last_name : 'Super Admin'}</span> */}
            </span>
            {/* <span className="symbol symbol-35 symbol-light-success">
              <span className="symbol-label font-size-h5 font-weight-bold">
                S
                </span>
            </span> */}
          </div>
          {/* <div>
            rtytrytry
          </div> */}
        </div>
        {/* <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img src={toAbsoluteUrl('/media/avatars/150-26.jpg')} alt='metronic' />
          <span className='menu-title' style={{paddingLeft: "10px"}}>{'Super Admin'}</span>
        </div> */}
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
      <AssetDialog
        open={modalOpen}
        searchValue={searchValue}
        setOpen={setModalOpen}
      />
    </div>
  )
}