// import { makeStyles, createStyles } from '@material-ui/core/styles';
// import {
//   EyeFilled,
//   PlusOutlined,
//   DeleteFilled,
//   SearchOutlined,
//   OrderedListOutlined,
//   EditFilled,
//   DownOutlined,
//   CheckCircleFilled,
//   CloseCircleFilled
// } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
// import styles from '../style.less';
// import { message, Col, Modal, Upload, Button, Icon, Spin } from 'antd';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// import CustomDialog from './CustomDialog';

// const useStyles = makeStyles((theme) =>
//   createStyles({
//     root: {
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       height: '100% !important',
//       width: '100%',
//       color: 'red',
//     },
//     icon: {
//       height: '15px',
//     },
//     // editIcon: {
//     //   color: theme.primary,
//     // },
//     deleteIcon: {
//       color: 'red',
//       height: '20px',
//     },
//   })
// );
const ActionCellRenderer = (props) => {
  // console.log('action cell ', props, props.api.getEditingCells());
  // const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [open, setOpen] = useState(false);
  const { data } = useSelector(({ auth }) => auth.user);
  const [userPermission, setUserPermission] = useState({
    canEdit: false,
    canDelete: false,
    canView: false
  });
  const [userInfo, setUserInfo] = useState(data);
  const [loggedUserRole, setLoggedUserRole] = useState();

  useEffect(() => {
    setEditMode(
      props.api.getEditingCells().length > 0
        ? props.api.getEditingCells()[0].rowIndex === props.node.rowIndex
        : false
    );
  }, [props]);

  const handleEdit = () => {
    console.log(" props.node", props.node.data.id)
    setEditMode(true);
    // props.api.startEditingCell({
    //   rowIndex: props.node.rowIndex,
    //   colKey: props.colDef.cellRendererParams.colKey
    //     ? props.colDef.cellRendererParams.colKey
    //     : 'quantity',
    // });
  };

  const handleDone = () => {
    setEditMode(false);
    props.api.stopEditing();
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (userInfo && userInfo.id && userInfo?.role?.name) {
      setLoggedUserRole(userInfo.role.name)
      setUserInfo(userInfo)
    }
  }, [userInfo])

  useEffect(() => {
    if (props && loggedUserRole && userInfo) {
      // console.log("***********", props.node.data)
      if (loggedUserRole === 'partner_user' || loggedUserRole === 'partner_admin') {
        setUserPermission({
          canImport: false
        })
      }
      // console.log(authUser, "userPermission && userPermission.canEdit", props.node.data.created_userid)
      let authUser = userInfo ? userInfo.id : userInfo.id;
      let created_user = props.node.data.user_created ? props.node.data.user_created.id : '';
      // let partner_id = props.node.data.partner ? props.node.data.partner.id : Number(props.node.data.partner.id);
      // let clients_id = props.node.data.client ? props.node.data.client.id : Number(props.node.data.client.id);
      let user_company_id = ''
      // console.log("loggedUserRole", loggedUserRole)
      // console.log("created_user", created_user)
      // console.log("authUser", authUser)

      if (loggedUserRole === 'partner_admin' || loggedUserRole === 'partner_user') {
        user_company_id = userInfo.partner ? userInfo.partner.id : ''

        if (((authUser) === (created_user) || loggedUserRole === 'partner_admin')) {
          // console.log("innnnn")

          setUserPermission({
            canEdit: true,
            canDelete: true,
            canView: true
          })
        } else {
          setUserPermission({
            canView: true
          })
        }

      } else if (loggedUserRole === 'client_admin' || loggedUserRole === 'client_user') {
        user_company_id = userInfo.client ? userInfo.client.id : ''
        if ((authUser) === (created_user)) {
          setUserPermission({
            canEdit: true,
            canDelete: true,
            canView: true
          })
          if (props.node.data.totalAssets !== 0) {
            setUserPermission({
              canEdit: true,
              canDelete: false,
              canView: true
            })
          }
        } else {
          setUserPermission({
            canView: true
          })
        }
        //
      } else if (loggedUserRole === 'master_admin' || loggedUserRole === 'master_user') {
        // console.log("props.node.data", props.node.data)
        setUserPermission({
          canEdit: true,
          canDelete: true,
          canView: true
        })
      }
    }
  }, [props, loggedUserRole, userInfo])


  const deleteConfirm = () => {
    props.agGridReact.props.deleteConfirm(props.node);
  };

  const handleView = () => {
    props.agGridReact.props.gotoEdit(props, 'view');
  };
  return (
    <div>
      {/* <i className="fas fa-edit" /> */}
      {
        (userPermission && userPermission.canView) && <i onClick={() => handleView()} className="fas fa-eye" />
      }

      {(userPermission && userPermission.canEdit) && (
        <Link className="viewbtn" to={`${"/updateagreement/" + props.node.data.id}`} ><i className="fas fa-edit" /></Link>

      )}
      {(userPermission && userPermission.canDelete) && <i className="fas fa-trash-alt deleteone" onClick={() => deleteConfirm()} />}
    </div>
  );
};

export default ActionCellRenderer;
