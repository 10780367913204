import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Select, MenuItem } from "@material-ui/core";

import { Link, useHistory } from 'react-router-dom'
// import FileUpload from './components/FileUpload';
import moment from "moment";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import _ from "lodash";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { Select as MatSelect } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FileUpload from "../../../../app/modules/assets/components/FileUpload"
import BulkUpdateDialog from './components/BulkUpdateDialog';
import CustomDropdown from './components/CustomDropdown';
import TextField from '@material-ui/core/TextField';
import NotesDialog from './components/NotesDialog'
import { directUSAPICall, SVPercentageformatter, SVformatter, range, generateExcel } from "../utils"
import { AGREEMENTFIELDS, ASSETSFIELDS, ASSETSFIELDS1, ASSETAGGREMENTFIELDS, ASSETSTATUS } from "../constant";
import DeleteConfirmDialog from "../../components/deleteConfirm"
import { ClientAddessList } from '../../../../app/modules/client/redux/ClientCrud'
import Checkbox from "@material-ui/core/Checkbox";
import { useStyles } from "../aggrid/components/utils";
import { useParams } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PageLoader from "../PageLoader";
import AssetViewDialog from '../../../../app/modules/assets/components/AssetViewDialog'
import Tooltip from '@mui/material/Tooltip';
import ActionCellRenderer from './components/ActionCellRenderer';

export default function AssetForm(props) {
  const { title,
    createTitle,
    createPath,
    searchString,
    page,
    searchcolumns,
    setOpen
  } = props;
  const classes = useStyles();
  const history = useHistory()
  const urlParams = useParams();
  const overlayLoadingTemplate =
    '<span className="ag-overlay-loading-center">Loading, Please Wait...</span>';
  const noRowsTemplate =
    '<span className="ag-overlay-loading-center">No records found...</span>';
  const [showNotesDialog, setShowNotesDialog] = useState(false);
  const [fileUploadOpen, setFileUploadOpen] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  const [loading, setLoading] = useState(true);
  const [assetsListData, setAssetsListData] = useState([]);
  const [gridApi, setGridApi] = useState();
  const [quickFilterText, setQuickFilterText] = useState('');
  const [agreementsInfoList, setAgreementsInfoList] = useState()
  const [rowDataAPI, setRowDataAPI] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [enableBulkUpdates, setEnableBulkUpdates] = useState(false);
  const [selectedAssetType, setSelectedAssetType] = useState('All');
  const externalFilterRef = useRef(null);
  const [assetTypes, setAssetTypes] = useState([]);
  const [assetTypeFieldMapping, setAssetTypeFieldMapping] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0)
  const [dailRent, setDailRent] = useState(0)
  const [Interim_rent_cost, setInterim_rent_cost] = useState(0)
  const [rentPeriod, setRentPeriod] = useState(0)
  const [bulkUpdateOpen, setBulkUpdateOpen] = useState(false);
  const [bulkupdatedData, setBulkupdatedData] = useState([]);
  const [tablecolumns, setTableColumns] = useState([])
  const [selectedAsset, setSelectedAsset] = useState();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState([]);
  const [statusNames, setStatusNames] = useState()
  const { data } = useSelector(({ auth }) => auth.user);
  const [userInfo, setUserInfo] = useState(data);
  const [partnersValues, setPartnersValues] = React.useState([]);
  const [clientsValues, setClientsValues] = React.useState([]);
  const [values, setValues] = useState({
    partner: null,
    client: null,
    aggrement: null
  })
  const [filterOptions, setFilterOptions] = useState('')
  const [agreementList, setAgreementList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [showAssetDetail, setShowAssetDetail] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState('');
  const [loggedUserRole, setLoggedUserRole] = useState();
  const [userPermission, setUserPermission] = useState({
    canEdit: true,
    canDelete: true,
    canView: true,
    canAdd: true,
    canImport: true,
    canBulkDelete: true,
    canImport: true,
    canBulkUpdate: true
  });
  const [hideColumns, setHideColumns] = useState(false);

  useEffect(() => {
    if (!gridApi) {
      return;
    }
    if (localStorage.getItem("selectedAssetType")) {
      setSelectedAssetType((localStorage.getItem("selectedAssetType")))
    }
    if (gridApi.columnController) {
      let allColumnIds = gridApi.columnController.gridColumns.map(
        (col) => col.colId
      );
      let currentMapping = [];
      if (assetTypeFieldMapping && assetTypeFieldMapping.length > 0) {
        currentMapping = assetTypeFieldMapping.find(
          (mapping) => mapping.asset_name.toLowerCase() === selectedAssetType.toLowerCase()
        );

      }

      let columnsToShow = currentMapping ? currentMapping.fields : [];

      if (!columnsToShow || columnsToShow.length === 0) {
        gridApi.columnController.setColumnsVisible(allColumnIds, true);
        return;
      }

      if (columnsToShow.length > 0) {
        columnsToShow.push('actions', 'id');
      }
      // columnDefs.push({ field: 'SOME RANDOM', headerName: 'SOME RANDOM' });
      gridApi.columnController.setColumnsVisible(allColumnIds, false);
      gridApi.columnController.setColumnsVisible(columnsToShow, true);
    }

  }, [gridApi, assetTypeFieldMapping, selectedAssetType]);
  // gridApi && gridApi.setColumnDefs({ field: 'SOME_RANDOM', title: 'SOME RANDOM' });


  const externalFilterChanged = (value) => {
    setSelectedAssetType(value);
  };

  let columns = [{
    title: `Action`,
    field: 'actions',
  }, {
    title: 'Import ID',
    field: 'import_id',
  }, {
    title: 'Asset ID',
    field: 'id',
  }, {
    title: 'Agreement Ref',
    field: 'agrement_info_no',
    editable: true,
    bulk_update: true,
    valueGetter: function (params) {
      return params?.data?.aggrement?.agrement_info_no && (params.data.aggrement?.agrement_info_no);
    },
  },
  {
    title: 'Type of agreements',
    field: 'typeofaggrements',
    editable: false,
    valueGetter: function (params) {
      return params?.data?.aggrement?.typeofaggrements && (params.data.aggrement?.typeofaggrements);
    },
  },
  {
    title: 'Sub Agree. ref',
    field: 'sub_agrement_ref',
    editable: false,
    valueGetter: function (params) {
      return params?.data?.aggrement?.sub_agrement_ref && (params.data.aggrement?.sub_agrement_ref);
    },
  },
  {
    title: 'Partner',
    field: 'partner_name',
    editable: false,
    valueGetter: function (params) {

      return params?.data?.aggrement?.partner && (params.data.aggrement?.partner?.partner_name);
    },
  },
  {
    title: 'Partner Org',
    field: 'company_org_no',
    editable: false,
    valueGetter: function (params) {
      return params?.data?.aggrement?.partner && (params.data.aggrement?.partner?.company_org_no);
    },
  },
  {
    title: 'Client',
    field: 'client_name',
    editable: false,
    valueGetter: function (params) {
      return params?.data?.aggrement?.client && (params.data.aggrement.client.name);
    },
  }, {
    title: 'Client Org',
    field: 'clients_org_no',
    editable: false,
    valueGetter: function (params) {
      return params?.data?.aggrement?.client && (params.data.aggrement.client.clients_org_no);
    },
  }, {
    title: 'Supplier ',
    filterPlaceholder: 'Supplier ',
    field: 'supplier_name',
    valueGetter: function (params) {
      let name = ''
      if (params.data.aggrement && params.data.aggrement.founding_partner) {
        name = params.data.aggrement.supplier_name = params.data.aggrement.founding_partner.supplier_name
      }
      return name
    }

  }, {
    title: 'Supplier org',
    filterPlaceholder: 'Supplier org',
    field: 'supplier_org_no',
    valueGetter: function (params) {
      let org = ''
      if (params.data.aggrement && params.data.aggrement.founding_partner) {
        org = params.data.aggrement.supplier_org_no = params.data.aggrement.founding_partner.supplier_org_no
      }
      return org
    }
  }, {
    title: 'Lease start',
    field: 'lease_start_date',
    type: 'date',
    editable: false,
    valueGetter: function (params) {
      return params?.data?.aggrement?.lease_start_date && (params?.data?.aggrement?.lease_start_date);
    },
  }, {
    title: 'Lease end',
    field: 'eol_date',
    type: 'date',
    editable: false,
    valueGetter: function (params) {
      return params?.data?.aggrement?.eol_date && (params?.data?.aggrement?.eol_date);
    },
  }, {
    title: 'Agreement Status',
    field: 'ag_status',
    editable: false,
    valueGetter: function (params) {
      return params?.data?.aggrement?.ag_status && (params?.data?.aggrement?.ag_status);
    },
  }, {
    title: 'Exit Options',
    field: 'exit_options',
    editable: false,
    valueGetter: function (params) {
      return params?.data?.aggrement?.exit_options && (params?.data?.aggrement?.exit_options);
    },
  }, {
    title: 'Invoicing to',
    field: 'invoicing_to',
    editable: false,
    valueGetter: function (params) {
      return params?.data?.aggrement?.invoicing_to && (params?.data?.aggrement?.invoicing_to);
    },
  }, {
    title: 'Original Sign Date',
    field: 'original_sign_date',
    editable: false,
    type: 'date',
    valueGetter: function (params) {
      return params?.data?.aggrement?.original_sign_date && (params?.data?.aggrement?.original_sign_date);
    },
  },
  {
    title: 'Leasing type',
    field: 'type_of_leasing',
    editable: false,
    type: "dropdown",
    valueGetter: function (params) {
      return params?.data?.aggrement?.type_of_leasing && (params?.data?.aggrement?.type_of_leasing);
    },
  },
  {
    title: 'No of moth lease',
    field: 'no_of_month_lease',
    editable: false,
    valueGetter: function (params) {
      return params?.data?.aggrement?.no_of_month_lease && (params?.data?.aggrement?.no_of_month_lease);
    },
  },
  {
    title: 'Monthly payment ratio(%)',
    field: 'montly_payment_ratio',
    editable: false,
    // type: 'SVPercentageformatter',
    valueGetter: function (params) {
      return params?.data?.aggrement?.montly_payment_ratio ? (params?.data?.aggrement?.montly_payment_ratio) : '';
    },
  },
  {
    title: 'Payment interval',
    field: 'billing_period',
    editable: false,
    valueGetter: function (params) {
      return params?.data?.aggrement?.billing_period && (params?.data?.aggrement?.billing_period);
    },
  },
  {
    title: 'Delievery approval date',
    field: 'date_of_delievery_approval',
    type: 'date',
    bulk_update: true,
    editable: true
  },
  {
    title: 'Quantity',
    field: 'no_of_quantity',
    editable: true,
    bulk_update: false
  },
  {
    title: 'Price per units',
    field: 'priceper_unit',
    editable: true,
    bulk_update: true,
    type: 'currencyColumn'

  },
  {
    title: 'Price total',
    field: 'price_total',
    editable: false,
    type: 'currencyColumn'

  },
  {
    title: 'Rent / period',
    field: 'rent_period',
    editable: false,
    type: 'currencyColumn'

  },
  {
    title: 'Daily rent',
    field: 'dailyrent',
    editable: false,
    type: 'currencyColumn'

  },
  {
    title: 'Interim no of days',
    field: 'Interim_no_of_days',
    editable: false,
    render: (item) => {
      return <div className="enableEllips">{item ? item : ''}</div>;
    }
  }, {
    title: 'Interim rent',
    field: 'Interim_rent_cost',
    editable: false,
    type: 'currencyColumn'

  },
  {
    title: 'Product Group',
    field: 'product_group',
    editable: true,
    bulk_update: true
  }, {
    title: 'Created By',
    field: 'user_created',
    editable: false,
    valueGetter: (params) => {
      return params.data?.user_created?.first_name + ' ' + params.data?.user_created?.last_name
    },
  }, {
    title: 'Created at',
    field: 'date_created',
    editable: false,
    type: 'date'
  }, {
    title: 'Updated By',
    field: 'user_updated',
    editable: false,
    valueGetter: (params) => {
      return params.data?.user_updated ? params.data?.user_updated?.first_name + ' ' + params.data?.user_updated?.last_name : null
    },
  }, {
    title: 'Updated at',
    field: 'date_updated',
    editable: false,
    type: 'date'
  }, {
    title: 'Brand',
    field: 'brand',
    editable: true,
    bulk_update: true
  }, {
    title: 'Model',
    field: 'model',
    editable: true,
    bulk_update: true
  },
  {
    title: 'Client own product group',
    field: 'client_own_product_group',
    editable: true,
    bulk_update: true
  },
  {
    title: 'Status',
    field: 'asset_status',
    editable: true,
    bulk_update: true
  },
  {
    title: 'Product Description',
    field: 'product_description',
    editable: true,
    bulk_update: true
  },
  {
    title: 'Part no',
    field: 'partno',
    editable: true,
    bulk_update: true

  },
  {
    title: 'IMEI',
    field: 'IMEI',
    editable: true,
    bulk_update: false

  }, {
    title: 'Processor',
    field: 'processor',
    editable: true,
    bulk_update: false

  }, {
    title: 'Memory',
    field: 'memory',
    editable: true
  }, {
    title: 'Serial Number',
    field: 'serial_number',
    editable: true,
    bulk_update: false

  }, {
    title: 'Harddisk',
    field: 'harddisk',
    editable: true
  }, {
    title: 'Screen Size',
    field: 'screen_size',
    editable: true,
  },
  {
    title: 'Insurance Company',
    field: 'insurance_company',
    editable: true,
    bulk_update: true
  },
  {
    title: 'Insurance Until',
    field: 'insurance_until',
    editable: true,
    type: 'date'
  },
  {
    title: 'Warrenty Until',
    field: 'warrenty_until',
    editable: true,
    type: 'date'
  },
  {
    title: 'Ref document',
    field: 'refdocument',
    editable: false,
    bulk_update: true
  },
  {
    title: 'Age (Months)',
    field: 'age',
    editable: false
  },
  {
    title: 'Extra 1',
    field: 'extra_infor1',
    editable: true
  },
  {
    title: 'Extra 2',
    field: 'extra_infor2',
    editable: true
  },
  {
    title: 'Username',
    field: 'user_name',
    editable: true
  },
  {
    title: 'User email',
    field: 'user_email',
    editable: true,
    // valueGetter: function (params) {
    //   let email = '';
    //   if (params.data && params.data.clients_attn) {
    //     let userInfo = getUsersInfo(params.data.clients_attn);

    //     if (userInfo) {
    //       email = userInfo.email
    //       params.data.user_name = userInfo.firstname + " " + userInfo.lastname;
    //       params.data.user_email = userInfo.email;
    //       params.data.user_phoneno = userInfo.phone_number || '';
    //       params.data.user_address = userInfo.address || '';
    //       params.data.employee_no = userInfo.emp_ref_number || '';
    //       params.data.user_city = userInfo.city || '';
    //       params.data.user_devision = userInfo.department || '';
    //       params.data.user_country = userInfo.country || '';
    //     }
    //   }
    //   return params.data.user_email;
    // },
  },
  {
    title: 'User phone no',
    field: 'user_phoneno',
    editable: true,
  },
  {
    title: 'User devision',
    field: 'user_devision',
    editable: true,
    bulk_update: true
  },
  {
    title: 'Emp Ref no',
    field: 'employee_no',
    editable: true,
  },
  {
    title: 'User Invoice address',
    field: 'user_invoice_address',
    editable: true
  },
  {
    title: 'User address',
    field: 'user_address',
    editable: true,
  },
  {
    title: 'User city',
    field: 'user_city',
    editable: true,
  },
  {
    title: 'User country',
    field: 'user_country',
    editable: true,
  }, {
    title: 'CC Manager name',
    field: 'ccmanager_name',
    editable: true
  },
  {
    title: 'CC Manager Phone',
    field: 'ccmanager_phone',
    editable: true
  },
  {
    title: 'CC Manager email',
    field: 'ccmanager_email',
    editable: true
  },
  {
    title: 'Cost centre',
    field: 'cost_centre',
    editable: true,
    bulk_update: true
  },
  {
    title: 'CostCentre1',
    field: 'cost_centre1',
    editable: true
  },
  {
    title: 'CostCentre2',
    field: 'cost_centre2',
    editable: true
  },
  ];

  const lists = async (gridApi, isfilter = null) => {
    setLoading(true);
    gridApi && gridApi.showLoadingOverlay();
    let fields = ''

    if (page === 'searchmodal') {
      fields = `${ASSETAGGREMENTFIELDS},id,aggrement.EOL_value_amt,price_total,rent_period,serial_number,product_group,insurance_company,Interim_rent_cost,user_created.first_name,user_created.last_name`
      // fields = `${searchString && 'search='+searchString+'&aggrement.id,aggrement.agrement_info_no,aggrement.no_of_month_lease,aggrement.founding_partner.supplier_name,aggrement.eol_date,aggrement.lease_start_date,price_total,rent_period,serial_number,product_group,insurance_company,Interim_rent_cost'}`
    } else {
      fields = `${ASSETSFIELDS},${ASSETSFIELDS1},${ASSETAGGREMENTFIELDS},import_id`
    }

    let options = {
      limit: -1,
      sort: '-id',
      fields: [fields],
      // filter: {}
    }
    if (userInfo.role) {
      if ((userInfo?.role?.name === "client_admin") || (userInfo?.role?.name === "client_user")) {
        if (selected && selected.length > 0) {
          values.client = userInfo.client.id
        } else {
          options.filter = {
            "aggrement": {
              "client": {
                _eq: userInfo.client.id
              }
            }
          }
        }
      } else if (userInfo?.role?.name === 'partner_admin' || userInfo?.role?.name === 'partner_user') {
        // console.log("users_details", users_details)

        if (selected && selected.length > 0) {
          values.partner = userInfo.partner.id;
        } else {
          options.filter = {
            "aggrement": {
              "partner": {
                _eq: userInfo.partner.id
              }
            }
          }
        }

      }
      if (values.client && !values.partner && selected.length === 0) {
        options.filter = {
          _and:
            [{
              "aggrement": {
                "client": {
                  _eq: values.client
                }
              }
            }]
        }
      }
      if (values.partner && !values.client && selected.length === 0) {
        options.filter = {
          _and:
            [
              {
                "aggrement": {
                  "partner": {
                    "_eq": values.partner
                  }
                }
              }
            ]
        }
      }

      if (values.partner && values.client && selected.length === 0) {
        options.filter = {
          _and:
            [
              {
                "aggrement": {
                  "partner": {
                    "_eq": values.partner
                  }
                }
              },
              {
                "aggrement": {
                  "client": {
                    _eq: values.client
                  }
                }
              }
            ]
        }
      }
      if (selected && selected.length > 0) {
        let tempselected = selected.map(
          (item) => item.id
        );
        values.aggrement = tempselected
        options.filter = {
          _and: [
            {
              "aggrement": {
                "partner": {
                  "_eq": values.partner
                }
              }
            },
            {
              "aggrement": {
                "client": {
                  _eq: values.client
                }
              }
            },
            {
              "aggrement.id": {
                _in: tempselected
              }
            }
          ]
          // options.filter = {
          //   "aggrement.id": {
          //     _in: selected
          //   }
          // }
        }
      }
      if (urlParams && urlParams.id) {
        options.filter = {
          "aggrement.id": {
            _eq: urlParams.id
          }
        }
      }
    }

    if (searchString) {
      options.search = `${searchString}`
    }

    let upcomingRents = [];
    let upcomingYears = [];
    gridApi && gridApi.showLoadingOverlay();
    if (filterOptions) {
      // options = filterOptions
    }
    if (localStorage.getItem("selectedAssetType")) {
      setSelectedAssetType((localStorage.getItem("selectedAssetType")))
    }
    const result = await directUSAPICall().items('Asset').readMany(options);
    if (result.data && result.data.length > 0) {
      if (isfilter) {
        localStorage.setItem("filter", JSON.stringify(values))
      }
      let response = result.data;
      setLoading(false)
      // setPageLoading(false)
      // console.log("response", response)
      let currentYear = moment().format('YYYY');
      upcomingYears = range(parseInt(currentYear) + 4, parseInt(currentYear), -1).sort()
      upcomingYears.forEach((item) => {
        upcomingRents.push({
          "title": `Upcoming rents ${item}`,
          "field": `Upcoming_rents_${item}`,
        })
      })

      response.forEach((item) => {
        if (item.upcomingrents && item.upcomingrents.length > 0) {
          item.upcomingrents.forEach((rent) => {
            // console.log("renttt", rent)
            item[`Upcoming_rents_${moment(rent.to).format('YYYY')}`] = SVformatter(rent.rentamount)
          })

          // setTableColumns(upcomingRents)
        }
      })
      let statusNames = ASSETSTATUS.map(
        (status) => status.value
      );
      // if (userRole !== 'master_admin') {
      //   partnerPayload.created_user = `${authUser}`
      // }
      setStatusNames(statusNames);

      // let tempColumns = tablecolumns
      // tempColumns.splice(25, 0, upcomingRents)
      if (!hideColumns) {
        setTableColumns((prev) => [...prev, ...upcomingRents]);
      }
      setRowData(response)

      let tempAPI = JSON.parse(JSON.stringify(response));
      setRowDataAPI(tempAPI);

      gridApi && gridApi.hideOverlay();
      if ((userInfo.role.name !== "client_admin") || (userInfo.role.name !== "client_user")) {

        listsAllAgreement()
      }
    } else {
      setLoading(false);
      // setPageLoading(false)
      setRowData([])
      gridApi && gridApi.hideOverlay();
    }

  }

  const getassetTypes = async () => {

    let options = {
      limit: -1,
      sort: '-asset_name',
      fields: ['asset_name,fields']
    }
    const result = await directUSAPICall().items('asset_type').readMany(options);
    if (result.data && result.data.length > 0) {
      // console.log("result",result.data)
      setAssetTypeFieldMapping(result.data);
      let assetNames = result.data.map(
        (assetType) => assetType.asset_name.toUpperCase()
      );
      setAssetTypes(assetNames);

    } else {
      setAssetTypes([])
    }
  }

  // useEffect(() => {
  //   // console.log("userDetail", userDetail)
  //   if (userInfo && userInfo.id && userInfo?.role?.name) {
  //     setTableColumns(columns)
  //     getassetTypes()
  //     lists(gridApi, page)
  //     // }
  //   }
  // }, [])

  useEffect(() => {
    if (userInfo && userInfo.id && userInfo?.role?.name) {
      setLoggedUserRole(userInfo.role.name)
      setTableColumns(columns)
      getassetTypes()
      if (userInfo.role.name === 'client_user' || userInfo.role.name === 'client_admin') {
        setHideColumns(true)
      }
      lists(gridApi, page)
    }
  }, [userInfo])

  useEffect(() => {
    if (loggedUserRole) {
      if (loggedUserRole === 'partner_user' || loggedUserRole === 'partner_admin') {
        setUserPermission({
          canImport: false
        })
      }
      if (loggedUserRole === 'client_user' || loggedUserRole === 'client_admin') {
        setHideColumns(true)
        setUserPermission({
          canAdd: false,
          canBulkDelete: false,
          canImport: false,
          canBulkUpdate: false
        })
      }
    }
  }, [loggedUserRole])

  useEffect(() => {
    // console.log("userDetail", userDetail)
    if (localStorage.getItem("filter")) {
      // setFilterOptions(localStorage.getItem("filter"))
      // console.log("filer******", JSON.parse(localStorage.getItem("filter")))
      setValues(JSON.parse(localStorage.getItem("filter")))

    }
  }, [])

  const gotoEdit = (values, type = null) => {
    if (page === 'searchmodal' || type === 'view') {
      setSelectedAssetId(values.data.id)
      setShowAssetDetail(true)
    } else {
      history.push(`/updateasset/${values.data.id}`)
    }
  }

  const deleteConfirm = (values) => {

    setShowDeleteDialog(true)
    let arr = [];
    arr.push(values.data)
    setDeleteId(arr)
  }

  const handleBulkDelete = async (props) => {
    let selectedRows = gridApi.getSelectedRows();
    setDeleteId(selectedRows)
    setShowDeleteDialog(true)
  };

  const BtnActionCellRenderer = (params) => {

    return <div>
      <i onClick={() => gotoEdit(params, 'view')} className="fas fa-eye" />
      {page !== 'searchmodal' ? <>
        <i onClick={() => gotoEdit(params)} className="fas fa-edit" />
        <i className="fas fa-sticky-note" onClick={() => handleNotestOpen(params)}></i>
        <i onClick={() => deleteConfirm(params)} className="fas fa-trash-alt deleteone" />
      </> : null
      }
    </div>
  }

  const buildColumnDefinitions = (columnDefs) => {
    return columnDefs && columnDefs.map((columnDef, index) => {

      let columnDefinition = {
        headerName: (index !== 0) ? columnDef.title : '',
        cellRenderer: index === 0 ? 'ActionCellRenderer' : columnDef.field === 'upcomingrents' ? 'UpcomingrentsRenderer' : false,
        // cellRendererParams: {
        //   onRowEditingStopped: (params) => onRowEditingStopped(params),
        // },
        headerCheckboxSelection: index === 0 ? true : false,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: index === 0 ? true : false,
        field: columnDef.field,
        editable: false,
        filter: (index !== 0 || page === 'searchmodal') ? 'agTextColumnFilter' : 'none',
        sortable: true,
        resizable: true,
        suppressAggFuncInHeader: true,
        enableCellChangeFlash: true,
        // hide: columnDef.hide,
        width: index !== 0 ? (columnDef.field.length <= 9 ? 90 : columnDef.field.length >= 12 ? 130 : columnDef.field.length >= 17 ? 160 : 180) : 150,
        valueFormatter: (params) => {
          // console.log("params", params.data.aggrement)
          if (columnDef.type === 'currencyColumn') {
            return params.value ? SVformatter(params.value) : ' ';
          }
          if (params.value && columnDef.type === 'date') {
            return moment(params.value).format('YYYY-MM-DD');
          }
          if (params.value && columnDef.type === 'percentage') {
            return params.value ? SVPercentageformatter(params.value) : ' ';
          }
        },
        valueGetter: columnDef.valueGetter

      };
      // if (columnDef.field === 'agrement_info_no') {
      //   let agremtList = agreementsInfoList && agreementsInfoList.sort((a, b) => (a > b ? -1 : 1));
      //   columnDefinition.cellEditor = 'DialogEditor';
      //   columnDefinition.cellEditorParams = {
      //     values: agremtList,
      //   };
      // }
      if (columnDef.type === 'date') {
        columnDefinition.cellEditor = 'dateEditor';
      }

      if (columnDef.field === 'product_group') {
        columnDefinition.cellEditor = 'AssetTypesEditor';
        columnDefinition.cellEditorParams = {
          values: assetTypes,
        };
      }
      if (columnDef.field === 'status') {
        columnDefinition.cellEditor = 'agSelectCellEditor';
        columnDefinition.cellEditorParams = {
          values: statusNames,
        };
      }

      // if (columnDef.field === 'totalAssets') {
      //     columnDefinition.cellRenderer = params => {
      //         params.data.totalAssets = getAssetsCount(params.data.id)
      //         return params.data.totalAssets
      //     };

      // }
      return columnDefinition;
    });
  };




  const onGridReady = (params) => {
    setGridApi(params.api);
    let payload = {
      limit: 100,
      offset: 0
    }
    // lists(params.api, payload, loggedUserRole)

  };

  const frameworkComponents = {
    //   CustomCellEditor: CustomCellEditor,
    BtnActionCellRenderer,
    ActionCellRenderer
    // UpcomingrentsRenderer,
    // DialogEditor,
    // dateEditor: DateEditor,
    // AssetTypesEditor
  };



  const onModelUpdated = (params) => {
    // console.log("onModelUpdated" ,params)
    if (!enableBulkUpdates) {
      gridApi && gridApi.deselectAll()
    }
    // params.api.sizeColumnsToFit();
  };

  useEffect(() => {
    if (assetsListData && assetsListData.length > 0) {
      getCalculation()
    }
  }, [assetsListData])

  const getCalculation = () => {
    let total_price = 0;
    let daily_rent = 0;
    let Interim_rent_cost = 0;
    let rent_period = 0;
    if (assetsListData && assetsListData.length > 0) {
      total_price = assetsListData.reduce((s, f) => {
        // console.log("valueee", f.rent_per_month)
        return s + (f.price_total && !_.isNull(f.price_total) ? parseFloat(f.price_total) : 0);
      }, 0);
      daily_rent = assetsListData.reduce((s, f) => {
        return s + (f.dailyrent && !_.isNull(f.dailyrent) ? parseFloat(f.dailyrent) : 0);
      }, 0);
      Interim_rent_cost = assetsListData.reduce((s, f) => {
        // console.log("valueee", f.rent_per_month)
        return s + (f.Interim_rent_cost && !_.isNull(f.Interim_rent_cost) ? parseFloat(f.Interim_rent_cost) : 0);
      }, 0);
      rent_period = assetsListData.reduce((s, f) => {
        // console.log("valueee", f.invoiced_amount)
        return s + (f.rent_period && !_.isNull(f.rent_period) ? parseFloat(f.rent_period) : 0);
      }, 0);
      // console.log("total_price", total_price)
      let total = 0
      assetsListData.forEach((item) => {

        total += item.price_total ? parseFloat(item.price_total) : 0
      })
      // console.log("total", total)
      setTotalPrice(total_price)
      setDailRent(daily_rent)
      setInterim_rent_cost(Interim_rent_cost)
      setRentPeriod(rent_period)
    }
  }

  const onRowSelected = (params) => {
    setEnableBulkUpdates(gridApi.getSelectedRows().length > 0);
  };
  const onRowDataChanged = (params) => {
    highlightUnsavedRows(params);
  };

  const onCellEditingStopped = (params) => {
    console.log("params", params)
  };
  const highlightUnsavedRows = (params) => {
    if (!params || rowDataAPI.length === 0) {
      return;
    }
    let missingRowNodes = params.api.rowModel.rowsToDisplay.filter((row) => {
      if (!row.data.asset_id) {
        return row;
      }
    });

    if (missingRowNodes.length > 0) {
      missingRowNodes.map((node) => {
        if (params.node !== node) {
          node.setSelected(true);
        }
      });
    }
  };

  const onRowEditingStarted = (params) => {
    gridApi.refreshCells({
      rowNodes: [params.node],
      // columns: [params.columnApi.columnController.allDisplayedColumns[0]],
      force: true,
    });
  };
  const handleBulkUpdate = (params) => {
    setBulkUpdateOpen(true);
  };
  const handleNotestOpen = (params) => {
    setShowNotesDialog(true);
    setSelectedAsset(params)
  };

  useEffect(() => {
    if (bulkupdatedData && bulkupdatedData.length > 0) {
      // console.log("row dattta****", rowData)
      setAssetsListData(assetsListData);
      setRowDataAPI(JSON.parse(JSON.stringify(assetsListData)));
      // setState({})
    }
  }, [bulkupdatedData]);

  const handleNotesCancel = async () => {
    setShowNotesDialog(false);
  };

  const exportExcelData = async () => {
    generateExcel(gridApi, `Assets-${moment().format('YYYY-MM-DD')}.xlsx`, 'assets', tablecolumns);
  }


  useEffect(() => {
    if (data && data?.id) {
      if (data.role) {
        if (data.role.name === 'client_admin' || data.role.name === 'client_user') {
          values.client = data.client.id;
          getClientList(null, data?.client?.id)
        }
        else if (data.role.name === 'partner_admin' || data.role.name === 'partner_user') {
          values.partner = data?.partner?.id
          getClientList(data?.partner?.id, null)
        } else {
          getClientList(null, null)
        }
      }
    }
  }, [data])

  const getClientList = (partnerId = null, client = null) => {
    if ((userInfo.role.name === "client_admin") || (userInfo.role.name === "client_user")) {
      console.log("partnerId", partnerId)
      if (partnerId) {
        values.partner = partnerId;
        listsAllAgreement()
        return;
      }
    }
    let queryFields = 'client.name,client.id,client.clients_org_no,partner.company_org_no,partner.partner_name,partner.id'
    let filter = ''
    if (partnerId) {
      values.partner = partnerId
      filter = `?filter[partner][_eq]=${partnerId}&fields=`
      // listsAllAgreement(partnerId, '')

    }
    if (client) {
      values.client = client
      filter = `?filter[client][_eq]=${client}&fields=`
      // listsAllAgreement('', client)
    }
    setSelected([])

    ClientAddessList(queryFields, filter)
      .then(({ data: { data } }) => {

        if (data && data.length > 0) {
          if (!partnerId) {
            values.client = "";
            setValues({ ...values })

            let partners = data.map(
              (item) => item.partner && item.partner
            );
            partners = partners.filter(function (e) { return e != null; });
            partners = _.orderBy(partners, ['partner_name'], ['asc']);
            setPartnersValues(_.uniqBy(partners, 'id'))

          }
          if (partnerId || client) {
            // values.partner = "";
            let clients = data.map(
              (item) => item.client
            );
            clients = _.orderBy(clients, ['name'], ['asc']);
            values.client = '';
            setValues({ ...values })
            setClientsValues(_.uniqBy(clients, 'id'))
            // if (client) {
            //   listsAllAgreement(client)
            // }
          }
        }
      })
      .catch((err) => {
        throw err;
        // setIsloading(false)
        //   setStatus('The login detail is incorrect')
      })
  }

  const listsAllAgreement = async (client = null) => {
    let fields = `id,agrement_info_no,heading,montly_payment_ratio,lease_start_date,billing_period`
    let options = {
      limit: -1,
      sort: '-id',
      fields: [fields]
    }
    if ((userInfo.role.name === "client_admin") || (userInfo.role.name === "client_user")) {
      if (values.partner) {
        options.filter = {
          _and:
            [
              {
                "partner.id": {
                  _eq: values.partner
                }
              },
              {
                "client.id": {
                  _eq: userInfo.client.id
                }
              }
            ]
        }
      }

    } else {
      if (values.partner) {
        options.filter = {
          "partner.id": {
            _eq: values.partner
          }
        }
      }

      if (client) {
        values.client = client
        options.filter = {
          "client.id": {
            _eq: client
          }
        }
      }
    }
    const result = await directUSAPICall().items('aggrement').readMany(options);
    if (result.data && result.data.length > 0) {
      setAgreementList(result.data)
      let agrement_info_no = result.data.map(
        (status) => status.agrement_info_no + '' + (status.heading ? ' - ' + status.heading : '')
      );
      setAgreementsInfoList(result.data);

      // setLoading(false)
    } else {
      // setLoading(false)
      setAgreementList([])
      setAgreementsInfoList([])
    }

  }

  // console.log("values", values)
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleCopy = () => {
    if (gridApi) {
      gridApi.paginationGoToFirstPage();
    }
    let selectedRows = gridApi.getSelectedRows();
    if (selectedRows.length === 0) {
      return;
    }
    let updatedRows = JSON.parse(JSON.stringify(selectedRows.slice()));
    console.log("updatedRows", updatedRows)
    updatedRows.map((row) => {
      delete row['id'];
    });
    setRowData((prev) => [...updatedRows, ...prev]);
  };

  return (
    <>
      <PageLoader pageLoaderOpen={loading} setPageLoaderOpen={setLoading} />
      <div className="card card-custom gutter-b">
        <div className='card-header border-0'>

          {
            <div className="header-card-toolbar">
              <div className='card-title m-0'>
                <h3 className='card-label'>
                  {'Assets list'} {searchString ? ' - ' + searchString : ''}
                </h3>
              </div>
              {
                <div className="btns">
                  {
                    (page === '/assets' && !searchcolumns) && <FormControl className={classes.formControl1}>
                      <Tooltip title="Changes View">
                        <MatSelect
                          value={selectedAssetType}
                          className={'matselect'}
                          ref={externalFilterRef}
                          onChange={(event) => {
                            // console.log("event", event)
                            localStorage.setItem('selectedAssetType', event.target.value)
                            externalFilterChanged(event.target.value);
                          }}
                        >
                          <MenuItem value='All'>All</MenuItem>
                          {assetTypes.map((type, index) => (
                            <MenuItem key={index} value={type}>{type}</MenuItem>
                          ))}
                        </MatSelect>
                      </Tooltip>

                    </FormControl>
                  }
                  {
                    !searchcolumns && page === '/assets' && (userPermission && userPermission && userPermission.canBulkUpdate) &&
                    <Tooltip title="Bulk Update">
                      <Link to='#' className='align-self-center'>
                        <Button variant="contained" color="primary" onClick={() => enableBulkUpdates && handleBulkUpdate(gridApi)} className={classes.button}>
                          <span><i className="fas fa-list"></i></span>
                        </Button>
                      </Link>
                    </Tooltip>
                  }
                  {!searchcolumns && (userPermission && userPermission && userPermission.canBulkDelete) && <Button variant="contained" color="primary" onClick={() => enableBulkUpdates && handleBulkDelete(gridApi)} className={classes.button}>
                    <span><i className="fas fa-trash-alt builkdelete"></i></span>
                  </Button>
                  }
                  {
                    ((userPermission && userPermission && userPermission.canImport) && !searchcolumns && page && (page === '/assets' || page === '/agreements')) &&
                    <Tooltip title="Upload"><Link onClick={() => setFileUploadOpen(true)} to='#' className='align-self-center'>
                      <Button variant="contained" color="primary" className={classes.button}>
                        <span><i className="fas fa-upload"></i></span>
                      </Button>
                    </Link>
                    </Tooltip>
                  }
                  <Tooltip title="Download">
                    <Button variant="contained" onClick={() => exportExcelData()} color="primary" className={classes.button}>
                      <span><i className="fas fa-download"></i></span>
                    </Button>
                  </Tooltip>
                  {/* <Tooltip title="Copy Assets">
                    <Button variant="contained" onClick={() => handleCopy()} color="primary" className={classes.button}>
                      <span><i className="fas fa-copy"></i></span>
                    </Button>
                  </Tooltip> */}
                  {
                    !searchcolumns && createPath && createTitle && <Link to={`/${createPath}`} className='align-self-center'>
                      <Button variant="contained" color="primary" className={classes.button}>
                        <i className="fas fa-plus"></i> {createTitle}
                      </Button>
                      {/* <button type="button" className="btn btn-primary">Create Asset</button> */}
                    </Link>
                  }
                </div>}
            </div>
          }
          {
            (!searchcolumns) &&
            Object.keys(urlParams).length === 0 && < div style={{ width: '100%' }}>
              <div className="form-group row">
                {
                  (userInfo?.role?.name !== 'partner_admin' && userInfo?.role?.name !== 'partner_user') &&
                  <div className='col-lg-4'>
                    <Autocomplete
                      id="size-small-standard11"
                      // value={values.partner}
                      getOptionLabel={(option) => option.partner_name + " - " + option.company_org_no}
                      options={partnersValues}
                      onChange={(e, option) => {
                        if (option) {
                          getClientList(option.id)
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // value={values.partner}
                          label="Partner"
                          margin="normal"
                          variant="standard"
                        />
                      )}
                    />
                  </div>}
                {
                  (userInfo?.role?.name !== 'client_admin' && userInfo?.role?.name !== 'client_user') &&

                  <div className='col-lg-4'>
                    <Autocomplete
                      id="size-small-standard1"
                      getOptionLabel={(option) => option.name + " - " + option.clients_org_no}
                      options={clientsValues}
                      onChange={(e, option) => {
                        if (option) {
                          listsAllAgreement(option.id)
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // value={values.client}
                          label="Client"
                          margin="normal"
                          variant="standard"
                        />
                      )}
                    />
                  </div>
                }
                <div className='col-lg-4'>
                  <Autocomplete
                    multiple
                    limitTags={1}
                    id="multiple-limit-tags"
                    options={agreementList}
                    disableCloseOnSelect
                    defaultValue={selected}
                    onChange={(e, item) => {
                      if (item) {
                        setSelected(item)
                      }
                    }}
                    getOptionLabel={(option) => option.agrement_info_no}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.agrement_info_no}
                      </li>
                    )}
                    // style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField margin="normal" {...params} label="Agreements" placeholder="Agreements" />
                    )}
                  />
                </div>
                <div className='col-lg-4 row'>

                  <div className={classes.buttonContainer}>
                    <Button
                      variant='contained'
                      onClick={() => {
                        setSelected([])
                        setValues({
                          partner: null,
                          client: null,
                          aggrement: null
                        })
                        lists(gridApi)
                      }
                      }
                      color='secondary'
                      className={classes.button}
                    >
                      Reset</Button>
                    <Button
                      variant='contained'
                      onClick={() => {

                        lists(gridApi, 'filter')
                      }
                      }
                      color='primary'
                      className={classes.button}
                    >
                      Search
                </Button>
                  </div>
                </div>
              </div>
            </div>
          }


          {/* </div> */}
          <div
            className='ag-theme-alpine'
            style={{
              width: '100%',
              height: '80vh',
              boxShadow: '0 1px 15px 1px rgba(69,65,78,.08)',
            }}
          >
            <AgGridReact
              rowData={rowData}
              rowBuffer={500}
              debounceVerticalScrollbar={true}
              columnDefs={buildColumnDefinitions(searchcolumns ? searchcolumns : tablecolumns)}
              frameworkComponents={frameworkComponents}
              suppressDragLeaveHidesColumns={true}
              onGridReady={onGridReady}
              rowSelection='multiple'
              editType='fullRow'
              // onRowEditingStopped={onRowEditingStopped}
              // onCellEditingStopped={onCellEditingStopped}
              onRowSelected={onRowSelected}
              onRowDataChanged={onRowDataChanged}
              onRowEditingStarted={onRowEditingStarted}
              enableCellTextSelection={true}
              overlayLoadingTemplate={overlayLoadingTemplate}
              overlayNoRowsTemplate={noRowsTemplate}
              onRowSelected={onRowSelected}
              pagination={true}
              gotoEdit={gotoEdit}
              deleteConfirm={deleteConfirm}
              handleNotestOpen={handleNotestOpen}
              suppressMenuHide={false}
              // floatingFiltersHeight="60"
              // headerHeight="60"
              // rowHeight="50"
              // rowStyle={{
              //   height: 80
              // }}
              paginationPageSize={pageSize}
              suppressRowClickSelection={true}
              alwaysShowVerticalScroll={true}
              quickFilterText={quickFilterText}
              floatingFilter={true}
              onModelUpdated={onModelUpdated}
            ></AgGridReact>
            <CustomDropdown
              options={[25, 50, 100, 500]}
              title={'Page Size'}
              value={pageSize}
              onChange={(value) => {
                setPageSize(value);
                gridApi.paginationSetPageSize(value);
              }}
            />
            <DeleteConfirmDialog
              title="Asset"
              content="are you sure you want to delete this Asset ?"
              open={showDeleteDialog}
              setOpen={setShowDeleteDialog}
              tableName="Asset"
              deleteId={deleteId}
              setIsDeleted={lists}
              parentGridApi={gridApi}
            />

            <NotesDialog
              handleCancel={handleNotesCancel}
              // userRole={userRole}
              selectedAsset={selectedAsset}
              showDialog={showNotesDialog}
            // setIsDeleted={lists}
            // parentGridApi={gridApi}
            />
            <BulkUpdateDialog
              open={bulkUpdateOpen}
              title='bulk update'
              columnDefs={columns}
              parentGridApi={gridApi}
              getNewData={lists}
              setOpen={setBulkUpdateOpen}
              assetTypes={assetTypes}
              agreementsInfoList={agreementsInfoList}
              statusNames={statusNames}
              setUpdatedData={setBulkupdatedData}
            // setGridApi={setGridApi}
            // gridApi={gridApi}
            />
            <FileUpload
              open={fileUploadOpen}
              setOpen={setFileUploadOpen}
              title='Asset File upload'
              handleCancel={setFileUploadOpen}
              getNewData={lists}
              parentGridApi={gridApi}
            />
            <AssetViewDialog
              open={showAssetDetail}
              setOpen={setShowAssetDetail}
              fieldDisable={true}
              title={`Asset - ${selectedAssetId}`}
              assetId={selectedAssetId}
              setSelectedAsset={setSelectedAssetId}
            />
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

