/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '20px'
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));

export default function Step4({
  values,
  setValues,
  isUserDisable,
  currentPage
}) {
  const classes = useStyles();
  const dispatch = useDispatch()

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };
console.log("currentPage", currentPage)
  return (
    <div className="card card-custom gutter-b">
      <div className='card-body box-shadow'>
        <div className="form-group row">
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isUserDisable
              }}
              variant={isUserDisable ? "filled" : "standard"}

              InputProps={{
                disabled: isUserDisable
              }}
              variant={isUserDisable ? "filled" : "standard"}
              id="user_email"
              name="user_email"
              label="User Email"
              className={classes.textField}
              value={values.user_email}
              onChange={handleChange('user_email')}
              margin="normal"
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isUserDisable
              }}
              variant={isUserDisable ? "filled" : "standard"}

              id="user_name"
              name="user_name"
              label="Username"
              className={classes.textField}
              value={values.user_name}
              onChange={handleChange('user_name')}
              margin="normal"
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isUserDisable
              }}
              variant={isUserDisable ? "filled" : "standard"}

              id="user_phoneno"
              name="user_phoneno"
              label="User Phone"
              className={classes.textField}
              value={values.user_phoneno}
              onChange={handleChange('user_phoneno')}
              margin="normal"
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isUserDisable
              }}
              variant={isUserDisable ? "filled" : "standard"}

              id="employee_no"
              name="employee_no"
              label="Employee No"
              className={classes.textField}
              value={values.employee_no}
              onChange={handleChange('employee_no')}
              margin="normal"
            />
          </div><div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isUserDisable
              }}
              variant={isUserDisable ? "filled" : "standard"}

              id="user_devision"
              name="user_devision"
              label="User Devision"
              className={classes.textField}
              value={values.user_devision}
              onChange={handleChange('user_devision')}
              margin="normal"
            />
          </div><div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isUserDisable
              }}
              variant={isUserDisable ? "filled" : "standard"}

              id="user_invoice_address"
              name="user_invoice_address"
              label="User Invoice address"
              className={classes.textField}
              value={values.user_invoice_address}
              onChange={handleChange('user_invoice_address')}
              margin="normal"
            />
          </div><div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isUserDisable
              }}
              variant={isUserDisable ? "filled" : "standard"}

              id="user_address"
              name="user_address"
              label="User Address"
              className={classes.textField}
              value={values.user_address}
              onChange={handleChange('user_address')}
              margin="normal"
            />
          </div><div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isUserDisable
              }}
              variant={isUserDisable ? "filled" : "standard"}

              id="user_city"
              name="user_city"
              label="User City"
              className={classes.textField}
              value={values.user_city}
              onChange={handleChange('user_city')}
              margin="normal"
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isUserDisable
              }}
              variant={isUserDisable ? "filled" : "standard"}

              id="user_country"
              name="user_country"
              label="User Country"
              className={classes.textField}
              value={values.user_country}
              onChange={handleChange('user_country')}
              margin="normal"
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isUserDisable
              }}
              variant={isUserDisable ? "filled" : "standard"}

              id="ccmanager_name"
              name="ccmanager_name"
              label="CC Manager name"
              className={classes.textField}
              value={values.ccmanager_name}
              onChange={handleChange('ccmanager_name')}
              margin="normal"
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isUserDisable
              }}
              variant={isUserDisable ? "filled" : "standard"}

              id="ccmanager_phone"
              name="ccmanager_phone"
              label="CC Manager Phone"
              className={classes.textField}
              value={values.ccmanager_phone}
              onChange={handleChange('ccmanager_phone')}
              margin="normal"
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isUserDisable
              }}
              variant={isUserDisable ? "filled" : "standard"}

              id="ccmanager_email"
              name="ccmanager_email"
              label="CC Manager Email"
              className={classes.textField}
              value={values.ccmanager_email}
              onChange={handleChange('ccmanager_email')}
              margin="normal"
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isUserDisable
              }}
              variant={isUserDisable ? "filled" : "standard"}

              id="cost_centre"
              name="cost_centre"
              label="Cost centre"
              className={classes.textField}
              value={values.cost_centre}
              onChange={handleChange('cost_centre')}
              margin="normal"
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isUserDisable
              }}
              variant={isUserDisable ? "filled" : "standard"}

              id="cost_centre1"
              name="cost_centre1"
              label="Cost centre1"
              className={classes.textField}
              value={values.cost_centre1}
              onChange={handleChange('cost_centre1')}
              margin="normal"
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isUserDisable
              }}
              variant={isUserDisable ? "filled" : "standard"}

              id="cost_centre2"
              name="cost_centre2"
              label="Cost centre2"
              className={classes.textField}
              value={values.cost_centre2}
              onChange={handleChange('cost_centre2')}
              margin="normal"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
