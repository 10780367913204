/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { SVformatter } from '../../../../_metronic/helpers/components/utils'
// import { Dropdown1 } from '../../content/dropdown/Dropdown1'
import CloudDownload from '@material-ui/icons/CloudDownload';
import moment from "moment";


export default function FileList({ className, upcomingrents }) {
    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0'>
                <h3 className='card-title fw-bolder text-dark'>Upcoming Rents</h3>
                <div className='card-toolbar'>
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body pt-2'>
                {
                    upcomingrents?.length > 0 ? upcomingrents.map((item, key) => (
                        <div className='d-flex align-items-center mb-8'>
                            {/* begin::Bullet */}
                            <span className='bullet bullet-vertical h-40px bg-success'></span>
                            {/* end::Bullet */}
                            {/* begin::Checkbox */}
                            <div className='form-check form-check-custom form-check-solid mx-5'>
                            </div>
                            {/* end::Checkbox */}
                            {/* begin::Description */}
                            <div className='flex-grow-1'>
                                <div className='text-gray-800 fw-bolder fs-6'>
                                From 11 {moment(item.from).format('YYYY-MM-DD')} - To {moment(item.to).format('YYYY-MM-DD')}
                                </div>
                                <span class="text-muted">{item.months}</span>

                                {/* <a target="_blank" download href={`${API_URL}${item.url}`}><DownloadOutlined /></a> */}
                            </div>
                            {/* end::Description */}
                            <span className='badge badge-light-success fs-8 fw-bolder'>{SVformatter(item.rentamount)}</span>
                        </div>
                    )) :
                        <div className='d-flex align-items-center mb-8'>
                            No data found
                          </div>
                }
            </div>
            {/* end::Body */}
        </div>
    )
}