import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { SVformatter } from './utils';
import _ from "lodash";

const TAX_RATE = 0.07;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  card: {
    minWidth: 275,
  },
  table: {
    minWidth: 400,
  },
}));

function ccyFormat(num) {
  return `${SVformatter(Math.floor(num))}`;
}


export default function SpanningTable({
  sumPurchaseAmountByPartner,
  sumPurchaseAmountByPartnerSubtotal,
  title
}) {

  const classes = useStyles();

  return (
    <div className={`card card-custom gutter-b ${classes.card}`}>
      <div className='card-body dasboard-overflow-auto'>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="text-bold" style={{ width: "180px" }}>{title}</TableCell>
                <TableCell className="text-bold" align="left">Sum Financing</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sumPurchaseAmountByPartner && sumPurchaseAmountByPartner.map(row => (
                <TableRow key={row.name}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell align="right">{ccyFormat(row.value)}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={1}><span className="font-weight-bolder">Total Amount</span></TableCell>
                <TableCell className="font-weight-bolder" align="right">{ccyFormat(sumPurchaseAmountByPartnerSubtotal)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
      </div>
    </div>
  );
}