/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { getLatestNoticeDay } from "../../../../_metronic/helpers/components/utils"


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '20px'
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));

export default function Step1({
  setValues,
  values,
  isDisable,
  hideColumns
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };
  // console.log("values****", values)
  useEffect(() => {
    if (values.sum_purchase_value) {
      // console.log("calculation(values)", calculation(values))
      // values.eol_date = (calculation(values).eoldate) || null;
      // values.ag_status = (calculation(values).status) || "";
      // setValues({ ...values })
    }
  }, [values.sum_purchase_value])

  useEffect(() => {
    if (values && values.eol_date && values.notice_period_before_EOL) {
      let noticeperiodEOL = getLatestNoticeDay(values.eol_date, values.notice_period_before_EOL)
      values.latest_notice_day = noticeperiodEOL;
      setValues({ ...values })
    }
  }, [values.eol_date, values.notice_period_before_EOL])

  useEffect(() => {
    if (values && values.latest_notice_day && values.notice_before_notice_month) {
      values.notice_before_notice_day = getLatestNoticeDay(values.latest_notice_day, values.notice_before_notice_month)
      setValues({ ...values });
    }
  }, [values.notice_before_notice_month, values.latest_notice_day])


  return (
    <div className="card card-custom gutter-b">
      <div className='card-body box-shadow'>
        <div className="form-group row">
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              id="noticeperiod"
              name={'notice_period_before_EOL'}
              label={'Notice period before EOL'}
              className={classes.textField}
              value={values.notice_period_before_EOL || ''}
              onChange={handleChange('notice_period_before_EOL')}
              margin="normal"
            />
          </div>
          {
            !hideColumns &&
            <div className='col-lg-6'>
              <TextField
                id={'EOL_percentage'}
                name={'EOL_percentage'}
                label={'EOL Percentage'}
                className={classes.textField}
                value={values.EOL_percentage}
                onChange={handleChange('EOL_percentage')}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <i className="fas fa-percent" />
                    </InputAdornment>
                  ),
                  disabled: isDisable
                }}
                variant={isDisable ? "filled" : "standard"}

              />
            </div>}
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              id="latest_notice_day"
              name={'latest_notice_day'}
              label={'Latest notice day'}
              type={isDisable ? "" : "date"}
              margin="normal"
              format="YYYY-MM-DD"
              // onChange={handleChange('latest_notice_day')}
              value={values.latest_notice_day}
              // formatDate={() => moment().format('YYYY-MM-DD')}
              // defaultValue="2017-05-24"
              className={classes.textField}
              variant="filled"
              InputLabelProps={{
                shrink: true,
                readOnly: true,
                disabled: true
              }}
            />
          </div>
          {
            !hideColumns && <div className='col-lg-6'>
              <TextField
                InputProps={{
                  disabled: isDisable
                }}
                variant={isDisable ? "filled" : "standard"}

                id={'EOL_value_amt'}
                name={'EOL_value_amt'}
                label={'EOL value'}
                className={classes.textField}
                value={values.EOL_value_amt}
                onChange={handleChange('EOL_value_amt')}
                margin="normal"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                  readOnly: true,
                  disabled: true
                }}
              />
            </div>
          }

          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              name={'notice_before_notice_month'}
              label={'Notice before notice months'}
              id={'Notice before notice months'}
              className={classes.textField}
              value={values.notice_before_notice_month}
              onChange={handleChange('notice_before_notice_month')}
              margin="normal"

            />
          </div><div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              id={'clients_attn_notice'}
              name={'clients_attn_notice'}
              label={'Clients attn notice'}
              className={classes.textField}
              // value={values.name}
              // onChange={handleChange('name')}
              margin="normal"
            />
          </div><div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              key={'notice_before_notice_day'}
              name={'notice_before_notice_day'}
              label={'Notice before notice day'}
              type={isDisable ? "" : "date"}
              margin="normal"
              format="YYYY-MM-DD"
              // onChange={handleChange('notice_before_notice_day')}
              value={values.notice_before_notice_day}
              // formatDate={() => moment().format('YYYY-MM-DD')}
              // defaultValue="2017-05-24"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div><div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              key={'notice'}
              name={'notice'}
              label={'Notice'}
              className={[classes.textField, classes.textArea].join(' ')}
              value={values.notice}
              multiline
              rows="6"
              onChange={handleChange('notice')}
              margin="normal"
            />
          </div>
          {
            !hideColumns && <div className='col-lg-6'>
              <TextField
                InputProps={{
                  disabled: isDisable
                }}
                variant={isDisable ? "filled" : "standard"}

                id={'Interim_income'}
                name={'Interim_income'}
                label={'Interim income'}
                className={classes.textField}
                value={values.Interim_income}
                onChange={handleChange('Interim_income')}
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                  readOnly: true,
                  disabled: true
                }}
              />
            </div>
          }

        </div>
        {/* </form> */}
      </div>
    </div>
  )
}
