/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect } from 'react';
import { PageTitle } from '../../../_metronic/layout/core'
import { useLocation, useHistory, Link } from 'react-router-dom'
import moment from "moment";
import DeleteConfirmDialog from "../../../_metronic/helpers/components/deleteConfirm";
import { directUSAPICall, getMonthDiff, SVformatter } from "../../../_metronic/helpers/components/utils";
import FileUpload from "./components/FileUpload"
import PageLoader from "../../../_metronic/helpers/components/PageLoader";
import { AGREEMENTFIELDS } from "../../../_metronic/helpers/components/constant"
import { useSelector } from "react-redux";
import { AgGridReact } from 'ag-grid-react';
import _ from "lodash";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { useStyles } from "../../../_metronic/helpers/components/aggrid/components/utils";
import CustomDropdown from '../../../_metronic/helpers/components/aggrid/components/CustomDropdown';
import Button from '@material-ui/core/Button';
import { generateExcel } from '../../../_metronic/helpers/components/utils'
import { ClientAddessList } from "../client/redux/ClientCrud"
import Badge from '@mui/material/Badge';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import AgreementViewDialog from './components/ViewDataDialog';
import Tooltip from '@mui/material/Tooltip';
import ActionCellRenderer from './components/ActionCellRenderer';

export default function TableComponent(props) {
    const { page, dashboardfields, dashBoardAggList } = props;
    const classes = useStyles();
    const location = useLocation()
    const history = useHistory()
    const [deleteId, setDeleteId] = useState([]);
    const { data } = useSelector(({ auth }) => auth.user);
    const [userInfo, setUserInfo] = useState(data);

    const [loading, setLoading] = useState(false);
    const [agreementList, setAgreementList] = useState([]);
    const [agreementIdsList, setAgreementIdsList] = useState([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [fileUploadOpen, setFileUploadOpen] = useState(false);
    const overlayLoadingTemplate =
        '<span className="ag-overlay-loading-center">Loading, Please Wait...</span>';
    const noRowsTemplate =
        '<span className="ag-overlay-loading-center">No records found...</span>';
    const [pageSize, setPageSize] = useState(50);
    const [gridApi, setGridApi] = useState();
    // const [rowDataAPI, setRowDataAPI] = useState([]);
    // const [rowData, setRowData] = useState([]);
    // const externalFilterRef = useRef(null);
    const [tablecolumns, setTableColumns] = useState([])
    const [selectedDataCount, setSelectedDataCount] = useState([])
    const [sumPurchaseValue, setSumPurchaseValue] = useState(0)
    const [monthlyPaymentRentAmount, setMonthlyPaymentRentAmount] = useState(0)
    const [partnersValues, setPartnersValues] = React.useState([]);
    const [clientsValues, setClientsValues] = React.useState([]);
    const [groupByAssets, setGroupByAssets] = useState([])
    const [values, setValues] = useState({
        partner: null,
        client: null
    })
    const [selectedAGId, setSelectedAGId] = useState(false)
    const [showAgrementDetail, setShowAgrementDetail] = useState()
    const [loggedUserRole, setLoggedUserRole] = useState();
    const [userPermission, setUserPermission] = useState({
        canEdit: true,
        canDelete: true,
        canView: true,
        canAdd: true,
        canImport: true,
        canBulkDelete: true,
        canBulkUpdate: true
    });
    useEffect(() => {
        if (data && data?.id && location) {
            setUserInfo(data)
            if (dashBoardAggList) {
                setAgreementList(dashBoardAggList)
            }
        }
    }, [data, location, dashBoardAggList])

    useEffect(() => {
        if (!gridApi) {
            return;
        }
        let allColumnIds = gridApi.columnController.gridColumns.map(
            (col) => col.colId
        );
        // let currentMapping = assetTypeFieldMapping.find(
        //   (mapping) => mapping.Asset_Name === selectedAssetType
        // );
        let columnsToShow = [];

        if (!columnsToShow || columnsToShow.length === 0) {
            gridApi.columnController.setColumnsVisible(allColumnIds, true);
            return;
        }

        if (columnsToShow.length > 0) {
            columnsToShow.push('actions');
        }
        gridApi.columnController.setColumnsVisible(allColumnIds, false);
        gridApi.columnController.setColumnsVisible(columnsToShow, true);
    }, [gridApi]);


    useEffect(() => {
        if (loggedUserRole) {
            //   if (loggedUserRole === 'partner_user' || loggedUserRole === 'partner_admin') {
            //     setUserPermission({
            //       canImport: false
            //     })
            //   }
            if (loggedUserRole === 'client_user' || loggedUserRole === 'client_admin') {
                setUserPermission({
                    canAdd: false,
                    canBulkDelete: false,
                    canImport: false,
                    canBulkUpdate: false
                })
            }
        }
    }, [loggedUserRole])

    const exportExcelData = async () => {
        generateExcel(gridApi, `Agreements-${moment().format('YYYY-MM-DD')}.xlsx`, 'agreements');
    }

    const onRowSelected = (params) => {
        let sum_purchase_value = 0;
        let monthlyPaymentRentAmount = 0;
        if (params.api && params.api.getSelectedRows().length > 0) {
            let selectedData = params.api.getSelectedRows()
            setSelectedDataCount(params.api.getSelectedRows().length)
            if (selectedData && selectedData.length > 0) {
                sum_purchase_value = selectedData.reduce((s, f) => {
                    // console.log("valueee", f.rent_per_month)
                    return s + (f.sum_purchase_value && !_.isNull(f.sum_purchase_value) ? parseFloat(f.sum_purchase_value) : 0);
                }, 0);
                monthlyPaymentRentAmount = selectedData.reduce((s, f) => {
                    // console.log("valueee", f.rent_per_month)
                    return s + (f.montly_payment_rent_or_lease && !_.isNull(f.montly_payment_rent_or_lease) ? parseFloat(f.montly_payment_rent_or_lease) : 0);
                }, 0);
            }
        } else {
            setSelectedDataCount(0)
        }
        setSumPurchaseValue(sum_purchase_value)
        setMonthlyPaymentRentAmount(monthlyPaymentRentAmount)
    };

    // useEffect(() => {
    //     // console.log("userDetail", userDetail)
    //     if (userInfo && userInfo.id && userInfo?.role?.name) {

    //     }
    // }, [])

    useEffect(() => {
        if (userInfo && userInfo.id && userInfo?.role?.name) {
            setLoggedUserRole(userInfo.role.name)
            setUserInfo(userInfo)
            setTableColumns(columns)
            // groupByAgreement()
            if (dashBoardAggList) {
                setAgreementList(dashBoardAggList)
            }
            if (!dashBoardAggList) {
                groupByAgreementAssets()

                // listsAllAgreement(gridApi, page)
            }

        }
    }, [userInfo])

    const listsAllAgreement = async (asssetData, page) => {
        setLoading(true)
        // let fields = '?limit=1&fields=ag_status,heading,agrement_info_no,eol_date,lease_start_date,type_of_product,client.name,client.clients_org_no,partner.company_org_no,partner.partner_name,sum_purchase_value'
        let fields = `${AGREEMENTFIELDS}`
        if (page === 'dashboard') {
            fields = `${dashboardfields.toString()}`
        }
        let options = {
            limit: -1,
            sort: '-id',
            fields: [fields]
        }
        if (userInfo.role) {
            if ((userInfo.role.name === "client_admin") || (userInfo.role.name === "client_user")) {

                options.filter = {
                    "client.id": {
                        _eq: userInfo.client.id
                    }
                }
                if (values.partner) {
                    options.filter = {
                        _and:
                            [
                                {
                                    "partner.id": {
                                        _eq: values.partner
                                    }
                                },
                                {
                                    "client.id": {
                                        _eq: userInfo.client.id
                                    }
                                }
                            ]
                    }
                }

            }
            else if (userInfo.role.name === 'partner_admin' || userInfo.role.name === 'partner_user') {
                // console.log("users_details", users_details)
                options.filter = {
                    "partner.id": {
                        _eq: userInfo.partner.id
                    }
                }
                if (values.client) {
                    options.filter = {
                        _and:
                            [
                                {
                                    "partner.id": {
                                        _eq: userInfo.partner.id
                                    }
                                },
                                {
                                    "client.id": {
                                        _eq: values.client
                                    }
                                }
                            ]
                    }
                }
            } else {
                if (values.client && !values.partner) {
                    options.filter = {
                        _and:
                            [
                                {
                                    "client.id": {
                                        _eq: values.client
                                    }
                                }
                            ]
                    }
                }
                if (values.partner && !values.client) {
                    options.filter = {
                        _and:
                            [
                                {
                                    "partner.id": {
                                        _eq: values.partner
                                    }
                                },

                            ]
                    }
                }
                if (values.partner && values.client) {
                    options.filter = {
                        _and:
                            [
                                {
                                    "partner.id": {
                                        _eq: values.partner
                                    }
                                },
                                {
                                    "client.id": {
                                        _eq: values.client
                                    }
                                }
                            ]
                    }
                }
            }

        }

        const result = await directUSAPICall().items('aggrement').readMany(options);
        if (result.data && result.data.length > 0) {
            if (asssetData && asssetData.length > 0) {
                result.data.forEach((item) => {
                    let response = asssetData.filter((row) => row.aggrement === item.id);
                    item.totalAssets = response && response.length > 0 ? parseInt(response[0].count.id) : 0
                })
            }
            setAgreementList(result.data)
            setLoading(false)
        } else {
            setLoading(false)
            setAgreementList([])
        }
    }

    const groupByAgreementAssets = async () => {
        let options = {
            limit: -1,
            groupBy: ['aggrement'],
            aggregate: {
                count: 'id'
            }
        }
        if (userInfo.role) {
            if ((userInfo?.role?.name === "client_admin") || (userInfo?.role?.name === "client_user")) {
                options.filter = {
                    "aggrement": {
                        "client": {
                            _eq: userInfo.client.id
                        }
                    }
                }
            } else if (userInfo?.role?.name === 'partner_admin' || userInfo?.role?.name === 'partner_user') {
                options.filter = {
                    "aggrement": {
                        "partner": {
                            _eq: userInfo.partner.id
                        }
                    }
                }

            }
            if (values.client && !values.partner) {
                options.filter = {
                    _and:
                        [{
                            "aggrement": {
                                "client": {
                                    _eq: values.client
                                }
                            }
                        }]
                }
            }
            if (values.partner && !values.client) {
                options.filter = {
                    _and:
                        [
                            {
                                "aggrement": {
                                    "partner": {
                                        "_eq": values.partner
                                    }
                                }
                            }
                        ]
                }
            }

            if (values.partner && values.client) {
                options.filter = {
                    _and:
                        [
                            {
                                "aggrement": {
                                    "partner": {
                                        "_eq": values.partner
                                    }
                                }
                            },
                            {
                                "aggrement": {
                                    "client": {
                                        _eq: values.client
                                    }
                                }
                            }
                        ]
                }
            }
        }
        const result = await directUSAPICall().items('Asset').readMany(options);
        if (result.data && result.data.length > 0) {
            setGroupByAssets(result.data)
            listsAllAgreement(result.data)
        } else {
            listsAllAgreement([])
        }
    }
    const gotoEdit = (values, type) => {
        // console.log("propppss", values)
        if (type === 'view') {
            setSelectedAGId(values.data.id)
            setShowAgrementDetail(true)
        } else {
            history.push(`/updateagreement/${values.data.id}`)
        }
    }
    const deleteConfirm = (values) => {
        setShowDeleteDialog(true)
        let arr = [];
        arr.push(values.data)
        setDeleteId(arr)
    }

    const LinkCellRenderer = (params) => {
        let color = ''
        if (params.data && params.data.ag_status) {
            let monthDiff = getMonthDiff(params.data.eol_date);
            let status = params.data.ag_status.toUpperCase();
            if (status === 'INTERIM') {
                color = 'yellow'
            } else if (status === 'ONGOING') {
                color = 'green'

            } else if (status === 'ENDING') {
                color = 'blue'
                if (monthDiff >= 3 && monthDiff <= 6) {
                    color = 'blue'
                } else if (monthDiff <= 3) {
                    color = 'red'
                }
            } else if (status === 'CLOSED') {
                color = 'black'
            }

        }
        return <>
            {
                color ? <Badge>
                    <i className="fas fa-circle" style={{ color: color }} />
                </Badge> : null
            }
            <Link target="_blank" to={`/agrementassets/${params.data.id}`}>{params.value}</Link></>
    }

    const BtnActionCellRenderer = (params) => {

        return <div>
            <i onClick={() => gotoEdit(params, 'view')} className="fas fa-eye" />
            {
                (params?.data?.user_created && (userInfo?.role?.name === 'partner_user' || userInfo?.role?.name === 'partner_admin' && userInfo?.role?.name === 'client_user' || userInfo?.role?.name === 'client_admin')) ? ((userInfo?.id === params.data.user_created.id) ? <i onClick={() => gotoEdit(params)} className="fas fa-edit" /> : null) : <i onClick={() => gotoEdit(params)} className="fas fa-edit" />
            }
            {
                (params?.data?.user_created && (userInfo?.role?.name === 'partner_user' || userInfo?.role?.name === 'client_user' || userInfo?.role?.name === 'client_admin')) ? (userInfo?.id === params.data.user_created.id) ? params.data.totalAssets === 0 ? <i onClick={() => deleteConfirm(params)} className="fas fa-trash-alt deleteone" /> : null : null : params.data.totalAssets === 0 ? <i onClick={() => deleteConfirm(params)} className="fas fa-trash-alt deleteone" /> : null
            }
        </div>
    }

    const buildColumnDefinitions = (columnDefs) => {
        return columnDefs && columnDefs.map((columnDef, index) => {
            if (columnDef.type === 'date') {
                // console.log({ columnDef });
            }
            let columnDefinition = {
                headerName: page !== 'dashboard' ? index !== 0 ? columnDef.title : '' : columnDef.title,
                cellRenderer: index === 0 && page !== 'dashboard' ? 'ActionCellRenderer' : columnDef.field === 'agrement_info_no' ? 'LinkCellRenderer' : (columnDef.field === 'statusrenderer') ? 'StatusCellRenderer' : false,
                headerCheckboxSelection: index === 0 && page !== 'dashboard' ? true : false,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: index === 0 && page !== 'dashboard' ? true : false,
                field: columnDef.field,
                // editable: columnDef.editable,
                filter: page !== 'dashboard' ? (index === 0) ? 'none' : 'agTextColumnFilter' : 'agTextColumnFilter',
                sortable: true,
                resizable: true,
                // hide: columnDef.hide,
                width: index !== 0 ? (columnDef.field.length <= 7 ? 90 : columnDef.field.length >= 12 ? 130 : columnDef.field.length >= 17 ? 160 : 180) : 130,

                valueGetter: columnDef.valueGetter,
                valueFormatter: (params) => {
                    // console.log("params", params.data.aggrement)
                    if (columnDef.type === 'currencyColumn') {
                        return params.value ? SVformatter(params.value) : ' ';
                    }
                    if (params.value && columnDef.type === 'date') {
                        return moment(params.value).format('YYYY-MM-DD');
                    }
                },
            };
            // if (columnDef.field === 'totalAssets') {
            //     columnDefinition.cellRenderer = params => {
            //         params.data.totalAssets = getAssetsCount(params.data.id)
            //         return params.data.totalAssets
            //     };

            // }
            return columnDefinition;
        });
    };

    const frameworkComponents = {
        BtnActionCellRenderer,
        LinkCellRenderer,
        ActionCellRenderer

    };
    const onModelUpdated = (params) => {
        // console.log("onModelUpdated" ,params)
        // if (gridApi.getSelectedRows().length > 0) {
        //     gridApi && gridApi.deselectAll()
        // }
        // params.api.sizeColumnsToFit();
    };
    // console.log("groupByAssets", groupByAssets)


    const dashboardColumns = [
        {
            title: 'AG. ref',
            field: 'agrement_info_no',
            filterPlaceholder: 'Agrement ref',
        }, {
            title: 'Title',
            field: 'heading',
            filterPlaceholder: 'heading'
        },
        {
            title: 'Status',
            field: 'ag_status',
            filterPlaceholder: 'status',
        }, {
            title: 'Lease start',
            filterPlaceholder: 'Lease start',
            field: 'lease_start_date',
            type: 'date'
        }, {
            title: 'EOL Date',
            field: 'eol_date',
            filterPlaceholder: 'EOL date',
            type: 'date'
        }, {
            title: 'Type of Product',
            field: 'type_of_product',
            filterPlaceholder: 'Type of product',
        }, {
            title: 'Partner',
            field: 'partner_name',
            filterPlaceholder: 'Partner Name',
            valueGetter: function (params) {
                if (params.data.partner) {
                    params.data.partner_name = params.data.partner.partner_name
                    params.data.company_org_no = params.data.partner.company_org_no
                }
                return params.data.partner_name
            }
        }, {
            title: 'Client',
            field: 'client_name',
            filterPlaceholder: 'Client Name',
            valueGetter: function (params) {
                // console.log("params.data", params.data)
                params.data.client_name = ''
                if (params.data.client) {
                    params.data.client_name = params.data.client.name
                    params.data.clients_org_no = params.data.client.clients_org_no
                }
                return params.data.client_name
            }
        },
        {
            title: 'Total no of Assets',
            field: 'totalAssets',
            filterPlaceholder: 'Total Assets',

        }
    ]
    const columns = [


        {
            title: 'Actions',
            field: ''
        },
        {
            title: 'AG. ID',
            field: 'id',
        },
        {
            title: 'AG. ref',
            field: 'agrement_info_no',
            filterPlaceholder: 'Agrement ref',

        }, {
            title: 'Sub AG. Ref',
            field: 'sub_agrement_ref',
            filterPlaceholder: 'Sub Agree Ref',

        },
        {
            title: 'Total Assets',
            field: 'totalAssets',
            filterPlaceholder: 'Total Assets'
        },
        {
            title: 'Title',
            filterPlaceholder: 'Title',
            field: 'heading',
        }, {
            title: 'Status',
            filterPlaceholder: 'Status',
            field: 'ag_status',

        }, {
            title: 'Partner',
            filterPlaceholder: 'Partner Name',
            field: 'partner_name',
            valueGetter: function (params) {
                params.data.partner_name = ""
                if (params.data.partner) {
                    params.data.partner_name = params.data.partner.partner_name
                    params.data.company_org_no = params.data.partner.company_org_no
                }
                return params.data.partner_name
            }
        }
        , {
            title: 'Partner org',
            filterPlaceholder: 'Partner org',
            field: 'company_org_no'
        }, {
            title: 'Client',
            filterPlaceholder: 'Client',
            field: 'client_name',
            valueGetter: function (params) {
                params.data.client_name = ""
                if (params.data.client) {
                    params.data.client_name = params.data.client.name ? params.data.client.name : ''
                    params.data.clients_org_no = params.data.client.clients_org_no ? params.data.client.clients_org_no : ''
                }
                return params.data.client_name
            }
        }, {
            title: 'Client org',
            filterPlaceholder: 'Client org',
            field: 'clients_org_no',

        }, {
            title: 'Lease start',
            filterPlaceholder: 'Lease start',
            field: 'lease_start_date',
            type: 'date'
        }, {
            title: 'EOL date',
            filterPlaceholder: 'EOL date',
            field: 'eol_date',
            type: 'date'
        }, {
            title: 'Supplier',
            filterPlaceholder: 'Supplier ',
            field: 'supplier_name',
            valueGetter: function (params) {
                let name = ''
                if (params.data.founding_partner) {
                    name = params.data.supplier_name = params.data.founding_partner.supplier_name
                }
                return name
            }

        }, {
            title: 'Supplier org',
            filterPlaceholder: 'Supplier org',
            field: 'supplier_org_no',
            valueGetter: function (params) {
                let org = ''
                if (params.data.founding_partner) {
                    org = params.data.supplier_org_no = params.data.founding_partner.supplier_org_no
                }
                return org
            }
        },
        {
            title: 'Type of agrmt.',
            filterPlaceholder: 'Type of agrmt.',
            field: 'typeofaggrements',

        }, {
            title: 'lease Type',
            filterPlaceholder: 'lease Type',
            field: 'type_of_leasing',

        }, {
            title: 'Type of product',
            filterPlaceholder: 'Type of product',
            field: 'type_of_product',

        }, {
            title: 'Project',
            filterPlaceholder: 'Project',
            filterPlaceholder: 'Project',
            field: 'project',
        }, {
            title: 'Sales Person',
            filterPlaceholder: 'Sales Person',
            filterPlaceholder: 'Sales Person',
            field: 'salesperson',

        }, {
            title: 'Lease duration',
            filterPlaceholder: 'Lease duration',
            filterPlaceholder: 'Lease duration',
            field: 'no_of_month_lease',

        }, {
            title: 'Billing period',
            filterPlaceholder: 'Billing period',
            filterPlaceholder: 'Billing period',
            field: 'billing_period',

        }, {
            title: 'Original sign date',
            filterPlaceholder: 'Original sign date',
            field: 'original_sign_date',
            type: 'date'

        }, {
            title: 'Sum purchase value budget',
            filterPlaceholder: 'Sum purchase value budget',
            field: 'sum_purchase_value_budget',
            type: 'currencyColumn'
        }, {
            title: 'Sum purchase value',
            filterPlaceholder: 'Sum purchase value',
            filterPlaceholder: 'Sum purchase value',
            field: 'sum_purchase_value',
            type: 'currencyColumn'
        }, {
            title: 'Pre sold(%)',
            filterPlaceholder: 'Pre sold',
            field: 'pre_sold',

        }, {
            title: 'Founding(%)',
            filterPlaceholder: 'Founding(%)',
            field: 'founding',

        },
        {
            title: 'Client interest(%)',
            filterPlaceholder: 'Client interest(%)',
            field: 'client_interest',

        }, {
            title: 'Interim income',
            filterPlaceholder: 'Interim income',
            field: 'Interim_income',
            type: 'currencyColumn'
        },
        {
            title: 'Add to purchase value',
            filterPlaceholder: 'Add to purchase value',
            field: 'add_to_purchase_value',
            type: 'currencyColumn',

        }, {
            title: 'Montly payment Ratio',
            filterPlaceholder: 'Montly payment Ratio',
            field: 'montly_payment_ratio',
        },
        {
            title: 'Montly payment rent/lease',
            filterPlaceholder: 'Montly payment rent/lease',
            field: 'montly_payment_rent_or_lease',
            type: 'currencyColumn',
        }, {
            title: 'Commision to partner(%)',
            filterPlaceholder: 'Commision to partner(%)',
            field: 'commision_to_partner_percentage',

        }, {
            title: 'Commision to partner',
            filterPlaceholder: 'Commision to partner',
            field: 'commision_partner_amount',
            type: 'currencyColumn',


        }, {
            title: 'Value to finance',
            filterPlaceholder: 'Value to finance',
            field: 'value_to_finance',
            type: 'currencyColumn',

            type: 'currencyColumn'
        }, {
            title: 'EOL percentage(%)',
            filterPlaceholder: 'EOL percentage(%)',
            field: 'EOL_percentage',

        }, {
            title: 'EOL value',
            filterPlaceholder: 'EOL value',
            field: 'EOL_value_amt',
            type: 'currencyColumn'
        }, {
            title: 'Type of depreciation',
            filterPlaceholder: 'Type of depreciation',
            field: 'type_of_depreciation',

        }, {
            title: 'present value',
            filterPlaceholder: 'present value',
            field: 'present_value',

        },
        {
            title: 'Clients attn',
            filterPlaceholder: 'Clients attn',
            field: 'clients_attn',
            valueGetter: (params) => {
                return params.data?.clients_attn ? (params.data?.clients_attn?.first_name ? params.data?.clients_attn?.first_name + ' ' + params.data?.clients_attn?.last_name : null) : null
            },
        },
        {
            title: 'clients attn notice',
            filterPlaceholder: 'clients attn notice',
            field: 'clients_attn_notice',

        }, {
            title: 'notice',
            filterPlaceholder: 'notice',
            field: 'notice',

        }, {
            title: 'Invoicing to',
            filterPlaceholder: 'Invoicing to',
            field: 'invoicing_to',

        }, {
            title: 'Notice period before EOL',
            filterPlaceholder: 'Notice period before EOL',
            field: 'notice_period_before_EOL',

        }, {
            title: 'Latest notice day',
            filterPlaceholder: 'Latest notice day',
            field: 'latest_notice_day',
            type: 'date'

        }, {
            title: 'Notice before notice day',
            filterPlaceholder: 'Notice before notice day',
            field: 'notice_before_notice_day',
            type: 'date'

        },
        {
            title: 'Notice before notice month',
            filterPlaceholder: 'Notice before notice month',
            field: 'notice_before_notice_month',

        },
        {
            title: 'Index connected to agreement',
            filterPlaceholder: 'Index connected to agreement',
            field: 'index_connected_to_agreement',

        }, {
            title: 'Index based start information',
            filterPlaceholder: 'Index based start information',
            field: 'Index_based_start_information',

        }, {
            title: 'Created By',
            field: 'user_created',
            editable: false,
            valueGetter: (params) => {
                return params.data?.user_created ? (params.data?.user_created?.first_name + ' ' + params.data?.user_created?.last_name) : null
            },
        }, {
            title: 'Created at',
            field: 'date_created',
            editable: false,
            type: 'date'
        }, {
            title: 'Updated By',
            field: 'user_updated',
            editable: false,
            valueGetter: (params) => {
                return params.data?.user_updated ? params.data?.user_updated?.first_name + ' ' + params.data?.user_updated?.last_name : null
            },
        }, {
            title: 'Updated at',
            field: 'date_updated',
            editable: false,
            type: 'date'
        },
    ];



    const onGridReady = (params) => {
        setGridApi(params.api);
    };
    const handleBulkDelete = async (props) => {
        let selectedRows = gridApi.getSelectedRows();
        setDeleteId(selectedRows)
        setShowDeleteDialog(true)
    };
    useEffect(() => {
        if (data && data?.id) {
            if (data.role) {
                if (data.role.name === 'client_admin' || data.role.name === 'client_user') {
                    values.client = data.client.id;
                    getClientList(null, data?.client?.id)
                }
                else if (data.role.name === 'partner_admin' || data.role.name === 'partner_user') {
                    values.partner = data?.partner?.id
                    getClientList(data?.partner?.id, null)
                } else {
                    getClientList(null, null)
                }
            }
        }
    }, [data])

    const getClientList = (partnerId = null, client = null) => {
        let queryFields = 'client.name,client.id,client.clients_org_no,partner.company_org_no,partner.partner_name,partner.id'
        let filter = ''
        if (partnerId) {
            values.partner = partnerId
            filter = `?filter[partner][_eq]=${partnerId}&fields=`
            // listsAllAgreement(partnerId, '')

        }
        if (client) {
            values.client = client
            filter = `?filter[client][_eq]=${client}&fields=`
            // listsAllAgreement('', client)
        }

        ClientAddessList(queryFields, filter)
            .then(({ data: { data } }) => {

                if (data && data.length > 0) {
                    if (!partnerId) {
                        values.client = "";
                        setValues({ ...values })

                        let partners = data.map(
                            (item) => item.partner && item.partner
                        );
                        partners = partners.filter(function (e) { return e != null; });
                        partners = _.orderBy(partners, ['partner_name'], ['asc']);
                        setPartnersValues(_.uniqBy(partners, 'id'))

                    }
                    if (partnerId || client) {
                        // values.partner = "";
                        let clients = data.map(
                            (item) => item.client
                        );
                        clients = _.orderBy(clients, ['name'], ['asc']);
                        values.client = '';
                        setValues({ ...values })
                        setClientsValues(_.uniqBy(clients, 'id'))
                    }
                }
            })
            .catch((err) => {
                throw err;
                // setIsloading(false)
                //   setStatus('The login detail is incorrect')
            })
    }


    // console.log("agreementList", agreementList)
    return (
        <>
            <PageLoader pageLoaderOpen={loading} setPageLoaderOpen={setLoading} />
            {
                location.pathname !== '/dashboard' &&
                <><PageTitle breadcrumbs={[
                    {
                        title: 'Agreement List',
                        path: '/dashboard',
                        isSeparator: false,
                        isActive: false,
                    }
                ]}>{'Agreements List'}</PageTitle>
                    <div id="kt_subheader" className="subheader py-2 py-lg-4 subheader-solid">
                    </div>
                </>
            }
            <div className="card card-custom gutter-b">
                <div className='card-header border-0'>
                    <div className={`card-title ${page}-title`}>
                        <h3 className='card-label'>
                            {'Agreements List'}
                        </h3>
                    </div>
                    {
                        page !== '/dashboard' &&
                        <div className="card-toolbar">
                            {page !== 'dashboard' && (userPermission && userPermission && userPermission.canBulkDelete) && <Button variant="contained" color="primary" onClick={() => gridApi && gridApi.getSelectedRows().length > 0 && handleBulkDelete(gridApi)} className={classes.button}>
                                <span><i className="fas fa-trash-alt builkdelete"></i></span>
                            </Button>
                            }
                            {
                                ((userPermission && userPermission && userPermission.canImport) && (page !== 'dashboard') && (<Tooltip title="Upload"><Link onClick={() => setFileUploadOpen(true)} to='#' className='align-self-center'>
                                    <Button variant="contained" color="primary" className={classes.button}>
                                        <span><i className="fas fa-upload"></i></span>
                                    </Button>
                                </Link></Tooltip>))
                            }
                            {
                                page !== 'dashboard' && <Tooltip title="Download"><Button variant="contained" onClick={() => exportExcelData()} color="primary" className={classes.button}>
                                    <span><i className="fas fa-download"></i></span>
                                </Button>
                                </Tooltip>
                            }

                            {
                                page !== 'dashboard' && <Link to={`/createagreement`} className='align-self-center'>
                                    <Button variant="contained" color="primary" className={classes.button}>
                                        <i className="fas fa-plus"></i> {'Create Agreement'}
                                    </Button>
                                    {/* <button type="button" className="btn btn-primary">Create Asset</button> */}
                                </Link>
                            }
                        </div>}
                    {
                        < div style={{ width: '100%' }}>
                            <div className="form-group row">
                                {
                                    (userInfo?.role?.name !== 'partner_admin' && userInfo?.role?.name !== 'partner_user') &&
                                    <div className='col-lg-4'>
                                        <Autocomplete
                                            id="size-small-standard"
                                            // value={values.partner}
                                            getOptionLabel={(option) => option.partner_name + " - " + option.company_org_no}
                                            options={partnersValues}
                                            onChange={(e, option) => {
                                                if (option) {
                                                    getClientList(option.id)
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    // value={values.partner}
                                                    label="Partner"
                                                    margin="normal"
                                                    variant="standard"
                                                />
                                            )}
                                        />
                                    </div>}
                                {
                                    (userInfo?.role?.name !== 'client_admin' && userInfo?.role?.name !== 'client_user') &&

                                    <div className='col-lg-4'>
                                        <Autocomplete
                                            id="size-small-standard"
                                            getOptionLabel={(option) => option.name + " - " + option.clients_org_no}
                                            options={clientsValues}
                                            onChange={(e, option) => {
                                                if (option) {
                                                    setValues({ ...values, client: option.id })
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    // value={values.client}
                                                    label="Client"
                                                    margin="normal"
                                                    variant="standard"
                                                />
                                            )}
                                        />
                                    </div>
                                }
                                <div className='col-lg-4'>

                                    <div className={'agbuttonContainer'}>
                                        <Box className="box">

                                            <Button
                                                variant='contained'
                                                onClick={() => {
                                                    setValues({
                                                        partner: null,
                                                        client: null
                                                    })
                                                    groupByAgreementAssets()

                                                }
                                                }
                                                color='secondary'
                                                className={classes.button}
                                            >
                                                Reset</Button>
                                            <Button
                                                variant='contained'
                                                onClick={() => {
                                                    groupByAgreementAssets()
                                                }
                                                }
                                                color='primary'
                                                className={classes.button}
                                            >
                                                Search
                                            </Button>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div
                    className='ag-theme-alpine'
                    style={{
                        width: '100%',
                        height: '80vh',
                        boxShadow: '0 1px 15px 1px rgba(69,65,78,.08)',
                    }}
                >
                    <AgGridReact
                        rowData={agreementList}
                        rowBuffer={500}
                        debounceVerticalScrollbar={true}
                        columnDefs={buildColumnDefinitions(page !== 'dashboard' ? tablecolumns : dashboardColumns)}
                        frameworkComponents={frameworkComponents}
                        suppressDragLeaveHidesColumns={true}
                        onGridReady={onGridReady}
                        rowSelection='multiple'
                        editType='fullRow'
                        enableCellTextSelection={true}
                        overlayLoadingTemplate={overlayLoadingTemplate}
                        overlayNoRowsTemplate={noRowsTemplate}
                        // onRowSelected={onRowSelected}
                        pagination={true}
                        paginationPageSize={pageSize}
                        suppressRowClickSelection={true}
                        alwaysShowVerticalScroll={true}
                        // scrollbarWidth={'2000'}
                        gotoEdit={gotoEdit}
                        deleteConfirm={deleteConfirm}
                        floatingFilter={true}
                        onModelUpdated={onModelUpdated}
                        onRowSelected={onRowSelected}
                    // getRowHeight={'46'}
                    ></AgGridReact>
                    <CustomDropdown
                        options={[25, 50, 100, 500]}
                        title={'Page Size'}
                        value={pageSize}
                        onChange={(value) => {
                            setPageSize(value);
                            gridApi.paginationSetPageSize(value);
                        }}
                    />
                    {/* {
                        page !== 'dashboard' && selectedDataCount > 0 && <div className="footerBar">
                            <table>
                                <thead>
                                    <th>Sum of Purchase</th>
                                    <th>Monthly payment rent</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="price" >{SVformatter(sumPurchaseValue)}</td>
                                        <td className="price" >{SVformatter(monthlyPaymentRentAmount)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    } */}
                </div>

            </div>
            <DeleteConfirmDialog
                title="Agreemet"
                content="are you sure you want to delete this Agreement ?"
                open={showDeleteDialog}
                setOpen={setShowDeleteDialog}
                tableName="aggrement"
                deleteId={deleteId}
                setIsDeleted={groupByAgreementAssets}
            />
            <FileUpload
                open={fileUploadOpen}
                setOpen={setFileUploadOpen}
                allAssets={agreementIdsList}
                title='File upload'
                handleCancel={setFileUploadOpen}
                getNewData={groupByAgreementAssets}

            />
            <AgreementViewDialog
                open={showAgrementDetail}
                setOpen={setShowAgrementDetail}
                fieldDisable={true}
                title={`Agreement - ${selectedAGId}`}
                selectedAGId={selectedAGId}
                setSelectedAGId={setSelectedAGId}
            />
        </>
    )
}

export { TableComponent }

