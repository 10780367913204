import { API_URL } from './constant'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import axios from 'axios'
import moment from "moment";
import { Directus } from '@directus/sdk';
import { getassetsList } from "../../../app/modules/assets/redux/AssetsCrud";


export function directUSAPIToken() {
    const directus = new Directus(API_URL);

    const token = directus.auth.token;

    return token
}

export function directUSAPICall() {
    const directus = new Directus(API_URL);

    // const token = directus.auth.token;
    // const result = new Directus(API_URL, {
    //     auth: {
    //         staticToken: localStorage.getItem("")
    //     },
    // });
    return directus
}
// const fetch = require('node-fetch');
export function cleanEmptyObj(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
            delete obj[propName];
        }
    }
    return obj
}

export function getMonthDiff(eoldate) {
    let today = moment().format('YYYY-MM-DD')
    const getDate = (date) => moment(date, 'YYYY-MM-DD').startOf('month')
    eoldate = moment(eoldate).format('YYYY-MM-DD');
    let diff = 0
    if (eoldate > today) {
        diff = Math.abs(getDate(today).diff(getDate(eoldate), 'months'));
    }
    // console.log("diff", diff)
    return diff
}

export function numberWithCommas(x) {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '';
}

export function SVformatter(v) {
    return v ? new Number(Math.round(v)).toLocaleString("sv-SE", { minimumFractionDigits: 0 }) : 0;
}
export function SVPercentageformatter(x) {
    console.log('*****', x)
    return x ? x.toString().replace(",", ".") : '';
}
export function removeSpaceCommaformatter(str) {
    var regex = /[.,\s]/g;
    str = str.toString()
    var result = str.replace(regex, '');
    return result
}

export function deleteData(tableName, ids) {
    const directus = new Directus(API_URL);
    if (tableName === 'users') {
        return directus.items(tableName).deleteMany(ids);
    } else {
        return directus.items(tableName).deleteMany(ids);
    }
}

export function getCounts(tableName, param) {
    const headers = {
        'Authorization': `Bearer ${directUSAPIToken()}`
    }
    return axios.get(`${API_URL}/items/${tableName}${param}`, {
        params: {},
        headers
    })
}

export const checkExist = async (tableName, filter) => {
    let options = {
        fields: ['id']
    }
    options.filter = filter;
    const result = await directUSAPICall().items(tableName).readMany(options);
    let count = 0
    if (result.data && result.data.length > 0) {
        count = result.data.length
    }
    return count
}

export const getMontlyPaymentRentorLease = (sumPurchaseValue, montlypaymentratio) => {
    let montlypaymentvalue = 0;
    if ((sumPurchaseValue && montlypaymentratio)) {
        montlypaymentvalue = (parseFloat(sumPurchaseValue) * parseFloat(montlypaymentratio)) / 100;
        // setCommisiontopartneramount(EOL_value_amt)
        return SVformatter(montlypaymentvalue)
        //   form.setFieldsValue({
        //     montly_payment_rent_or_lease: SVformatter(montlypaymentvalue)
        //   })
    }
}

export const getInterestProfit = (founding, clientInterest) => {
    let interestProfit = 0;
    if ((founding && clientInterest) && (clientInterest > founding)) {
        interestProfit = ((parseFloat(clientInterest) - parseFloat(founding))).toFixed(2);
    }
    return interestProfit
}

export const getAddPurchaseValue = (sumPurchaseValue, montlypaymentratio, noOfMonthLease, eolvalueAmt) => {
    let add_purchase_value = 0;
    if ((sumPurchaseValue && montlypaymentratio && noOfMonthLease)) {
        add_purchase_value = (((parseFloat(sumPurchaseValue)) * (parseFloat(montlypaymentratio) / 100)) * parseFloat(noOfMonthLease)) + parseInt(eolvalueAmt);
    }
    return SVformatter(add_purchase_value)
}

export const getCommisionAmount = (sumPurchaseValue, commisiontopartnerpercentage) => {
    let commission_amount = 0;
    if ((sumPurchaseValue && commisiontopartnerpercentage)) {
        commission_amount = (parseFloat(sumPurchaseValue) * parseFloat(commisiontopartnerpercentage)) / 100;
    }
    return SVformatter(commission_amount)
}

export const getCommissionAmount = (sumPurchaseValue, commisiontopartnerpercentage) => {
    let commission_amount = 0;
    if ((sumPurchaseValue && commisiontopartnerpercentage)) {
        commission_amount = (parseFloat(sumPurchaseValue) * parseFloat(commisiontopartnerpercentage)) / 100;
    }
    return SVformatter(commission_amount)
}

export const getEOLValueAmt = (sumPurchaseValue, eolpercentage) => {
    let EOLvalueamt = 0;
    if ((sumPurchaseValue && eolpercentage)) {
        EOLvalueamt = (parseFloat(sumPurchaseValue) * parseFloat(eolpercentage)) / 100;
        // setCommisiontopartneramount(EOL_value_amt)
    }
    return SVformatter(EOLvalueamt)
}

export const getNoticeBeforeNoticeDay = (latest_notice_day, notice_before_notice_month) => {
    let noticeperiodEOL = null
    if (latest_notice_day && notice_before_notice_month) {
        noticeperiodEOL = moment(latest_notice_day).subtract(notice_before_notice_month, 'M').subtract(1, "days").format('YYYY-MM-DD');
        noticeperiodEOL = moment(noticeperiodEOL, 'YYYY-MM-DD')
    }
    return noticeperiodEOL
}

export const getLatestNoticeDay = (eol_date, noticeperiodbeforeEOL) => {
    let noticeperiodEOL = null
    if (eol_date && noticeperiodbeforeEOL) {
        noticeperiodEOL = moment(eol_date).subtract(noticeperiodbeforeEOL, 'M').subtract(1, "days").format('YYYY-MM-DD')
    }
    return noticeperiodEOL

    // console.log("noticeperiodbeforeEOL", noticeperiodbeforeEOL)

}

export function getDatesFromDateRange(billing_period, rentperiod_tax, startDate, endDate, format, counter) {
    startDate = moment(startDate, format);
    endDate = moment(endDate, format);

    let dates = [];
    let fromDate = startDate.clone();
    let toDate = fromDate.clone().add(counter, "year").startOf("year").add(-1, "day");
    do {
        let from_date = fromDate.format(format);
        let to_date = (toDate < endDate) ? toDate.format(format) : endDate.format(format);
        let yearRents = getMonths(from_date, to_date);
        const perMonths = parseFloat(rentperiod_tax) / parseInt(billing_period)
        let rent_amount = (parseInt(yearRents) * perMonths);
        dates.push({
            "from": from_date,
            "to": to_date,
            "months": yearRents,
            "rentamount": rent_amount ? Math.round(rent_amount) : 0
        });
        fromDate = moment(toDate, format).add(1, "day").clone();
        toDate = fromDate.clone().add(counter, "year").startOf("year").add(-1, "day");
    } while (fromDate < endDate);
    return dates;
}


export function getMonths(startdate, enddate) {
    let firstDate = moment(startdate, 'YYYY-MM-DD'); //Create date using string-format constructor
    let secondDate = moment(enddate, 'YYYY-MM-DD');
    let duration = moment.duration(secondDate.diff(firstDate));
    return Math.round(duration.asMonths());
}
export const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));

export const generateExcel1 = (columns, currentRowData, fileName) => {
    let excelColumns = columns
        .map((column) => column.field);
    excelColumns = excelColumns.filter((item) => item !== undefined);
    currentRowData.forEach((response) => {
        response.date_created = moment(response.date_created).format('YYYY-MM-DD')
        response.date_updated = response.date_updated && moment(response.date_updated).format('YYYY-MM-DD')
        response.user_created = response?.user_created?.first_name + ' ' + response?.user_created?.last_name
        response.user_updated = response.user_updated && response?.user_updated?.first_name + ' ' + response?.user_updated?.last_name
    })
    let excelData = currentRowData.map((row) =>
        pickExcelFields(row, excelColumns)
    );
    // console.log("currentRowData", currentRowData)
    // console.log("excelColumns", excelColumns)
    //     //  return
    var worksheet = XLSX.utils.json_to_sheet(excelData, {
        header: excelColumns.filter(
            (col) => Object.keys(excelData[0]).indexOf(col) > -1
        ),
    });
    var new_workbook = XLSX.utils.book_new();
    // return
    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'Sheet1');

    XLSX.writeFile(new_workbook, fileName, { type: 'file' });
};

export const generateExcel = (gridApi, fileName, page = null, tablecolumns = null) => {
    let excelColumns = gridApi.columnController
        .getAllDisplayedColumns()
        .map((column) => column.colDef.field);
    if (tablecolumns) {
        excelColumns = tablecolumns.map((column) => column.field);
    }
    let currentRowData = [];

    if (gridApi.getSelectedRows().length === 0) {
        currentRowData = gridApi.rowModel.rowsToDisplay.map((row) => row.data);
    } else {
        currentRowData = gridApi.getSelectedRows().map((row) => row);
    }
    if (page === 'assets') {
        currentRowData.forEach((params) => {
            params.agrement_info_no = params?.aggrement?.agrement_info_no
            params.typeofaggrements = params?.aggrement?.typeofaggrements;
            params.sub_agrement_ref = params?.aggrement?.sub_agrement_ref;
            params.partner_name = params.aggrement?.partner?.partner_name;
            params.client_name = params.aggrement?.client?.name;
            params.company_org_no = params.aggrement?.partner?.company_org_no;
            params.clients_org_no = params.aggrement?.client?.clients_org_no;
            params.lease_start_date = moment(params?.aggrement?.lease_start_date).format('YYYY-MM-DD');
            params.eol_date = moment(params?.aggrement?.eol_date).format('YYYY-MM-DD');
            params.ag_status = params?.aggrement?.ag_status;
            params.original_sign_date = params?.aggrement?.original_sign_date ? moment(params?.aggrement?.original_sign_date).format('YYYY-MM-DD') : '';
            params.type_of_leasing = params?.aggrement?.type_of_leasing;
            params.no_of_month_lease = params?.aggrement?.no_of_month_lease;
            params.montly_payment_ratio = params?.aggrement?.montly_payment_ratio;
            params.billing_period = params?.aggrement?.billing_period;
            params.supplier_org_no = params?.aggrement?.founding_partner?.supplier_org_no;
            params.supplier_name = params?.aggrement?.founding_partner?.supplier_name;
            params.date_created = moment(params.date_created).format('YYYY-MM-DD')
            params.date_updated = params.date_updated && moment(params.date_updated).format('YYYY-MM-DD')
            params.user_created = params?.user_created?.first_name + ' ' + params?.user_created?.last_name
            params.user_updated = params.user_updated && params?.user_updated?.first_name + ' ' + params?.user_updated?.last_name

        })
    }
    if (page === 'agreements') {
        currentRowData.forEach((params) => {
            params.partner_name = params.partner?.partner_name;
            params.client_name = params?.client?.name;
            params.company_org_no = params?.partner?.company_org_no;
            params.clients_org_no = params?.client?.clients_org_no;
            params.lease_start_date = moment(params?.lease_start_date).format('YYYY-MM-DD');
            params.eol_date = moment(params?.eol_date).format('YYYY-MM-DD');
            if (params?.original_sign_date) {
                params.original_sign_date = moment(params?.original_sign_date).format('YYYY-MM-DD');
            }
            if (params?.latest_notice_day) {
                params.latest_notice_day = moment(params?.latest_notice_day).format('YYYY-MM-DD');
            }
            if (params?.notice_before_notice_day) {
                params.notice_before_notice_day = moment(params?.notice_before_notice_day).format('YYYY-MM-DD');
            }
            params.supplier_org_no = params?.founding_partner?.supplier_org_no;
            params.supplier_name = params?.founding_partner?.supplier_name;
            params.date_created = moment(params.date_created).format('YYYY-MM-DD')
            params.date_updated = params.date_updated && moment(params.date_updated).format('YYYY-MM-DD')
            params.user_created = params?.user_created?.first_name + ' ' + params?.user_created?.last_name
            params.user_updated = params.user_updated && params?.user_updated?.first_name + ' ' + params?.user_updated?.last_name

        })
    }
    // console.log("currentRowData", currentRowData)

    if (page === 'client') {
        currentRowData.forEach((item) => {
            item.partner_name = item.partner ? item.partner.partner_name : '';
            item.partner_org_no = item.partner ? item.partner.company_org_no : '';
            item.client_name = item.client ? item.client.name : '';
            item.clients_org_no = item.client ? item.client.clients_org_no : '';
            item.phone_number = item.client ? item.client.phone_number : '';
            item.city = item.client ? item.client.city : '';
            item.zipcode = item.client ? item.client.zipcode : '';
            item.created_user = `${item.created_user ? item.created_user.firstname : ''} ${item.created_user ? item.created_user.lastname : ''}` || '';
            item.updated_user = `${item.updated_user ? item.updated_user.firstname : ''} ${item.updated_user ? item.updated_user.lastname : ''}` || '';
        })
    }

    let excelData = currentRowData.map((row) =>
        pickExcelFields(row, excelColumns)
    );
    var worksheet = XLSX.utils.json_to_sheet(excelData, {
        header: excelColumns.filter(
            (col) => Object.keys(excelData[0]).indexOf(col) > -1
        ),
    });
    var new_workbook = XLSX.utils.book_new();
    // return
    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'Sheet1');

    XLSX.writeFile(new_workbook, fileName, { type: 'file' });
};

export const pickExcelFields = (data, keys) => {
    // console.log(data, "data", keys)

    return Object.keys(data)
        .filter((i) => keys.includes(i))
        .reduce((acc, key) => {
            // console.log("data[key]", data[key])
            acc[key] = data[key];
            return acc;
        }, {});
};

export const groupByAgreement = async (ids = null) => {
    let queryParam = "?limit=-1&groupBy[]=aggrement&aggregate[sum]=price_total,Interim_rent_cost&filter[aggrement][_nnull]=true"
    if (ids) {
        queryParam = `?limit=-1&groupBy[]=aggrement&aggregate[sum]=price_total,Interim_rent_cost&filter[aggrement.id][_in]=${ids}`
    }
    // &filter[aggrement.id][_eq]=
    getassetsList(queryParam)
        .then(({ data: { data } }) => {
            if (data.length > 0) {
                data.map(async (item) => {
                    let agreementVal = await directUSAPICall().items('aggrement').readOne(item.aggrement, { fields: ['add_purchase_value,EOL_value_amt,no_of_month_lease,EOL_percentage,commision_to_partner_percentage,sum_purchase_value,Interim_income,montly_payment_rent_or_lease,montly_payment_ratio'] })
                    // console.log("aggg", agreementVal)
                    agreementVal.sum_purchase_value = item.sum.price_total || 0;
                    // // agreementVal.sum_purchase_value = parseInt(tuple.price_total);
                    agreementVal.Interim_income = item.sum.Interim_rent_cost || 0;
                    // console.log("assetInterim_rent_cost", assetInterim_rent_cost)

                    let montlypaymentvalue = 0
                    if ((agreementVal && agreementVal.sum_purchase_value && agreementVal.montly_payment_ratio)) {
                        let item = agreementVal
                        // console.log("agreementVal.sum_purchase_value", agreementVal.sum_purchase_value)
                        // console.log("agreementVal.montly_payment_ratio", agreementVal.montly_payment_ratio)
                        montlypaymentvalue = (parseFloat(item.sum_purchase_value) * parseFloat(item.montly_payment_ratio)) / 100;
                        agreementVal.montly_payment_rent_or_lease = montlypaymentvalue.toFixed(2);
                    } else {
                        agreementVal.montly_payment_rent_or_lease = 0;
                    }
                    let commission_amount = 0;
                    if ((agreementVal && agreementVal.sum_purchase_value && agreementVal.commision_to_partner_percentage)) {
                        let item = agreementVal
                        commission_amount = (parseFloat(item.sum_purchase_value) * parseFloat(item.commision_to_partner_percentage)) / 100;
                        agreementVal.commision_partner_amount = (commission_amount).toFixed(2)
                    } else {
                        agreementVal.commision_partner_amount = commission_amount
                    }
                    let EOLvalueamt = 0;
                    if ((agreementVal && agreementVal.sum_purchase_value && agreementVal.EOL_percentage)) {
                        let item = agreementVal

                        EOLvalueamt = (parseFloat(item.sum_purchase_value) * parseFloat(item.EOL_percentage)) / 100;
                        agreementVal.EOL_value_amt = (EOLvalueamt).toFixed(2)
                    } else {
                        agreementVal.EOL_value_amt = 0
                    }
                    let add_purchase_value = 0
                    if ((agreementVal.sum_purchase_value && agreementVal.montly_payment_ratio && agreementVal.no_of_month_lease && agreementVal.EOL_value_amt)) {
                        // add_purchase_value = ((parseFloat(agreementVal.sum_purchase_value) / 100) * parseFloat(agreementVal.pre_sold)) + parseFloat(agreementVal.sum_purchase_value);
                        // setAddPurchaseValue(add_to_purchase_value)
                        add_purchase_value = ((parseFloat(agreementVal.sum_purchase_value)) * (parseFloat(agreementVal.montly_payment_ratio) / 100)) * parseFloat(agreementVal.no_of_month_lease) + parseInt(agreementVal.EOL_value_amt);

                        agreementVal.add_to_purchase_value = (add_purchase_value).toFixed(2)
                    } else {
                        agreementVal.add_to_purchase_value = 0;
                    }
                    return await directUSAPICall().items('aggrement').updateOne(item.aggrement, agreementVal);
                    // console.log("updateAgreementSum*****", updateAgreementSum)
                    // groupdata.push({
                    //   aggrement: item.aggrement,

                    // })
                })
            } else {
                if (ids) {
                    ids = ids.split(",")
                    ids.map(async (id) => {
                        // console.log("itemmmm", item)
                        let agreementVal = await directUSAPICall().items('aggrement').readOne(id, { fields: ['add_purchase_value,EOL_value_amt,no_of_month_lease,EOL_percentage,commision_to_partner_percentage,sum_purchase_value,Interim_income,montly_payment_rent_or_lease,montly_payment_ratio'] })
                        agreementVal.montly_payment_rent_or_lease = 0;
                        agreementVal.EOL_value_amt = 0
                        agreementVal.add_to_purchase_value = 0;
                        agreementVal.sum_purchase_value = 0;
                        agreementVal.Interim_income = 0;
                        return await directUSAPICall().items('aggrement').updateOne(id, agreementVal);
                        // console.log("updateAgreementSum*****", updateAgreementSum)
                        // groupdata.push({
                        //   aggrement: item.aggrement,

                        // })
                    })
                }

            }

            //  setGroupByAgreementData(data)
            // setLoading(false)
            // setAgreementList(data)
            // setIsloading(false)
            //   dispatch(auth.actions.login(accessToken))
        })
        .catch(() => {
            // setLoading(false)
            //   setStatus('The login detail is incorrect')
        })
}