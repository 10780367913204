
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import moment from "moment";
import Check from '@material-ui/icons/Check';
import { PageTitle } from '../../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import { Create, UpdateAgreement, agreementData } from '../redux/AgreementCrud';
import { getSupplierList } from '../../supplier/redux/SupplierCrud'
import { AssetTypesList } from '../../asset_type/redux/AssetTypeCrud'
import { ClientList, ClientAddessList } from '../../client/redux/ClientCrud'
import _ from "lodash";
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { cleanEmptyObj, removeSpaceCommaformatter, SVformatter } from '../../../../_metronic/helpers/components/utils'
import CustomCancelButton from "../../../../_metronic/helpers/uikit/CancelButton";
import CircularProgress from '@mui/material/CircularProgress'
import { AGREEMENTFIELDS } from "../../../../_metronic/helpers/components/constant"
import { directUSAPICall, groupByAgreement } from "../../../../_metronic/helpers/components/utils"
import PageLoader from "../../../../_metronic/helpers/components/PageLoader";
import { useSelector } from "react-redux";


const useStyles = makeStyles(theme => ({
  labelContainer: {
    "& $alternativeLabel": {
      fontSize: '20px'
    }
  },
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Agreement Info', 'Financial Info', 'Documents'];
}

export default function CreateForm(props) {
  const { selectedAGId, page } = props;
  const classes = useStyles();
  const intl = useIntl()
  const history = useHistory()
  const urlParams = useParams()
  const [loading, setLoading] = useState(false)
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set());
  const [skipped, setSkipped] = React.useState(new Set());
  const [partnersValues, setPartnersValues] = React.useState([]);
  const [suppliersValues, setSuppliersValues] = React.useState([]);
  const [clientsValues, setClientsValues] = React.useState([]);
  const [assetTypesValues, setAssetTypesValues] = React.useState([]);
  const [uploadedFiles, setUploadedFils] = useState([])
  const { data } = useSelector(({ auth }) => auth.user);
  const [newUploadedFils, setNewUploadedFils] = useState([])
  const [pageLoading, setPageLoading] = useState(true);
  const { pathname } = useLocation();
  const [userInfo, setUserInfo] = useState({});
  const [values, setValues] = React.useState({
    id: 0,
    status: 'published',
    heading: '',
    founding_partner: '',
    agrement_info_no: '',
    type_of_leasing: '',
    typeofaggrements: '',
    lease_start_date: null,
    partner: '',
    client: '',
    no_of_month_lease: '',
    billing_period: '',
    ag_status: '',
    sum_purchase_value_budget: '',
    sum_purchase_value: '',
    invoicing_to: '',
    difftobudjet: '',
    original_sign_date: null,
    founding: '',
    type_of_product: '',
    client_interest: '',
    montly_payment_ratio: '',
    montly_payment_rent_or_lease: '',
    project: '',
    salesperson: '',
    notice_period_before_EOL: '',
    EOL_percentage: '',
    latest_notice_day: '',
    EOL_value_amt: '',
    notice_before_notice_month: '',
    clients_attn_notice: '',
    notice_before_notice_day: '',
    notice: '',
    Interim_income: '',
    clients_attn: null,
    newUploadedFils: [],
    files: []
  });
  const steps = getSteps();
  const [isDisable, setIsDisable] = useState(false);
  const [loggedUserRole, setLoggedUserRole] = useState();
  const [hideColumns, setHideColumns] = useState(false);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Step1
          setValues={setValues}
          values={values}
          isDisable={isDisable}
          userInfo={userInfo}
          partnersValues={partnersValues}
          clientsValues={clientsValues}
          suppliersValues={suppliersValues}
          assetTypesValues={assetTypesValues}
          hideColumns={hideColumns}
        />;
      case 1:
        return <Step2
          isDisable={isDisable}
          setValues={setValues}
          values={values}
          hideColumns={hideColumns}
        />;
      case 2:
        return <Step3
          isDisable={isDisable}
          values={values}
          setUploadedFils={setUploadedFils}
          setNewUploadedFils={setNewUploadedFils}
        />;
      // case 3:
      //   return <Step4 />;
      // case 4:
      //   return <Step5 />;
      default:
        return 'Unknown step';
    }
  }

  function totalSteps() {
    return getSteps().length;
  }

  function isStepOptional(step) {
    return step === 1;
  }

  function skippedSteps() {
    return skipped.size;
  }

  function completedSteps() {
    return completed.size;
  }

  function allStepsCompleted() {
    return completedSteps() === totalSteps() - skippedSteps();
  }

  function isLastStep() {
    return activeStep === totalSteps() - 1;
  }

  function handleNext() {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed
        // find the first step that has been completed
        steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  const handleStep = step => () => {
    setActiveStep(step);
  };

  function handleReset() {
    setActiveStep(0);
    setCompleted(new Set());
    setSkipped(new Set());
  }

  function isStepSkipped(step) {
    return skipped.has(step);
  }

  function isStepComplete(step) {
    return completed.has(step);
  }

  const handleSubmit = (type) => {
    values.newUploadedFils = newUploadedFils
    if (values.agrement_info_no && values.founding_partner && values.type_of_leasing && values.typeofaggrements && values.lease_start_date && values.no_of_month_lease && values.client && values.partner) {
      setLoading(true)
      delete values.assets;
      delete values.user_updated;
      delete values.user_created;
      delete values.date_created;
      delete values.date_updated;
      if (values.sum_purchase_value) {
        values.sum_purchase_value = removeSpaceCommaformatter(values.sum_purchase_value)
      }
      if (values.sum_purchase_value_budget) {
        values.sum_purchase_value_budget = removeSpaceCommaformatter(values.sum_purchase_value_budget)
      }
      if (values.montly_payment_rent_or_lease) {
        values.montly_payment_rent_or_lease = removeSpaceCommaformatter(values.montly_payment_rent_or_lease)
      }
      if (values.EOL_value_amt) {
        values.EOL_value_amt = removeSpaceCommaformatter(values.EOL_value_amt)
      }
      if (values.Interim_income) {
        values.Interim_income = removeSpaceCommaformatter(values.Interim_income)
      }
      if (values.difftobudjet) {
        values.difftobudjet = removeSpaceCommaformatter(values.difftobudjet)
      }
      // console.log("requestttt", values.sum_purchase_value)
      // return
      if (!values.id || values.id === 0 && cleanEmptyObj(values)) {
        values.newUploadedFils = newUploadedFils

        // return
        Create(cleanEmptyObj(values))
          .then(({ data: { data } }) => {
            // console.log("cleanEmptyObj(values)", cleanEmptyObj(values))
            // if(type === 'saveexit') {
            setLoading(false)
            groupByAgreement(data.id)

            // }
            if (type === 'savenext') {
              // history.push(`/createagreement/${data.id}`)
              values.id = data.id
              setValues({ ...values });

              handleNext()
            }
            if (type === 'saveexit') {
              values.id = data.id
              setValues({ ...values });
              history.push('/agreements')
            }
            // setAgreementList(data)
            // setIsloading(false)
            //   dispatch(auth.actions.login(accessToken))
          })
          .catch(() => {
            setLoading(true)
            //   setStatus('The login detail is incorrect')
          })
      }
      if (values.id) {

        if (newUploadedFils && newUploadedFils.length > 0) {
          values.files = values.files.concat(newUploadedFils)
        }
        UpdateAgreement(cleanEmptyObj(values), values.id)
          .then(({ data: { data } }) => {
            // console.log("dataaa", data)
            // if(type === 'saveexit') {
            setLoading(false)
            groupByAgreement(data.id)
            // }
            if (type === 'savenext') {
              values.id = data.id
              setValues({ ...values });
              handleNext()
            }
            if (type === 'saveexit') {
              values.id = data.id
              setValues({ ...values });
              history.push('/agreements')
            }
            // setAgreementList(data)
            // setIsloading(false)
            //   dispatch(auth.actions.login(accessToken))
          })
          .catch(() => {
            setLoading(true)
            //   setStatus('The login detail is incorrect')
          })
      }

    }

  }

  useEffect(() => {
    if (data && data?.id) {
      setUserInfo(data)
      setLoggedUserRole(data.role.name)

      if (data.role) {
        if (data.role.name === 'client_admin' || data.role.name === 'client_user') {

          const path_name = pathname.split("/")
          if (path_name[1] === 'createagreement') {
            getClientPartnerList(data.client.id)
          } else {
            getClientList(data?.partner?.id, data?.client?.id)

          }

          values.client = data.client.id;
          setHideColumns(true)
          suppliersData()

          return;
        }
        if (data.role.name === 'partner_admin' || data.role.name === 'partner_user') {
          values.partner = data.partner.id;
        }
        getClientList(data?.partner?.id, data?.client?.id)

        suppliersData()
      }
    }
  }, [data])

  useEffect(() => {
    // setIsloading(true)
    // partnersData()
    if (urlParams && urlParams.id) {
      getAgreementData(urlParams.id)
    }

  }, [urlParams])
  useEffect(() => {
    // setIsloading(true)
    // partnersData()
    if (selectedAGId) {
      getAgreementData(selectedAGId)
    }

  }, [selectedAGId])

  useEffect(() => {
    // setIsloading(true)
    // partnersData()
    if (values.partner) {
      if (userInfo?.role?.name) {
        if (userInfo.role.name === 'client_admin' || userInfo.role.name === 'client_user') {
          values.client = userInfo.client.id;
        } else {
          values.client = null;
        }
        getClientList(values.partner, values.client)

      }
    }
  }, [values.partner])

  useEffect(() => {
    if (page) {
      if (page === 'viewagreements') {
        setIsDisable(true)
      } else {
        setIsDisable(false)
      }
    }
  }, [page])

  const getAgreementData = async (id) => {
    setLoading(true)
    setPageLoading(true)
    // let users = await directUSAPICall().users.me.read();
    // console.log("users", users)
    let fields = `${AGREEMENTFIELDS},files,files.id,files.directus_files_id,files.directus_files_id.id,files.directus_files_id.filename_download,files.directus_files_id.title`
    const result = await directUSAPICall().items('aggrement').readOne(id, { fields: [fields] });
    if (result && result.id) {
      let agData = result
      if (result.partner) {
        agData.partner = result.partner.id
      }
      if (result.client) {
        agData.client = result.client.id
      }
      if (result.founding_partner) {
        agData.founding_partner = result.founding_partner.id
      }
      if (result.clients_attn) {
        agData.clients_attn = result.clients_attn.id
      }
      if (result.sum_purchase_value) {
        result.sum_purchase_value = SVformatter(result.sum_purchase_value)
      }
      if (result.sum_purchase_value_budget) {
        result.sum_purchase_value_budget = SVformatter(result.sum_purchase_value_budget)
      }
      if (result.montly_payment_rent_or_lease) {
        result.montly_payment_rent_or_lease = SVformatter(result.montly_payment_rent_or_lease)
      }
      if (result.EOL_value_amt) {
        result.EOL_value_amt = SVformatter(result.EOL_value_amt)
      }
      if (result.Interim_income) {
        result.Interim_income = SVformatter(result.Interim_income)
      }
      if (result.difftobudjet) {
        result.difftobudjet = SVformatter(result.difftobudjet)
      }
      agData.lease_start_date = moment(result.lease_start_date).format('YYYY-MM-DD')
      agData.original_sign_date = result.original_sign_date ? moment(result.original_sign_date).format('YYYY-MM-DD') : null
      agData.eol_date = result.eol_date ? moment(result.eol_date).format('YYYY-MM-DD') : null
      agData.notice_before_notice_day = result.notice_before_notice_day ? moment(result.notice_before_notice_day).format('YYYY-MM-DD') : null
      agData.latest_notice_day = result.latest_notice_day ? moment(result.latest_notice_day).format('YYYY-MM-DD') : null
      setValues(agData)
      setLoading(false)
      setPageLoading(false)
    } else {
      setValues({})
      setLoading(false)
      setPageLoading(false)
    }
  }

  const suppliersData = () => {
    let queryParam = "?sort=supplier_name&filter[supplier_org_no][_nnull]=true&fields=*.*.*."
    getSupplierList(queryParam)
      .then(({ data: { data } }) => {
        setSuppliersValues(data)
        AssetTypesData()
      })
      .catch(() => {
        AssetTypesData()
      })
  }

  const AssetTypesData = () => {
    AssetTypesList()
      .then(({ data: { data } }) => {
        let assetNames = data.map(
          (assetType) => assetType.asset_name.toUpperCase()
        );

        setAssetTypesValues(assetNames)
        // console.log("dataaa", data)
        // setAgreementList(data)
        // setIsloading(false)
        //   dispatch(auth.actions.login(accessToken))
      })
      .catch(() => {
        // setIsloading(false)
        //   setStatus('The login detail is incorrect')
      })
  }
  const getClientPartnerList = async (client) => {
    let fields = 'client.name,client.id,client.clients_org_no,partner.company_org_no,partner.partner_name,partner.id'

    let options = {
      limit: -1,
      fields: [fields]
    }
    options.filter = {
      "user_created": {
        "client": {
          _eq: client
        }
      }
    }

    const result = await directUSAPICall().items('client_address').readMany(options);
    if (result.data && result.data.length > 0) {
      setPageLoading(false)
      let partners = result.data.map(
        (item) => item.partner && item.partner
      );
      partners = partners.filter(function (e) {return e != null;});
      partners = _.orderBy(partners, ['partner_name'], ['asc']);
      // console.log("partners", partners)
      setPartnersValues(_.uniqBy(partners, 'id'))

    } else {
      setPageLoading(false)

      setPartnersValues([])
    }
  }

  // console.log("valuessss", values)
  const getClientList = (partnerId = null, client = null) => {
    let queryFields = 'client.name,client.id,client.clients_org_no,partner.company_org_no,partner.partner_name,partner.id'
    let filter = ''
    if (partnerId) {
      filter = `?[partner][_nnull]=true&filter[partner][_eq]=${partnerId}&fields=`
    }
    if (client) {
      filter = `?[client][_nnull]=true&filter[client][_eq]=${client}&fields=`
    }

    // console.log("clienttss", filter)
    ClientAddessList(queryFields, filter)
      .then(({ data: { data } }) => {
        setPageLoading(false)

        if (data && data.length > 0) {
          if (!partnerId) {
            let partners = data.map(
              (item) => item.partner && item.partner
            );
            partners = _.orderBy(partners, ['partner_name'], ['asc']);
            partners = partners.filter(function (e) {return e != null;});
            setPartnersValues(_.uniqBy(partners, 'id'))

          }
          if (partnerId || client) {
            // let partners = data.map(
            //   (item) => item.partner && item.partner
            // );
            // partners = _.orderBy(partners, ['partner_name'], ['asc']);
            // setPartnersValues(_.uniqBy(partners, 'id'))
            let clients = data.map(
              (item) => item.client
            );
            clients = _.sortBy(clients, function (o) { return o.name; })
            // clients = _.orderBy(clients, ['name'], ['asc']);
            // values.client = '';
            // setValues({ ...values })
            setClientsValues(_.uniqBy(clients, 'id'))
          }
        }
        // setPartnersValues(data)
        // console.log("dataaa", data)
        // setAgreementList(data)
        // setIsloading(false)
        //   dispatch(auth.actions.login(accessToken))
      })
      .catch((err) => {
        setPageLoading(false)
        throw err;
        // setIsloading(false)
        //   setStatus('The login detail is incorrect')
      })
  }

  // console.log("valuesss", values)

  return (
    <>
      {
        !isDisable ?
          <> <PageLoader pageLoaderOpen={pageLoading} setPageLoaderOpen={setPageLoading} />
            <PageTitle breadcrumbs={[
              {
                title: 'Agreement List',
                path: '/agreements',
                isSeparator: false,
                isActive: false,
              }
            ]}>{'Create Agreement'}</PageTitle>
            <div id="kt_subheader" className="subheader py-2 py-lg-4 subheader-solid">
            </div>
          </>
          : null
      }

      <div className="card card-custom gutter-b">
        <div className='card-body'>
          <div className={classes.root}>
            <Stepper alternativeLabel nonLinear activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const buttonProps = {};
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepButton
                      icon={<Check className={[classes.xiconRoot, index === activeStep ? 'activestep' : ''].join(' ')} />}
                      onClick={handleStep(index)}
                      completed={isStepComplete(index)}
                      {...buttonProps}
                    >
                      <StepLabel
                        classes={{
                          iconContainer: classes.iconContainer
                        }}
                      >
                        {label}
                      </StepLabel>
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>
            <div>
              {allStepsCompleted() ? (
                <div>
                  <Typography className={classes.instructions}>
                    All steps completed - you&apos;re finished
            </Typography>
                  <Button onClick={handleReset}>Reset</Button>
                </div>
              ) : (
                <div>
                  <form
                    autoComplete="off"
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    id='kt_login_signup_form'
                  // onSubmit={handleSubmit}
                  >
                    {getStepContent(activeStep)}
                  </form>

                  <div className="stepper-btn">
                    {
                      !isDisable ?
                        <CustomCancelButton
                          variant="secondary"
                          color="danger"
                          to='agreements'
                        /> : null}

                    <Button variant="contained"
                      color="warning" disabled={activeStep === 0} onClick={handleBack} className={[classes.button, 'backbtn']}>
                      Back
              </Button>
                    {
                      !isDisable ? <>
                        <Button
                          onClick={() => handleSubmit('savenext')}
                          variant="contained"
                          color="primary"
                          disabled={loading}
                          className={classes.button}

                        >
                          <span className='indicator-label'>{'Save & Next'}</span>
                          {
                            loading && <CircularProgress />
                          }

                        </Button>

                        <Button
                          onClick={() => handleSubmit('saveexit')}
                          variant="contained"
                          color="primary"
                          disabled={loading}
                          className={classes.button}

                        >
                          <span className='indicator-label'>{'Save & Exit'}</span>
                          {
                            loading && <CircularProgress />
                          }

                        </Button></> : <Button
                          onClick={() => handleNext()}
                          variant="contained"
                          color="primary"
                          disabled={loading}
                          className={classes.button}

                        >
                        <span className='indicator-label'>{'Next'}</span>
                      </Button>
                    }
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
