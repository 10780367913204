/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { getMonthDiff, directUSAPICall, SVformatter } from '../../../../_metronic/helpers/components/utils'
import { getAgreementStatus } from '../../../../_metronic/helpers/components/calculation'
import Autocomplete from '@mui/material/Autocomplete';
import moment from "moment";

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '20px'
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));

export default function Step1({
  setValues,
  values,
  userInfo,
  partnersValues,
  clientsValues,
  suppliersValues,
  assetTypesValues,
  isDisable,
  hideColumns
}) {
  const classes = useStyles();
  const [usersList, setUsersList] = useState([]);
  const status = ["PREPARE", "INTERIM", "ONGOING", "EXTENDED", "ENDING", "CLOSED"]
  console.log("values.lease_start_date", values.lease_start_date)
  useEffect(() => {
    if (values.lease_start_date && values.no_of_month_lease) {
      values.eol_date = (getAgreementStatus(values).eoldate) || null;
      let monthDiff = getMonthDiff(values.eol_date);

      values.ag_status = (getAgreementStatus(values, monthDiff).status) || "";
      // console.log("valesss", getAgreementStatus(values))
      let color = ''
      if (values.ag_status === 'ONGOING') {
        if (monthDiff >= 3 && monthDiff <= 6) {
          color = 'blue'
        } else if (monthDiff <= 3) {
          color = 'red'
        }
      }

      delete values.status
      setValues({ ...values })
    }
  }, [values.lease_start_date, values.no_of_month_lease])

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
    // if(name === 'sum_purchase_value_budget') {

    // }
  };

  const getClientUsersList = async (id = null) => {
    let options = {
      sort: 'first_name',
      fields: ['id, email'],
      filter: {
        _and: [
          {
            "userType": {
              _eq: 'CLIENT'
            }
          },
          {
            "client": {
              _eq: values.client || id
            }
          }
        ]
      }
    }

    const result = await directUSAPICall().items('directus_users').readMany(options);
    if (result.data && result.data.length > 0) {
      setUsersList(result.data)
    }
  }

  const checkClient = () => {
    if (userInfo && userInfo.role) {
      let disable = false;
      if (userInfo.role.name === 'client_admin' || userInfo.role.name === 'client_user') {
        disable = true
      }
      return disable
    }

  }
  const checkPartner = () => {
    if (userInfo && userInfo.role) {
      let disable = false;
      if (userInfo.role.name === 'partner_admin' || userInfo.role.name === 'partner_user') {
        disable = true
      }
      return disable
    }
  }

  useEffect(() => {
    if (values.client) {
      getClientUsersList(values.client)
    }
  }, [values.client])

  return (
    <div className="card card-custom gutter-b">
      <div className='card-body box-shadow'>
        <div className="form-group row">
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              label="Title"
              id="Title"
              name={'heading'}
              className={classes.textField}
              value={values.heading}
              onChange={handleChange('heading')}
              margin="normal"
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}

            // helperText="error"
            // error={true}
            />
          </div>

          <div className='col-lg-6'>
            <Autocomplete
              value={values.founding_partner ? suppliersValues.find(v => v.id === values.founding_partner) || {} : null}
              name={'founding_partner'}
              loading={values.founding_partner}
              id={'founding_partner'}
              // defaultValue={suppliersValues.find(v => v.id === )}
              getOptionLabel={(option) => option && (option.supplier_name + " - " + option.supplier_org_no)}
              options={suppliersValues}
              onChange={(e, option) => {
                if (option) {
                  setValues({ ...values, founding_partner: option.id })
                }
              }}
              disabled={isDisable ? isDisable : false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  error={!values.founding_partner ? true : false}
                  label="Founding partner"
                  margin="normal"
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  variant={isDisable ? "filled" : "standard"}
                />
              )}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              required
              error={!values.agrement_info_no ? true : false}
              label="Agreement info no"
              id="Agreement info no"
              className={classes.textField}
              name={'agrement_info_no'}
              value={values.agrement_info_no}
              onChange={handleChange('agrement_info_no')}
              margin="normal"
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
            />
          </div>
          <div className='col-lg-6'>

            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              required
              id="type_of_leasing"
              select
              error={!values.type_of_leasing ? true : false}
              className={classes.textField}
              value={values.type_of_leasing}
              name="type_of_leasing"
              onChange={handleChange('type_of_leasing')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              label="Type of Lease"
              margin="normal"
            >
              <MenuItem key={'rent'} value="Rent">Rent</MenuItem>
              <MenuItem key={'finance'} value="Financiel Leasing">Financiel Leasing</MenuItem>
              <MenuItem key={'operational'} value="Operational Leasing">Operational Leasing</MenuItem>
            </TextField>
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              required
              id="typeofagreements"
              select
              error={!values.typeofaggrements ? true : false}
              className={classes.textField}
              value={values.typeofaggrements}
              name="typeofaggrements"
              onChange={handleChange('typeofaggrements')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              label="Type of Agreements"
              margin="normal"
            >
              <MenuItem key={'main'} value={'Main'}>Main</MenuItem>
              <MenuItem key={'main'} value={'sub'}>Sub</MenuItem>
            </TextField>
          </div>
          <div className='col-lg-6'>
            {/* <TextField
              date
              id="date"
              label="Lease Start Date"
              name={'lease_start_date'}
              className={classes.textField}
              value={values.lease_start_date}
              margin="normal"
              InputProps={{
                disabled: isDisable
              }}
              type="date"
              variant={isDisable ? "filled" : "standard"}
              InputLabelProps={{
                shrink: true,
                disabled: true
              }}
              onChange={handleChange('lease_start_date')}
            /> */}
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              required
              id="date"
              label="Lease Start Date"
              type={isDisable ? "text" : "date"}
              error={!values.lease_start_date ? true : false}
              name={'lease_start_date'}
              margin="normal"
              onChange={handleChange('lease_start_date')}
              value={values.lease_start_date}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          {
            !checkPartner() &&
            <div className='col-lg-6'>
              <Autocomplete
                value={values.partner ? (partnersValues.length > 0 ? partnersValues.find(v => v.id === values.partner) : []) || {} : null}
                name={'partner'}
                loading={values.partner}
                id={'partner'}
                disabled={checkPartner()}
                getOptionLabel={(option) => option && (option.partner_name + " - " + option.company_org_no)}
                options={partnersValues}
                onChange={(e, option) => {
                  if (option) {
                    setValues({ ...values, partner: option.id, clients_attn: [] })
                  }
                }}
                disabled={isDisable ? isDisable : false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    error={!values.partner ? true : false}
                    label="Partner"
                    margin="normal"
                    variant={isDisable ? "filled" : "standard"}

                  />
                )}
              />
            </div>
          }
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              required
              label="No of Month Lease"
              id="No of Month Lease"
              className={classes.textField}
              error={!values.no_of_month_lease ? true : false}
              name={'no_of_month_lease'}
              value={values.no_of_month_lease}
              onChange={handleChange('no_of_month_lease')}
              margin="normal"
            />
          </div>
          <div className='col-lg-6'>
            <Autocomplete
              value={values.client ? clientsValues.find(v => v.id === values.client) : null}
              name={'client'}
              loading={values.client}
              id={'client'}
              getOptionLabel={(option) => option && (option.name + " - " + option.clients_org_no)}
              options={clientsValues}
              // disabled={checkClient()}
              onChange={(e, option) => {
                if (option) {
                  setValues({ ...values, client: option.id, clients_attn: [] })
                  // getClientUsersList(option.id)
                }
              }}
              disabled={checkClient() || (isDisable || checkClient() ? isDisable : false)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  error={!values.client ? true : false}
                  label="Client"
                  margin="normal"
                  variant={isDisable ? "filled" : "standard"}

                />
              )}
            />
          </div>

          <div className='col-lg-6'>
            <TextField
              date
              label="EOL Date"
              id="EOL Date"
              name={'eol_date'}
              className={classes.textField}
              value={values.eol_date}
              margin="normal"
              InputProps={{
                disabled: true
              }}
              variant="filled"
              InputLabelProps={{
                shrink: true,
                disabled: true
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              required
              label="Billing period"
              id="Billing period"
              error={!values.billing_period ? true : false}
              name={'billing_period'}
              className={classes.textField}
              value={values.billing_period}
              onChange={handleChange('billing_period')}
              margin="normal"
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              id="Sum Purchase Value Budget"
              label="Sum Purchase Value Budget"
              className={classes.textField}
              name={'sum_purchase_value_budget'}
              value={(values.sum_purchase_value_budget)}
              onChange={handleChange('sum_purchase_value_budget')}
              margin="normal"
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              label="Sum Purchase Value"
              id="Sum Purchase Value"
              className={classes.textField}
              name={'sum_purchase_value'}
              value={values.sum_purchase_value}
              onChange={handleChange('sum_purchase_value')}
              margin="normal"
              variant="filled"
              InputProps={{
                disabled: true
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              required
              error={!values.invoicing_to ? true : false}
              label="Invoicing to"
              id="invoicing_to"
              name={'invoicing_to'}
              select
              error={!values.invoicing_to ? true : false}
              className={classes.textField}
              value={values.invoicing_to}
              onChange={handleChange('invoicing_to')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
            >
              <MenuItem key={'main'} value={'client'}>Client</MenuItem>
              <MenuItem key={'main'} value={'cc'}>CC</MenuItem>
            </TextField>
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              required
              id="ag status"
              select
              error={!values.ag_status ? true : false}
              className={classes.textField}
              value={values.ag_status}
              name={'ag_status'}
              onChange={handleChange('ag_status')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              label="Status"
              margin="normal"
            >
              {status.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className='col-lg-6'>
            <TextField
              label="Diff to budget"
              name={'difftobudjet'}
              id={'difftobudjet'}
              className={classes.textField}
              value={values.difftobudjet}
              onChange={handleChange('difftobudjet')}
              margin="normal"
              InputProps={{
                disabled: true
              }}
              variant="filled"
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              label="Original Sign Date"
              id="Original Sign Date"
              name={'original_sign_date'}
              className={classes.textField}
              value={values.original_sign_date}
              type="date"
              margin="normal"
              format="YYYY-MM-DD"
              onChange={handleChange('original_sign_date')}
              // formatDate={() => moment().format('YYYY-MM-DD')}
              // defaultValue="2017-05-24"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              label="Founding"
              id="Founding"
              name="founding"
              className={classes.textField}
              value={values.founding}
              onChange={handleChange('founding')}
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <i className="fas fa-percent" />
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              label="Type of Assets"
              name={'type_of_product'}
              id={'type_of_product'}
              select
              className={classes.textField}
              value={values.type_of_product}
              onChange={handleChange('type_of_product')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              label="Asset Types"
              margin="normal"
            >
              {assetTypesValues.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              label="Client interest"
              id="Client interest"
              name="client_interest"
              className={classes.textField}
              value={values.client_interest}
              onChange={handleChange('client_interest')}
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <i className="fas fa-percent" />
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div className='col-lg-6'>
            <Autocomplete
              value={values.clients_attn ? usersList && usersList.find(v => v.id === values.clients_attn) : null}
              name={'clients_attn'}
              loading={values.clients_attn}
              id={'clients_attn'}
              getOptionLabel={(option) => option && option.email}
              options={usersList || []}
              onChange={(e, option) => {
                if (option) {
                  setValues({ ...values, clients_attn: option.id })
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Client Attn"
                  margin="normal"
                  variant="standard"
                  InputProps={{
                    disabled: isDisable
                  }}
                  variant={isDisable ? "filled" : "standard"}

                />
              )}
            />
          </div>
          {
            !hideColumns && <div className='col-lg-6'>
              <TextField
                InputProps={{
                  disabled: isDisable
                }}
                variant={isDisable ? "filled" : "standard"}

                label="Tariff"
                id="Tariff"
                name={'montly_payment_ratio'}
                className={classes.textField}
                value={values.montly_payment_ratio}
                onChange={handleChange('montly_payment_ratio')}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <i className="fas fa-percent" />
                    </InputAdornment>
                  )
                }}
              />
            </div>
          }

          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              label="Project"
              id="Project"
              name={'project'}
              className={classes.textField}
              value={values.project}
              onChange={handleChange('project')}
              margin="normal"
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              label="Montly payment rent or lease"
              id="Montly payment rent or lease"
              name={'montly_payment_rent_or_lease'}
              className={classes.textField}
              value={values.montly_payment_rent_or_lease}
              onChange={handleChange('montly_payment_rent_or_lease')}
              margin="normal"
              variant="filled"
              InputProps={{
                disabled: true
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}

              label="Sales person"
              name={'salesperson'}
              id={'salesperson'}
              className={classes.textField}
              value={values.salesperson}
              onChange={handleChange('salesperson')}
              margin="normal"
            />
          </div>
        </div>
        {/* </form> */}
      </div>
    </div>
  )
}
