/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import * as auth from '../redux/AuthRedux'
import { login } from '../redux/AuthCRUD'
import { Directus } from '@directus/sdk';
import { API_URL } from '../../../../_metronic/helpers/components/constant';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import { useLocation } from "react-router";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}


/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [userType, setUserType] = useState('PARTNER')
  const dispatch = useDispatch()
  const directus = new Directus(API_URL);
  const location = useLocation();

  useEffect(() => {
    if(location && location.pathname === '/auth/login') {
      setUserType('SUPERADMIN');
    }

  }, [location])
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      // setTimeout(async () => {
        const response = await directus.auth.login({
          email: values.email,
          password: values.password
        });
        if(response.access_token) {
          setLoading(false)
          localStorage.setItem("userType", userType)
          dispatch(auth.actions.login(response.access_token))
        } else {
          setLoading(false)
          localStorage.setItem("userType", '')
          setStatus('The login detail is incorrect')
        }
        // login(values.email, values.password)
        //   .then(({ data: { data } }) => {
        //     setLoading(false)
        //     directus.auth.login({
        //       email: values.email,
        //       password: values.password
        //     });
        //     localStorage.setItem("userType", userType)
        //     dispatch(auth.actions.login(data.access_token))
        //   })
        //   .catch(() => {
        //     setLoading(false)
        //     setSubmitting(false)
        //     setStatus('The login detail is incorrect')
        //   })
      // }, 1000)
    },
  })

  return (
    <>
      {/* <PageLoader pageLoaderOpen={loading} setPageLoaderOpen={setLoading} /> */}
      <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white">
        <div className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10" style={{ backgroundImage: `url("https://preview.keenthemes.com/metronic/react/demo1/media/bg/bg-4.jpg")` }}>
          <div className="d-flex flex-row-fluid flex-column justify-content-between">
            <a className="flex-column-auto mt-5 pb-lg-0 pb-10" href="#">
              <img alt="Logo" className="max-h-70px" src="https://asset.techkonsult.se/assets/images/logo-white.png" />
            </a>
            <div className="flex-column-fluid d-flex flex-column justify-content-center">
              <h3 className="font-size-h1 mb-5 text-white">Asset Management System</h3>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
          <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
            <div className="login-form login-signin" id="kt_login_signin_form">
              {/* begin::Heading */}
              <div className='text-center mb-10'>
                <h1 className='text-dark mb-3'>Sign In to AMS</h1>
              </div>
              {/* begin::Heading */}
              {
                location && location.pathname === '/login' ?
                  <div className="row gy-5 g-xl-12 login-screen">
                    <div className="col-xl-3">
                      <h3 className='text-dark' style={{ marginTop: '10px' }}>LOGIN AS</h3>
                    </div>
                    <div className="col-xl-9">
                      <FormControl component="fieldset">
                        <RadioGroup row aria-label="usertype" defaultValue={userType} name="row-radio-buttons-group">
                          <FormControlLabel value="PARTNER" defaultChecked={userType === 'PARTNER' ? true : false} control={
                            <Button onClick={() => setUserType('PARTNER')} className={userType === 'PARTNER' ? 'checked' : ''}>
                              PARTNER
                      </Button>} label="" />
                          <FormControlLabel value="CLIENT" defaultChecked={userType === 'CLIENT' ? true : false} control={
                            <Button onClick={() => setUserType('CLIENT')} className={userType === 'CLIENT' ? 'checked' : ''}>CLIENT </Button>} label="" />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div> : null
              }

              <form
                className='form w-100'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_login_signin_form'
              >
                {formik.status ? (
                  <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                  </div>
                ) : null}

                {/* begin::Form group */}
                <div className='fv-row mb-10'>
                  {/* <label className='form-label fs-6 fw-bolder text-dark'>Email</label> */}
                  <input
                    placeholder='Email'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      { 'is-invalid': formik.touched.email && formik.errors.email },
                      {
                        'is-valid': formik.touched.email && !formik.errors.email,
                      }
                    )}
                    type='email'
                    name='email'
                    autoComplete='off'
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.email}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}

                {/* begin::Form group */}
                <div className='fv-row mb-10'>
                  <div className='d-flex justify-content-between mt-n5'>
                    <div className='d-flex flex-stack mb-2'>
                    </div>
                  </div>
                  <input
                    type='password'
                    placeholder='password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.password && formik.errors.password,
                      },
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.password}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}

                {/* begin::Action */}
                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                  <a className="text-dark-50 text-hover-primary my-3 mr-2" id="kt_login_forgot" href="#"></a>
                  <button id="kt_login_signin_submit" type="submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3"><span> {!loading && <span className='indicator-label'>Sign In</span>}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}</span>
                  </button>
                </div>
                {/* <div className='text-center'>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-lg btn-primary w-100 mb-5'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!loading && <span className='indicator-label'>Continue</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div> */}
                {/* end::Action */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
