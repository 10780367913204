module.exports = {
    // API_URL: 'http://localhost:8055',
    API_URL: 'https://assetapi.inspirepro.co.in',
    FILE_UPLOAD_ROW_COUNT_ERROR: 1000,
    FILE_UPLOAD_ROW_COUNT_ERROR_MESSAGE: 'Invalid file. Exceeds 1000 rows',
    FILE_UPLOAD_AGG_ID_MISSING_MESSAGE: 'Invalid file. Column agreement (Agreement Number) is missing',
    FILE_UPLOAD_EMPTY_FILE_MESSAGE: 'Invalid file. No records available',
    AGREEMENTFIELDS: 'user_updated.id,user_created.id,user_updated.first_name,user_updated.last_name,user_created.first_name,user_created.last_name,clients_attn.first_name,clients_attn.last_name,clients_attn.email,clients_attn.id,date_updated,date_created,original_sign_date,lease_start_date,notice_before_notice_day,latest_notice_day,eol_date,ag_status,project,salesperson,exit_options,pickup_point,agrement_info_no,notice_before_notice_month,EOL_percentage,founding,billing_period,no_of_month_lease,index_connected_to_agreement,invoicing_to,type_of_depreciation,type_of_product,type_of_leasing,typeofaggrements,heading,status,client_interest,pre_sold,sum_purchase_value,montly_payment_rent_or_lease,interest_profit,sum_purchase_value_budget,EOL_value_amt,present_value,Interim_income,commision_partner_amount,montly_payment_ratio,Interim_value,add_to_purchase_value,commision_to_partner_percentage,value_to_finance,notice,clients_attn_notice,founding_partner.supplier_name,founding_partner.supplier_org_no,founding_partner.id,partner.partner_name,partner.company_org_no,id,sort,notice_period_before_EOL,sub_agrement_ref,client.name,client.clients_org_no,client.id,partner.id',
    AGGREMENTFIELDSFILTER: 'id,agrement_info_no,founding_partner.supplier_name,founding_partner.id,founding_partner.supplier_org_no,partner.partner_name,partner.company_org_no,partner.id,client.id,no_of_month_lease,invoicing_to,type_of_product,type_of_leasing,typeofaggrements,heading,billing_period,eol_date,original_sign_date,lease_start_date,ag_status,montly_payment_ratio,client.name,client.clients_org_no',
    ASSETSFIELDS1: 'user_updated.id,user_created.id,user_created.first_name,user_updated.first_name,user_created.last_name,user_updated.last_name,user_client,date_updated,date_created,insurance_until,warrenty_until,date_of_delievery_approval,harddisk,screen_size,age,description,insurance_company,IMEI,extra,pickup_point,agreement_extra_field2,project,extra_infor1,extra_infor2,exit_options,agreement_extra_field1,ref_document,user_phoneno,status,asset_type,product_group,client_own_product_group,product_description,brand,model,serial_number,processor,memory,user_country,user_invoice_address,cost_centre,cost_centre1,cost_centre2,ccmanager_phone,ccmanager_email,ccmanager_name,prod_comments,Client_ref_number,supplier_invoice_reference,asset_status,partno,agrement_info_no,user_name,user_email,user_address,employee_no,user_city,user_devision',
    ASSETSFIELDS: 'no_of_quantity,price_total,rent_period,dailyrent,rent,price,Interim_rent_cost,upcomingrents,priceper_unit,aggrement.client.name,aggrement.client.clients_org_no,Interim_no_of_days,sort,id,client,partner,founding_partner',
    ASSETAGGREMENTFIELDS: 'user_created.id,aggrement.id,aggrement.agrement_info_no,aggrement.founding_partner.supplier_name,aggrement.founding_partner.supplier_org_no,aggrement.partner.id,aggrement.client.id,aggrement.partner.partner_name,aggrement.partner.company_org_no,aggrement.no_of_month_lease,aggrement.invoicing_to,aggrement.type_of_product,aggrement.type_of_leasing,aggrement.typeofaggrements,aggrement.aggrement.heading,aggrement.billing_period,aggrement.eol_date,aggrement.original_sign_date,aggrement.lease_start_date,aggrement.ag_status,aggrement.montly_payment_ratio,client.name,client.clients_org_no',
    AGGREMENTDETAILSFIELDS: 'user_created.id,id,no_of_month_lease,agrement_info_no,founding_partner.supplier_name,founding_partner.id,founding_partner.supplier_org_no,partner.partner_name,partner.id,client.id,partner.company_org_no,invoicing_to,type_of_product,type_of_leasing,typeofaggrements,heading,billing_period,eol_date,original_sign_date,lease_start_date,ag_status,montly_payment_ratio,client.name,client.clients_org_no',
    USERSFIELDS: 'user_updated.id,user_created.id,user_created.first_name,user_updated.first_name,user_created.last_name,user_updated.last_name,user_client,date_updated,date_created,user_created.id',
    DATAURLS: {
        AUTOLOGIN: { url: `/auth/autologin` },
        AGREEMENTBULKUPDATE: { url: `/aggrements/bulkupdate` },
        AGREEMENTBULKCREATE: { url: `/aggrements/bulkcreate` },
        AGREEMENTBULKDELETE: { url: `/aggrements/bulkdelete` },
        ASSETBULKUPDATE: { url: `/assets/bulkupdate` },
        ASSETBULKCREATE: { url: `/assets/bulkcreate` },
        ASSETSTYPE: { url: `/asset-types` },
        ASSETSTYPESETTING: { url: `/assettypesettings` },
        ASSETS: { url: `/assets` },
        DASHBOARDASSETS: { url: `/dashboardassets` },
        ASSETS_BULKDELETE: { url: `/assets/bulkdelete` },
        ASSETS_COUNT: { url: `/assets/count` },
        AGREEMENTS: { url: `/aggrements` },
        AGREEMENTS_COUNT: { url: `/aggrements/count` },
        CLIENTS: { url: `/clients` },
        CLIENTS_CHECK: { url: `/clients/usercheck` },
        PARTNER: { url: `/partners` },
        SUPPLIER: { url: `/suppliers` },
        USERS: { url: `/users` },
        CLIENTADDRESS: { url: `/client-addresses` },
        CLIENTADDRESSCOUNT: { url: `/client-addresses/count` },
        AGREEMENTBULKSUMUPDATE: { url: `/aggrements/agreementSumPurchase` },
        NOTES: { url: `/notes` }

    },
    LEASETYPE: [
        { RENT: 'RENT' },
        { LEASE: 'LEASE' }
    ],
    PARTERRIGHTS: [
        { label: 'ADMIN', value: 'PARTNER_ADMIN' },
        { label: 'ASSOCIATE', value: 'PARTNER_STAFF' }
    ],
    CLIENTRIGHTS: [
        { label: 'ADMIN', value: 'CLIENT_ADMIN' },
        { label: 'ASSOCIATE', value: 'CLIENT_STAFF' }
    ],
    AGREEMENTSTATUS: [
        { label: 'PREPARE', value: 'PREPARE' },
        { label: 'INTERIM', value: 'INTERIM' },
        { label: 'ONGOING', value: 'ONGOING' },
        { label: 'EXTENDED', value: 'EXTENDED' },
        { label: 'ENDING', value: 'ENDING' },
        { label: 'CLOSED', value: 'CLOSED' },
    ],
    DEPARTMENTS: [
        { label: 'None', value: 'NONE' },
        { label: 'Admin', value: 'ADMIN' },
        { label: 'Finance', value: 'FINANCE' },
        { label: 'IT', value: 'IT' },
        { label: 'Marketting', value: 'MARKETTING' },
        { label: 'Sales', value: 'SALES' }
    ],
    ASSETSTATUS: [
        { label: 'InUse', value: 'InUse' },
        { label: 'Repair', value: 'Repair' },
        { label: 'Returned', value: 'Returned' },
        { label: 'Recycled', value: 'Recycled' }
    ],
    ASSETCOLUMNS:[{
        title: 'Agreement Ref',
        field: 'agrement_info_no',

    },
    {
        title: 'Type of agreements',
        field: 'typeofaggrements',

    },
    {
        title: 'Sub Agree.ref',
        field: 'sub_agrement_ref',

    }, {
        title: 'Lease start',
        field: 'lease_start_date',

    }, {
        title: 'Lease end',
        field: 'eol_date',

    }, {
        title: 'Agreement Status',
        field: 'ag_status',

    }, {
        title: 'Exit Options',
        field: 'ag_exit_options',

    }, {
        title: 'Invoicing to',
        field: 'ag_involvingto',

    }, {
        title: 'Original Sign Date',
        field: 'ag_original_sign_date',
    },
    {
        title: 'Leasing type',
        field: 'type_of_leasing',
    },
    {
        title: 'No of moth lease',
        field: 'no_of_month_lease',

    },
    {
        title: 'Monthly payment ratio',
        field: 'montly_payment_ratio',

    },
    {
        title: 'Payment interval',
        field: 'billing_period',

    },
    {
        title: 'Delievery approval date',
        field: 'date_of_delievery_approval',
    },
    {
        title: 'Quantity',
        field: 'no_of_quantity',

    },
    {
        title: 'Price per units',
        field: 'priceper_unit',

    },
    {
        title: 'Price total',
        field: 'price_total',
    },
    {
        title: 'Rent / period',
        field: 'rent_period',
    },
    {
        title: 'Daily rent',
        field: 'dailyrent',
    },
    {
        title: 'Upcoming Rents Year',
        field: 'upcomingrents',
    },
    {
        title: 'Upcoming Rents Amount',
        field: 'upcomingrents',
    },
    {
        title: 'Interim no of days',
        field: 'Interim_no_of_days',
    }, {
        title: 'Interim rent',
        field: 'Interim_rent_cost',
    },
    {
        title: 'Product Group',
        field: 'product_group',

    }, {
        title: 'Brand',
        field: 'brand',

    }, {
        title: 'Model',
        field: 'model',

    },
    {
        title: 'Client own product group',
        field: 'client_own_product_group',

    },
    {
        title: 'Status',
        field: 'status',

    },
    {
        title: 'Product Description',
        field: 'product_description',

    },
    {
        title: 'Part no',
        field: 'partno',

    },
    {
        title: 'IMEI',
        field: 'IMEI',

    }, {
        title: 'Processor',
        field: 'processor',

    }, {
        title: 'Memory',
        field: 'memory',

    }, {
        title: 'Serial Number',
        field: 'serial_number',

    }, {
        title: 'Harddisk',
        field: 'harddisk',

    }, {
        title: 'Screen Size',
        field: 'screen_size',

    },
    {
        title: 'Insurance Company',
        field: 'insurance_company',

    },
    {
        title: 'Insurance Until',
        field: 'insurance_until',
    },
    {
        title: 'Warrenty Until',
        field: 'warrenty_until',

    },
    {
        title: 'Age (Months)',
        field: 'age',

    },
    {
        title: 'Extra 1',
        field: 'extra_infor1',

    },
    {
        title: 'Extra 2',
        field: 'extra_infor2',

    },
    {
        title: 'Username',
        field: 'user_name',

    },
    {
        title: 'User email',
        field: 'user_email',
    },
    {
        title: 'User phone no',
        field: 'user_phoneno',

    },
    {
        title: 'User devision',
        field: 'user_devision',

    },

    {
        title: 'Emp Ref no',
        field: 'employee_no',

    },
    {
        title: 'User Invoice address',
        field: 'user_invoice_address',
    },
    {
        title: 'User address',
        field: 'user_address',

    },
    {
        title: 'User city',
        field: 'user_city',

    },
    {
        title: 'User country',
        field: 'user_country',

    },
    {
        title: 'CostCentre',
        field: 'cost_centre',

    },
    {
        title: 'CostCentre1',
        field: 'cost_centre1',

    },
    {
        title: 'CostCentre2',
        field: 'cost_centre2',

    },
    {
        title: 'CC Manager name',
        field: 'ccmanager_name',
    },
    {
        title: 'CC Manager Phone',
        field: 'ccmanager_phone',
    },
    {
        title: 'CC Manager email',
        field: 'ccmanager_email',
    },
    ],

ROLES: [
    {
        "id": "0ae30546-fdfe-4d82-b805-79c29c4eae34",
        "name": "client_admin",
        "description": "CLIENT ADMIN"
    },
    {
        "id": "0f0a53fa-6e05-477a-84e8-8dce577fd634",
        "name": "partner_user",
        "description": "PARTNER USER"
    },
    {
        "id": "7baf4eff-8aeb-4b71-98bd-97dce2f272c5",
        "name": "partner_admin",
        "description": "PARTNER ADMIN"
    },
    {
        "id": "0ae30546-fdfe-4d82-b805-79c29c4eae34",
        "name": "client_admin",
        "description": "CLIENT ADMIN"
    },
    {
        "id": "a84b75fa-03f2-4b1b-a640-e7445379cdb5",
        "name": "client_user",
        "description": "CLIENT USER"
    },
    {
        "id": "ba56fac1-56f4-4127-b276-62882ea93aa4",
        "name": "Administrator",
        "description": "SUPER ADMIN"
    },
    {
        "id": "cad31e36-875f-43e6-ab73-9f961bc1cbb2",
        "name": "master_admin",
        "description": "SUPER ADMIN"
    },
    {
        "id": "df4560bb-8869-42ed-bd48-7a1865ccc12b",
        "name": "admin_user",
        "description": "ADMIN USER"
    }
],

ADMIN: [
    {
        "id": "df4560bb-8869-42ed-bd48-7a1865ccc12b",
        "name": "admin_user",
        "description": "ADMIN USER"
    }
],
PARTNER: [
    {
        "id": "7baf4eff-8aeb-4b71-98bd-97dce2f272c5",
        "name": "partner_admin",
        "description": "PARTNER ADMIN"
    },
    {
        "id": "0f0a53fa-6e05-477a-84e8-8dce577fd634",
        "name": "partner_user",
        "description": "PARTNER USER"
    },

],
PARTNERROLES: [
    {
        "id": "7baf4eff-8aeb-4b71-98bd-97dce2f272c5",
        "name": "partner_admin",
        "description": "PARTNER ADMIN"
    },
    {
        "id": "0f0a53fa-6e05-477a-84e8-8dce577fd634",
        "name": "partner_user",
        "description": "PARTNER USER"
    },
    {
        "id": "0ae30546-fdfe-4d82-b805-79c29c4eae34",
        "name": "client_admin",
        "description": "CLIENT ADMIN"
    },
    {
        "id": "a84b75fa-03f2-4b1b-a640-e7445379cdb5",
        "name": "client_user",
        "description": "CLIENT USER"
    },
],
CLIENTROLES: [

    {
        "id": "0ae30546-fdfe-4d82-b805-79c29c4eae34",
        "name": "client_admin",
        "description": "CLIENT ADMIN"
    },
    {
        "id": "a84b75fa-03f2-4b1b-a640-e7445379cdb5",
        "name": "client_user",
        "description": "CLIENT USER"
    },],


}

