/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
// import {
//   MixedWidget2,
// } from '../../../_metronic/partials/widgets'
import SummaryTable from '../../../_metronic/helpers/components/Summary_table';
import PartnerSummaryTable from '../../../_metronic/helpers/components/partner_Sum';
import AgreementTable from '../../modules/agreements/index';
import { KTSVG } from '../../../_metronic/helpers'
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { directUSAPICall, SVformatter, numberWithCommas } from '../../../_metronic/helpers/components/utils';
import PageLoader from "../../../_metronic/helpers/components/PageLoader";
import { getassetsList } from '../../modules/assets/redux/AssetsCrud';
import _ from "lodash";
import { Pie, Bar, measureTextWidth, Column, G2 } from '@ant-design/charts';
import { useLocation, useHistory } from 'react-router-dom'
import { parse } from 'url';


const dashboardfields = [
  'ag_status',
  'heading',
  'agrement_info_no',
  'eol_date',
  'lease_start_date',
  'type_of_product',
  'client',
  'partner'
]
const DashboardPage = ({
  totalAssets,
  totalAssetsList,
  upcomingrents,
  title,
  agreementListTotal,
  agreementList,
  sumPurchaseAmountByPartner,
  sumPurchaseAmountByPartnerSubtotal

}) => (
  <>
    {/* begin::Row */}

    <div className='row gy-5 g-xl-8'>
      <div className='col-lg-3'>
        {/* <MixedWidget2
          className='card-xl-stretch mb-xl-8'
          chartColor='danger'
          chartHeight='200px'
          strokeColor='#cb1e46'
          totalAssets={totalAssets}
          agreementListTotal={agreementListTotal}
        /> */}
        <div className={`card card-xl-stretch mb-xl-8`}>
          {/* begin::Header */}
          <div className={`card-header border-0 py-5 bg-danger`}>
            <h3 className='card-title fw-bolder text-white'>Assets Statistics</h3>
            <div className='card-toolbar'>
              {/* begin::Menu */}
              {/* <button
            type='button'
            className='btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color- border-0 me-n3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button> */}
              {/* <Dropdown1 /> */}
              {/* end::Menu */}
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body p-0'>
            {/* begin::Chart */}
            <div
              className={`mixed-widget-2-chart card-rounded-bottom bg-danger`}
            ></div>
            {/* end::Chart */}
            {/* begin::Stats */}
            <div className='card-p position-relative'>
              {/* begin::Row */}
              <div className='row g-0'>
                {/* begin::Col */}
                <div className='col bg-light-warning px-6 py-8 rounded-2 me-7 mb-7'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen032.svg'
                    className='svg-icon-3x svg-icon-warning d-block my-2'
                    numbers={agreementListTotal ? (agreementListTotal)?.toString() : "0"}
                  />
                  <a href='#' className='text-warning fw-bold fs-6'>
                    Number of Agreements
              </a>
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col bg-light-primary px-6 py-8 rounded-2 mb-7'>
                  <KTSVG
                    path='/media/icons/duotune/abstract/abs027.svg'
                    className='svg-icon-3x svg-icon-danger d-block my-2'
                    numbers={totalAssets ? totalAssets?.toString() : '0'}

                  />
                  <a href='#' className='text-primary fw-bold fs-6'>
                    Number of Assets
              </a>
                </div>
                {/* end::Col */}
              </div>
              {/* end::Row */}
            </div>
            {/* end::Stats */}
          </div>
          {/* end::Body */}
        </div>
      </div>
      <div className='col-lg-5'>
        <SummaryTable totalAssets={totalAssets} totalAssetsList={totalAssetsList} />
      </div>
      <div className='col-lg-4'>
        <PartnerSummaryTable
          title={title}
          sumPurchaseAmountByPartner={sumPurchaseAmountByPartner}
          sumPurchaseAmountByPartnerSubtotal={sumPurchaseAmountByPartnerSubtotal}
        />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
    </div>
    {/* end::Row */}
    <div id="kt_subheader" className="subheader py-2 py-lg-4 subheader-solid">
    </div>
    <div id="kt_subheader" className="subheader py-2 py-lg-4 subheader-solid">
    </div>
    {/* begin::Row */}
    <div className='row gy-5 g-lg-8 g-xl-8'>
      <div className='col-xl-12'>
        <AgreementTable dashBoardAggList={agreementList} page="/dashboard" page="dashboard" dashboardfields={dashboardfields} />
      </div>
    </div>
    <div id="kt_subheader" className="subheader py-2 py-lg-4 subheader-solid">
    </div>
    {/* end::Row */}
    <div className='row gy-5 g-lg-8 g-xl-8'>
    </div>
  </>
)

export default function DashboardWrapper() {
  const intl = useIntl()
  const [totalAssets, setTotalAssets] = useState(0)
  const [upcomingrents, setUpcomingrents] = useState([])
  const [totalAssetsList, setTotalAssetsList] = useState([])
  const [loading, setLoading] = useState(false)
  const [agreementList, setAgreementList] = useState([])
  const [agreementListTotal, setAgreementListTotal] = useState(0)
  const [noOfAggByPartner, setNoOfAggByPartner] = useState([])
  const [sumPurchaseAmountByPartner, setSumPurchaseAmountByPartner] = useState([])
  const { data } = useSelector(({ auth }) => auth.user);
  const [title, setTitle] = useState()
  const [sumPurchaseAmountByPartnerSubtotal, setsumPurchaseAmountByPartnerSubtotal] = useState(0)
  const [totalAggTitle, setTotalAggTitle] = useState('');
  const [totalFinanceAggTitle, setTotalFinanceAggTitle] = useState('');
  const [aggAssetName, setAggAssetName] = useState('')
  const [upComingBarChart, setUpComingBarChart] = useState([])

  const [userInfo, setUserInfo] = useState(data);
  const location = useLocation()


  useEffect(() => {
    if (data && data?.id && location && location.pathname === '/dashboard') {
      setUserInfo(data)
      setLoading(true)
      setTimeout(() => {
        groupByAgreementAssets()

      }, 500)

    }
  }, [data, location])

  useEffect(() => {
    if (userInfo && upcomingrents) {
      // console.log("upcomingrents", upcomingrents)
      let upcoming_rents = upcomingrents.reduce((o, m) => [...m, ...o], []);
      // console.log(moment().format('YYYY'), "upcoming_rents", upcoming_rents);
      let totalAssetssum1 = [];
      if (Array.isArray(upcoming_rents)) {
        upcoming_rents = upcoming_rents.filter((item) => item.year >= moment().format('YYYY'));
        if (upcoming_rents) {
          var groups = _.groupBy(upcoming_rents, function (value) {
            return value.year;
          });
          totalAssetssum1 = _.map(groups, function (group) {
            return {
              // agreement: group[0].agreement,
              year: group[0].year,
              "value": _.round(_.sumBy(group, x => x.rentamount ? (parseFloat(x.rentamount)) : 0), 0),
            }
          });
          setUpComingBarChart(totalAssetssum1)
        }
      }
    }
  }, [userInfo, upcomingrents])

  const groupByAgreementAssets = () => {
    let queryParam = `?limit=-1&groupBy[]=aggrement&aggregate[count]=id`
    // &filter[aggrement.id][_eq]=
    getassetsList(queryParam)
      .then(({ data: { data } }) => {
        listsAllAgreement(data)
      })
      .catch(() => {
        listsAllAgreement()
        // setLoading(false)
        //   setStatus('The login detail is incorrect')
      })
  }

  useEffect(() => {
    if (agreementList) {
      // console.log("totalAssetsList", totalAssetsList)
      let noofAggByPartner = [];
      let sumPurchaseAmountByPartner = [];
      if (userInfo && userInfo?.role?.name === 'client_admin' || userInfo && userInfo?.role?.name === 'client_user') {
        setAggAssetName('Partner')
        setTotalAggTitle('VOLUME FINANCING / PARTNER')
        setTotalFinanceAggTitle('NUMBER OF AGREEMENTS / PARTNER')
      } else if (userInfo && userInfo?.role?.name === 'partner_admin' || userInfo && userInfo?.role?.name === 'partner_user') {
        setAggAssetName('Client')
        setTotalAggTitle('NUMBER OF AGREEMENTS / CLIENT')
        setTotalFinanceAggTitle('VOLUME FINANCING / CLIENT')
      } else {
        setAggAssetName('Partner')
        setTotalAggTitle('NUMBER OF AGREEMENTS / PARTNER')
        setTotalFinanceAggTitle('VOLUME FINANCING / PARTNER')
      }
      if (userInfo && userInfo?.role?.name === 'partner_admin' || userInfo && userInfo?.role?.name === 'partner_user') {
        setTitle('Client')
        noofAggByPartner = _(agreementList)
          .groupBy('client.clients_org_no')
          .map(group => ({
            name: group[0]?.client?.name || 'Other',
            "value": group.length
          }))
          .value();
        sumPurchaseAmountByPartner = _(agreementList)
          .groupBy('client.clients_org_no')
          .map(group => ({
            name: group[0]?.client?.name || 'Other',
            "value": _.sumBy(group, x => x.sum_purchase_value ? parseFloat(x.sum_purchase_value) : 0),
          }))
          .value();
      }
      else if (userInfo && userInfo?.role?.name === 'client_admin' || userInfo && userInfo?.role?.name === 'client_user') {
        setTitle('Partner')

        noofAggByPartner = _(agreementList)
          .groupBy('partner.company_org_no')
          .map(group => ({
            name: group[0]?.partner?.partner_name || 'Other',
            // "value": _.countBy(group[0], 'partner.company_org_no')[group[0]?.partner?.partner_name],
            "value": group.length
          }))
          .value();

        sumPurchaseAmountByPartner = _(agreementList)
          .groupBy('partner.company_org_no')
          .map(group => ({
            name: group[0]?.partner?.partner_name || 'Other',
            "value": _.sumBy(group, x => x.sum_purchase_value ? parseFloat(x.sum_purchase_value) : 0),
          }))
          .value();


      } else {
        setTitle('Partner')

        sumPurchaseAmountByPartner = _(agreementList)
          .groupBy('partner.company_org_no')
          .map(group => ({
            name: group[0]?.partner?.partner_name || 'Other',
            "value": _.sumBy(group, x => x.sum_purchase_value ? parseFloat(x.sum_purchase_value) : 0),
          }))
          .value();

        noofAggByPartner = _(agreementList)
          .groupBy('partner.company_org_no')
          .map(group => ({
            name: group[0]?.partner?.partner_name || 'Other',
            // "value": _.countBy(group[0], 'partner.company_org_no')[group[0]?.partner?.partner_name],
            "value": group.length
          }))
          .value();
      }

      let subtotal = sumPurchaseAmountByPartner.map(({ value }) => value).reduce((sum, i) => sum + i, 0);
      setsumPurchaseAmountByPartnerSubtotal(subtotal)
      setSumPurchaseAmountByPartner(sumPurchaseAmountByPartner)

      setNoOfAggByPartner(noofAggByPartner)
    }
  }, [agreementList])


  const listsAllAgreement = async (asssetData = []) => {
    setLoading(true)
    let dasbaordfields = 'id,ag_status,heading,agrement_info_no,eol_date,lease_start_date,type_of_product,client.name,client.clients_org_no,partner.company_org_no,partner.partner_name,sum_purchase_value'
    let options = {
      limit: -1,
      sort: '-id',
      fields: [dasbaordfields],

    }
    if (userInfo.role) {
      if ((userInfo.role.name === "client_admin") || (userInfo.role.name === "client_user")) {

        options.filter = {
          "client.id": {
            _eq: userInfo.client.id
          }
        }
      }
      if (userInfo.role.name === 'partner_admin' || userInfo.role.name === 'partner_user') {
        // console.log("users_details", users_details)
        options.filter = {
          "partner.id": {
            _eq: userInfo.partner.id
          }
        }
      }
    }
    const result = await directUSAPICall().items('aggrement').readMany(options);
    if (result.data && result.data.length > 0) {
      if (asssetData && asssetData.length > 0) {
        result.data.forEach((item) => {
          let response = asssetData.filter((row) => row.aggrement === item.id);
          item.totalAssets = response && response.length > 0 ? parseInt(response[0].count.id) : 0
        })
      }
      setAgreementList(result.data)
      setAgreementListTotal(result.data.length)
      setLoading(false)
      callAssetsList()

    } else {
      setAgreementList([])
      setAgreementListTotal([])
      setLoading(false)
      //   setStatus('The login detail is incorrect')
    }
  }

  const callAssetsList = async () => {
    setLoading(true)
    let options = {
      limit: -1,
      sort: '-id',
      fields: ['upcomingrents,no_of_quantity,product_group,price_total'],

    }
    if (userInfo.role) {
      if ((userInfo.role.name === "client_admin") || (userInfo.role.name === "client_user")) {

        options.filter = {
          "client.id": {
            _eq: userInfo.client.id
          }
        }
      }
      if (userInfo.role.name === 'partner_admin' || userInfo.role.name === 'partner_user') {
        // console.log("users_details", users_details)
        options.filter = {
          "partner.id": {
            _eq: userInfo.partner.id
          }
        }
      }
    }
    const result = await directUSAPICall().items('Asset').readMany(options);
    if (result.data && result.data.length > 0) {
      let response = result.data;
      let total = 0;
      let rents_upcoming = [];
      response.forEach((item) => {
        if (item.upcomingrents) {
          item.upcomingrents.forEach((rents) => {
            rents.agreement = item.agrement_info_no;
            rents.year = rents.to ? moment(rents.to, 'YYYY-MM-DD').format('YYYY') : ''
          })
          rents_upcoming.push(item.upcomingrents)
        }
        total += item.no_of_quantity ? parseInt(item.no_of_quantity) : 0
      })
      setUpcomingrents(rents_upcoming);
      // setUpcomingrents(response.map(
      //   (item) => item.upcomingrents && item.upcomingrents)
      // );
      setTotalAssetsList(response);
      setTotalAssets(total)
      setLoading(false)
      // AggByPartner()
    }
    else {
      setLoading(false)

    }
  }
  let config2 = {
    appendPadding: 10,
    data: sumPurchaseAmountByPartner,
    angleField: 'value',
    colorField: 'name',
    radius: 0.8,
    innerRadius: 0.6,
    label: false,
    label1: {
      type: 'outer',
      content: '{name}',
    },
    meta: {
      value: {
        formatter: function formatter(v) {
          return v ? SVformatter(Math.floor(v)) : 0;
        },
      },

    },
    statistic: {
      content: {
        offsetY: 4,
        style: { fontSize: '18px' },
        customHtml: function customHtml(container, view, datum, data) {
          var _container$getBoundin2 = container.getBoundingClientRect(),
            width = _container$getBoundin2.width;
          var text = datum
            ? ''.numberWithCommas(datum.value)
            : ''.concat(
              SVformatter(Math.floor(data.reduce(function (r, d) {
                return r + d.value;
              }, 0)))
            );
          return renderStatistic(width, text, { fontSize: 32 });
        },
      },
    },
    interactions: [
      { type: 'element-selected' },
      { type: 'element-active' },
      { type: 'pie-statistic-active' },
    ],
    interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }],
  };


  let config1 = {
    appendPadding: 10,
    data: noOfAggByPartner,
    angleField: 'value',
    colorField: 'name',
    radius: 0.8,
    innerRadius: 0.6,
    label: false,
    label1: {
      type: 'outer',
      content: '{name}',
    },
    interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }],
  };

  // console.log("pieConfigs", pieConfigs)
  function renderStatistic(containerWidth, text, style) {
    var _measureTextWidth = (0, measureTextWidth)(text, style),
      textWidth = _measureTextWidth.width,
      textHeight = _measureTextWidth.height;
    var R = containerWidth / 2;
    var scale = 1;
    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))),
        ),
        1,
      );
    }
    var textStyleStr = 'width:'.concat(containerWidth, 'px;');
    return '<div style="'
      .concat(textStyleStr, ';font-size:')
      .concat(scale, 'em;line-height:')
      .concat(scale < 1 ? 1 : 'inherit', ';">')
      .concat(text, '</div>');
  }
  var brandColor = '#5B8FF9';

  var barconfig = {
    data: upComingBarChart.sort((a, b) => parseInt(a.year) - parseInt(b.year)),
    xField: 'year',
    yField: 'value',
    seriesField: '',
    // color: function color(_ref) {
    //   return brandColor;
    // },
    label: {
      content: function content(originData) {
        return SVformatter(Math.floor(originData.value));
      },
      offset: 10,
    },
    legend: false,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };

  return (
    <>
      <PageLoader pageLoaderOpen={loading} setPageLoaderOpen={setLoading} />
      <PageTitle breadcrumbs={[
        {
          title: 'Dashboard',
          path: '/dashboard',
          isSeparator: false,
          isActive: false,
        }
      ]}>{'Dashboard'}</PageTitle>
      <div id="kt_subheader" className="subheader py-2 py-lg-4 subheader-solid">
      </div>
      <DashboardPage
        agreementList={agreementList}
        agreementListTotal={agreementListTotal}
        userDetail={userInfo}
        totalAssets={totalAssets}
        title={title}
        totalAssetsList={totalAssetsList}
        upcomingrents={upcomingrents}
        sumPurchaseAmountByPartner={sumPurchaseAmountByPartner}
        sumPurchaseAmountByPartnerSubtotal={sumPurchaseAmountByPartnerSubtotal}
      // sumOption={sumOption}
      />
      <div id="kt_subheader" className="subheader py-2 py-lg-4 subheader-solid">
      </div>
      <div className='row gy-5 g-xl-12'>
        {/* <div className='col-xl-12'>
        <AgreementTable dashBoardAggList={agreementList} page="/dashboard" page="dashboard" dashboardfields={dashboardfields} />
      </div> */}

        <div className='col-xl-6 col-xl-6'>
          <div className="card card-custom gutter-b">
            <div
              className='card-header border-0 cursor-pointer'
            >
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>{totalAggTitle}</h3>
              </div>
            </div>
            <div className='card-body'>
              <div>
                {<Pie {...config1} />}
              </div>
            </div>
          </div>


        </div>
        <div className='col-xl-6'>
          <div className="card card-custom gutter-b">
            <div
              className='card-header border-0 cursor-pointer'
            >
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>{totalFinanceAggTitle}</h3>
              </div>
            </div>
            <div className='card-body'>
              <div>
                {<Pie {...config2} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="kt_subheader" className="subheader py-2 py-lg-4 subheader-solid">
      </div>
      <div className='row gy-5 g-xl-12'>

        {
          userInfo && userInfo?.role?.name !== 'master_admin' && upComingBarChart.length < 50 ? <div className='col-xl-8'>
            <div className="card card-custom gutter-b">
              <div
                className='card-header border-0 cursor-pointer'
              >
                <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>{totalFinanceAggTitle}</h3>
                </div>
              </div>
              <div className='card-body'>
                <div>
                  {<Column {...barconfig} />
                  }
                </div>
              </div>
            </div>
          </div> : null
        }
      </div>
      <div id="kt_subheader" className="subheader py-2 py-lg-4 subheader-solid">
      </div>
    </>
  )
}

export { DashboardWrapper }
