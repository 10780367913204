import CloudDownload from '@material-ui/icons/CloudDownload';
import { directUSAPIToken } from '../../../../_metronic/helpers/components/utils';
import { API_URL } from '../../../../_metronic/helpers/components/constant'

export default function FileList({ className, files, isDisable }) {
    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0'>
                <h3 className='card-title fw-bolder text-dark'>Files</h3>
                <div className='card-toolbar'>
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body pt-2'>
                {
                    files?.length > 0 ? files.map((item, key) => (
                        <div className='d-flex align-items-center mb-8'>
                            {/* begin::Bullet */}
                            <span className='bullet bullet-vertical h-40px bg-success'></span>
                            {/* end::Bullet */}
                            {/* begin::Checkbox */}
                            <div className='form-check form-check-custom form-check-solid mx-5'>
                            </div>
                            {/* end::Checkbox */}
                            {/* begin::Description */}
                            <div className='flex-grow-1'>
                                <a href='#' target="_blank" href={`${API_URL}/assets/${item.directus_files_id.id}?accessToken=${directUSAPIToken()}`} download className='text-gray-800 text-hover-primary fw-bolder fs-6'>
                                    {item.directus_files_id.title}
                                </a>
                            </div>
                            {/* end::Description */}
                            {
                                !isDisable ?
                                    <span className='badge badge-light-success fs-8 fw-bolder'><a href='#' target="_blank" href={`${API_URL}/assets/${item.directus_files_id.id}?accessToken=${directUSAPIToken()}`} download className='text-gray-800 text-hover-primary fw-bolder fs-6'>
                                        <CloudDownload />
                                    </a></span> : null}
                        </div>
                    )) :
                        <div className='d-flex align-items-center mb-8'>
                            No data found
                          </div>
                }
            </div>
            {/* end::Body */}
        </div>
    )
}