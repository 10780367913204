// import {
//   faTimes,
//   faFileUpload,
//   faCheckCircle,
//   faExclamationCircle,
// } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import Check from '@material-ui/icons/Check';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Cancel from '@material-ui/icons/Cancel';

import { Link } from "react-router-dom";

// import { Steps, Col, Progress, Icon, Spin } from 'antd';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import XLSX from 'xlsx';
import moment from "moment";

import {
  FILE_UPLOAD_ROW_COUNT_ERROR,
  FILE_UPLOAD_ROW_COUNT_ERROR_MESSAGE,
  FILE_UPLOAD_AGG_ID_MISSING_MESSAGE,
  FILE_UPLOAD_EMPTY_FILE_MESSAGE,
} from '../../../../_metronic/helpers/components/constant'
import React, { useState, useRef, useEffect, useContext } from 'react';
import './index.css';
import _ from "lodash";
import { directUSAPICall, getMonthDiff } from "../../../../_metronic/helpers/components/utils";
import CircularProgressWithLabel from "../../../../_metronic/helpers/progressBar";
import { getAgreementStatus } from '../../../../_metronic/helpers/components/calculation'

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogRoot: {
      width: '600px',
      height: '450px',
      display: 'flex',
      flexDirection: 'column',
    },
    dialogTitle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '40px',
      background: 'linear-gradient(to right, #eef2f3, #8e9eab)',
      boxShadow: '1px 1px 3px #8e9eab',
    },
    dialogTitleText: {
      display: 'flex',
      justifyContent: 'space-between',
      // fontFamily: "'Poppins'",
      fontWeight: 700,
      textTransform: 'uppercase',
      fontSize: '0.85rem',
    },
    dialogContent: {
      //   marginTop: '10px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '50%',
      marginBottom: '15px',
      marginRight: '15px',
    },
    button: {
      marginLeft: '15px',
    },
    buttonProgress: { marginLeft: '5px' },
    actionIcon: {
      fontSize: '1.25rem',
      cursor: 'pointer',
    },
    stepContent: {
      width: '100%',
    },
    steps: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      //   paddingLeft: '20px',
    },
    stepsText: {
      marginTop: '15px',
      // fontFamily: 'Poppins',
      fontSize: '20px',
    },
    stepsTextError: {
      marginTop: '15px',
      // fontFamily: 'Poppins',
      fontSize: '20px',
      color: 'red',
      textAlign: 'center',
    },
    stepsSubText: {
      marginTop: '5px',
      // fontFamily: 'Poppins',
      fontSize: '12px',
    },
    uploadArea: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: '#ababab',
      '&:hover': {
        color: '#003366',
        cursor: 'pointer',
      },
    },
    uploadIcon: {
      fontSize: '80px',
      // color: '#cdcdcd',
      // '&:hover': {
      //   color: '#003366',
      //   cursor: 'pointer',
      // },
    },
    successIcon: {
      color: '#003366',
      marginBottom: '15px',
    },
    errorIcon: {
      color: '#eb8034',
      marginBottom: '15px',
    },
    successArea: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    spinnerArea: {
      marginBottom: '10px',
    },
    animatedItem: {
      animation: `$myEffect 300ms ${theme.transitions.easing.easeInOut}`,
    },
    '@keyframes myEffect': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
    recordsCount: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 500,
      fontSize: '14px',
      marginBottom: '10px'
    }
  })
);

const FileUploadDialog = ({
  open,
  title,
  // allAgreements,
  setOpen,
  // companyList,
  // supplierList,
  getNewData,
  // userList,
  // clientList,
  parentGridApi
}) => {
  // console.log('allAgreements', allAgreements, assetTypes, statusNames);
  const classes = useStyles();
  const fileUploader = useRef(null);
  // const appContext = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [enableSubmission, setEnableSubmission] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [fileUploadSuccess, setFileUploadSuccess] = useState(false);
  const [fileUploadError, setFileUploadError] = useState(false);
  const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState('');
  const [data, setData] = useState([]);
  const [cols, setCols] = useState([]);
  const [updateRecordsCount, setUpdateRecordsCount] = useState(0);
  const [steps, setSteps] = useState([
    'Select a file',
    'Update Records',
    'Insert Records',
  ]);
  const [currentStep, setCurrentStep] = useState(0);
  const [assetIDRows, setAssetIDRows] = useState([]);
  const [nonAssetIDRows, setNonAssetIDRows] = useState([]);
  const [companyList, setCompanyList] = useState();
  const [newPartner, setNewPartner] = useState(false);
  const [newClient, setNewClient] = useState(false);
  const [newSupplier, setNewSupplier] = useState(false);
  const [allAgreements, setAllAgreements] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    handleReset();
    if (open) {
      listsAllAgreement()
    }
  }, [open]);

  const listsAllAgreement = async () => {
    setAssetIDRows([])
    const result = await directUSAPICall().items('aggrement').readMany({ limit: -1, fields: ['agrement_info_no,id,no_of_month_lease'] });
    if (result.data && result.data.length > 0) {
      setAllAgreements(result.data);

    }
    callPartnersList()
    callClientsList()
    callSuppliersList()
    callUsersList()
    // const agreems = directUSAPICall().readMany({ fields: ['title'] });
    // console.log("agreems", agreems)
  }

  const callPartnersList = async () => {
    const result = await directUSAPICall().items('partners').readMany({ fields: ['id, company_org_no'] });
    if (result.data && result.data.length > 0) {
      setCompanyList(result.data)
    }
  }

  const callUsersList = async () => {
    const result = await directUSAPICall().items('directus_users').readMany({ fields: ['id, email'] });
    if (result.data && result.data.length > 0) {
      setUsersList(result.data)
    }
  }

  const callClientsList = async () => {
    const result = await directUSAPICall().items('clients').readMany({ fields: ['id, clients_org_no'] });
    if (result.data && result.data.length > 0) {
      setClientList(result.data)
    }
  }

  const callSuppliersList = async () => {
    const result = await directUSAPICall().items('supplier').readMany({ fields: ['id, supplier_org_no'] });
    if (result.data && result.data.length > 0) {
      setSupplierList(result.data)
    }
  }


  useEffect(() => {
    if (!success) {
      return;
    }
    // console.log("allAgreements", allAgreements)
    let allAssetIDs = allAgreements.map((asset) => asset.agrement_info_no);
    setAssetIDRows(
      data.filter((row) => allAssetIDs.indexOf('' + row.agrement_info_no) !== -1)
    );
    setNonAssetIDRows(
      data.filter((row) => allAssetIDs.indexOf('' + row.agrement_info_no) === -1)
    );

  }, [success]);
  // console.log('setAssetIDRows', assetIDRows);
  // console.log('setNonAssetIDRows', nonAssetIDRows);

  const handleReset = () => {
    setLoading(false);
    setSuccess(false);
    setError(false);
    setData([]);
    setCols([]);
    setCurrentStep(0);
    setAssetIDRows([]);
    setNonAssetIDRows([]);
    setFileUploadSuccess(false);
    setFileUploadErrorMessage('');
    setFileUploadError(false);
    setNewPartner(false)
    setNewClient(false)
    setNewSupplier(false)
    if (fileUploader.current) {
      fileUploader.current.value = null;
    }
  };

  const handleNext = () => {
    setSuccess(false);
    setError(false);
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setAssetIDRows([])
    setCurrentStep((prev) => prev - 1);
  };

  const handleUpdate = async () => {
    setLoading(true);
    let summaryOffset = 0;
    try {
      // return
      // let noofRequests = 50;
      // let tempassetIDRows = assetIDRows;
      let result;
      for (let i = 0; i <= assetIDRows.length; i++) {
        if (assetIDRows[i] && assetIDRows[i].id) {
          setUpdateRecordsCount(i + 1);
          // console.log("assetIDRows", assetIDRows.length)
          if (updateRecordsCount === assetIDRows.length) {
            break
          }
          result = await directUSAPICall().items('aggrement').updateOne([assetIDRows[i].id], assetIDRows[i]);
        }
      }

      setLoading(false);
      setUpdateRecordsCount(0)
      if (result.id) {
        setAssetIDRows([])
        setSuccess(true);
        // setUpdateRecordsCount(0)

      } else {
        summaryOffset = 0;
        setUpdateRecordsCount(0)

        setError(true);
        setLoading(false);
        setFileUploadErrorMessage('Agreement info imported successfully ');
      }
    } catch (err) {
      console.log('update error', err);
      setLoading(false);
      setError(false);
      setFileUploadErrorMessage('Failed to imported');
      throw err;
    }
  };


  const handleInsert = async () => {
    setLoading(true);
    let dataWithoutAssetIds = nonAssetIDRows.map((asset) => {
      let tempObject = { ...asset };
      if (!tempObject.asset_id) {
        tempObject.asset_id = null;
      }
      Object.keys(tempObject).forEach((key) => {
        if (!tempObject[key]) {
          delete tempObject[key];
        }
      });

      return tempObject;
    });

    let summaryOffset = 0;

    try {
      let noofRequests = 50;

      let tempassetIDRows = dataWithoutAssetIds;
      let insertSuccess;
      for (let i = 0; i <= dataWithoutAssetIds.length; i += 50) {
        let filterassetIDRows = tempassetIDRows.slice(i, noofRequests + i);
        insertSuccess = await directUSAPICall().items('aggrement').createMany(filterassetIDRows);

        summaryOffset += filterassetIDRows.length;
        setUpdateRecordsCount(summaryOffset);
      }
      // const insertSuccess = await directUSAPICall().items('aggrement').createMany(dataWithoutAssetIds);
      // if (result.data && result.data.length > 0) {
      //   setClientList(result.data)
      // }
      // let insertSuccess = await fetchPost(
      //   `${API_URL}${DATAURLS.AGREEMENTBULKCREATE.url}`,
      //   dataWithoutAssetIds
      // );
      setLoading(false);
      if (insertSuccess?.data?.length > 0) {
        setSuccess(true);
      } else {
        setError(true);
        setLoading(false);
        setFileUploadErrorMessage('Agreement Successfully imported');
      }
    } catch (err) {
      setLoading(false);
      setError(false);
      setFileUploadErrorMessage('Failed to import. Please try again.');
      throw err;
    }
  };

  function ExcelDateToJSDate(serialDate) {
    var days = Math.floor(serialDate);
    var hours = Math.floor((serialDate % 1) * 24);
    var minutes = Math.floor((((serialDate % 1) * 24) - hours) * 60)
    return new Date(Date.UTC(0, 0, serialDate, hours - 17, minutes));
  }

  // const getOrgNo = (orgNo, name) => {
  //   let response = companyList.filter((row) => row.company_org_no == orgNo)
  //   if (response.length === 0) {
  //     const result = directUSAPICall().items('partners').createOne({ company_org_no: orgNo, partner_name: name }, { fields: ['id, company_org_no'] });
  //     if (result.data && result.data?.id) {
  //       response[0] = result.data
  //     }
  //   }
  //   return response && response[0] ? response[0] : ""
  // }


  const getOrgNo = (orgNo, table, namea) => {
    let options = {
      fields: ['id'],
      filter: {
      }
    };
    let arr = []
    let response
    let params = {
      status: 'published'
    }
    if (orgNo) {
      if (table === 'clients') {
        arr = clientList
        response = arr.filter((row) => row.clients_org_no == orgNo)
        options.filter = {
          'clients_org_no': {
            _eq: orgNo
          }
        }
        params.clients_org_no = orgNo;
        params.name = namea;
      } else if (table === 'partners') {
        arr = companyList
        response = arr.filter((row) => row.company_org_no == orgNo)
        options.filter = {
          'company_org_no': {
            _eq: orgNo
          }
        }
        params.company_org_no = orgNo;
        params.partner_name = namea;

      }
      else if (table === 'supplier') {
        arr = supplierList
        response = arr.filter((row) => row.supplier_org_no == orgNo)
        options.filter = {
          'supplier_org_no': {
            _eq: orgNo
          }
        }
        params.supplier_org_no = orgNo;
        params.supplier_name = namea;

      }
      // const response = directUSAPICall().items(table).readMany(options);
      if (response && response.length === 0) {
        return params;
      } else {
        return response && response[0] ? response[0] : ""
      }
    }

  }

  // const getSupplierOrgNo = (orgNo, name) => {
  //   let response = supplierList.filter((row) => row.supplier_org_no == orgNo)
  //   if (response.length === 0) {
  //     const result = directUSAPICall().items('supplier').createOne({ supplier_org_no: orgNo, name: name }, { fields: ['id, supplier_org_no'] });
  //     if (result.data && result.data?.id) {
  //       response[0] = result.data
  //     }
  //   }
  //   return response && response[0] ? response[0] : ""
  // }

  const getUsers = (email) => {
    let reponse = usersList.filter((row) => row.email == email);
    // console.log("reponse",)
    return reponse && reponse[0] ? reponse[0].id : 0
  }
  function isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }


  function number_test(n) {
    var result = (n - Math.floor(n)) !== 0;

    if (result)
      return true;
    else
      return false;
  }


  const handleFileUpload = async (event) => {
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }
    const file = files[0];

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    setLoading(true);
    try {
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        // const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
        const wb = XLSX.read(bstr, { type: 'binary' });
        // const wb = XLSX.readFile(file.path);
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        // const data = XLSX.utils.sheet_to_json(ws, { defval: null });
        /* Convert array of arrays */
        let data = XLSX.utils.sheet_to_json(ws, {
          defval: null,
          // header:1,
          blankRows: false
        });
        let duplicateClints = []
        let duplicatePartners = []
        let supplierDuplicates = []
        let newpartners = [];
        let newClients = [];
        let newSuppliers = [];
        data.forEach(async (item) => {
          // console.log("item", item)
          // console.log("item.founding", item.founding)
          // console.log("item.client_interest", item.client_interest)
          let partner_org = item.partner_org_no || item.company_org_no
          let client_org = item.clients_org_no || item.client_org_no
          if (item.agrement_info_no) {
            // console.log("partner_org ***", partner_org)
            delete item.clients_attn

            delete item.sub_agrement_ref
            item.agrement_info_no = isNumber(item.agrement_info_no) ? item.agrement_info_no : item.agrement_info_no.trim();
            if (item.agrement_info_no) {
              let reponse = allAgreements.filter((row) => row.agrement_info_no == item.agrement_info_no);
              if (reponse && reponse.length > 0) {
                if (!item.id) {
                  item.id = reponse[0].id;
                }
                if (!item.no_of_month_lease) {
                  item.no_of_month_lease = reponse[0].no_of_month_lease;
                }
              }
            }
            item.status = 'published'
            let partnerData = {}
            if (partner_org) {
              partnerData = getOrgNo(item.partner_org_no ? item.partner_org_no : item.company_org_no, 'partners', item.partner_name)
              // console.log("partnerData ***", partnerData)
              if (partnerData?.id) {
                item.partner = partnerData.id
                // item.company_org_no = item.partner_org_no
              } else {
                // item.partner = partnerData.status ? partnerData : null
              }
              if (!partnerData && item.partner_org_no) {
                newpartners.push(item.partner_org_no)
              }
            }
            let clientData = {}
            if (client_org) {
              clientData = getOrgNo((item.clients_org_no ? item.clients_org_no : item.client_org_no), 'clients', item.client_name)
              if (clientData?.id) {
                item.client = clientData.id
                // item.clients_org_no = clientData.clients_org_no
              } else {
                // item.client = clientData.status ? clientData : null
              }
              if (!clientData && item.client_org_no) {
                newClients.push(item.client_org_no)
                // console.log("item.partner_org_no", item.partner_org_no)
              }
            }
            // console.log("clientData", clientData)

            // let clientData = getOrgNo(item.clients_org_no ? item.clients_org_no : item.client_org_no, 'clients', item.client_name)
            // let supplierData = null
            if (item.supplier_org_no) {
              let supplierData = getOrgNo(item.supplier_org_no, 'supplier', item.supplier_name)
              // console.log("supplierData", supplierData)
              if (supplierData?.id) {
                item.founding_partner = supplierData.id
                // item.supplier_org_no = supplierData.supplier_org_no
              } else {
                // item.founding_partner = supplierData.status ? supplierData : null
              }

              if (!supplierData && item.supplier_org_no) {
                newSuppliers.push(item.supplier_org_no)
                // console.log("item.partner_org_no", item.partner_org_no)
              }
            }

            if (item.clients_attn) {
              // = getUsers(item.clients_attn)
            }


            // // console.log("clientData users", clientData.users[0])

            if (partnerData && item.partner_name &&
              (partnerData.partner_name === item.partner_name)) {
              // console.log(item.partner_org_no, "item.partner_name", item.partner_name)
            } else {
              duplicatePartners.push(item.agrement_info_no)
            }

            if (item.founding) {
              item.founding = (item.founding * 100).toFixed(2);
            }
            if (item.client_interest) {
              item.client_interest = (item.client_interest * 100).toFixed(2);
            }
            if (item.pre_sold) {
              item.pre_sold = (item.pre_sold * 100).toFixed(2);
            }
            if (item.montly_payment_ratio) {
              item.montly_payment_ratio = (item.montly_payment_ratio * 100).toFixed(2);
            }
            if (item.EOL_percentage) {
              item.EOL_percentage = (item.EOL_percentage * 100).toFixed(2);
            }
            if (item.commision_to_partner_percentage) {
              item.commision_to_partner_percentage = (item.commision_to_partner_percentage * 100).toFixed(2);
            }
            // console.log(item.agrement_info_no, "22222222", item.montly_payment_ratio)
            if (item.montly_payment_ratio && !number_test(item.montly_payment_ratio)) {
              item.montly_payment_ratio = (item.montly_payment_ratio) / 100;
            }
            if (item.client_interest && !number_test(item.client_interest)) {
              item.client_interest = (item.client_interest) / 100;
            }
            if (item.founding && !number_test(item.founding)) {
              item.founding = (item.founding) / 100;
            }

            if (item.EOL_percentage && !number_test(item.EOL_percentage)) {
              item.EOL_percentage = (item.EOL_percentage) / 100;
            }
            if (item.commision_to_partner_percentage && !number_test(item.commision_to_partner_percentage)) {
              item.commision_to_partner_percentage = (item.commision_to_partner_percentage) / 100;
            }

            if (item.lease_start_date && item.no_of_month_lease) {
              let startDate = null
              if (moment(ExcelDateToJSDate(item.lease_start_date)).isValid()) {
                startDate = moment(ExcelDateToJSDate(item.lease_start_date)).format('YYYY-MM-DD')
              } else {
                startDate = moment((item.lease_start_date)).format('YYYY-MM-DD')
              }
              let eoldate = moment(startDate).add(item.no_of_month_lease, 'M').subtract(1, "days").format('YYYY-MM-DD')
              item.lease_start_date = startDate;
              item.eol_date = eoldate;
              let monthDiff = getMonthDiff(item.eol_date);
              item.ag_status = (getAgreementStatus(item, monthDiff).status) || "";
            }
            if (item.original_sign_date) {
              if (moment(ExcelDateToJSDate(item.original_sign_date)).isValid()) {
                item.original_sign_date = moment(ExcelDateToJSDate(item.original_sign_date)).format('YYYY-MM-DD')
              } else {
                item.original_sign_date = moment((item.original_sign_date)).format('YYYY-MM-DD')
              }
            }

            let interestProfit = 0;
            if ((item.founding && item.client_interest) && (item.client_interest > item.founding)) {
              interestProfit = Math.round((parseFloat(item.client_interest) - parseFloat(item.founding)));
            }
            item.interest_profit = interestProfit;
            let commission_amount = 0;
            if ((item.sum_purchase_value && item.commision_to_partner_percentage)) {
              commission_amount = (parseFloat(item.sum_purchase_value) * parseFloat(item.commision_to_partner_percentage)) / 100;
              item.commision_partner_amount = (commission_amount).toFixed(2);
            }

            let EOLvalueamt = 0;
            if ((item.sum_purchase_value && item.EOL_percentage)) {
              EOLvalueamt = (parseFloat(item.sum_purchase_value) * parseFloat(item.EOL_percentage)) / 100;
              item.EOL_value_amt = (EOLvalueamt).toFixed(2);
            }
            let montlypaymentvalue = 0;
            if ((item.sum_purchase_value && item.montly_payment_ratio)) {
              montlypaymentvalue = (parseFloat(item.sum_purchase_value) * parseFloat(item.montly_payment_ratio)) / 100;
              item.montly_payment_rent_or_lease = montlypaymentvalue.toFixed(2);
            } else {
              item.montly_payment_rent_or_lease = 0;
            }
            if (item.eol_date && item.notice_period_before_EOL) {
              let noticeperiodEOLDate = moment(item.eol_date).subtract(item.notice_period_before_EOL, 'M').subtract(1, "days").format('YYYY-MM-DD');
              item.latest_notice_day = noticeperiodEOLDate;
            } else {
              item.latest_notice_day = null;
            }
            if (item.latest_notice_day && item.notice_before_notice_month) {
              let noticeperiodEOLMonth = moment(item.latest_notice_day).subtract(item.notice_before_notice_month, 'M').subtract(1, "days").format('YYYY-MM-DD');
              item.notice_before_notice_day = noticeperiodEOLMonth;
            } else {
              item.notice_before_notice_day = null;
            }
            let add_purchase_value = 0;
            if ((item.sum_purchase_value && item.montly_payment_ratio && item.no_of_month_lease && item.EOL_value_amt)) {
              add_purchase_value = ((parseFloat(item.sum_purchase_value)) * (parseFloat(item.montly_payment_ratio) / 100)) * parseFloat(item.no_of_month_lease) + parseInt(item.EOL_value_amt);
              item.add_to_purchase_value = (add_purchase_value).toFixed(2);
            }
            if (item.clients_attn_notice) {
              item.clients_attn_notice = JSON.stringify(item.clients_attn_notice);
            }
            delete item.user_created;
            delete item.user_updated;
            delete item.date_created;
            delete item.date_updated;


            // item.clients_attn = clientData && clientData.users.length > 0 ? clientData.users[0].id : 0
          }
        });
        //filter null values
        // console.log("dataaaa", data)

        data = data.filter((item) => item.agrement_info_no);
        // data = data.filter((item) => item.sub_agrement_ref);
        data = _.map(data, function (user) {
          return _.omit(user, function (value, key) {
            // console.log("*********", key)
            return key.charAt(0) == "_";
          });
        });
        const lookup = data.reduce((a, e) => {
          a[e.agrement_info_no] = ++a[e.agrement_info_no] || 0;
          return a;
        }, {});
        let duplicate = []
        Object.entries(lookup).map((day, i) => {
          if (day && day[1] > 0) {
            duplicate.push(day[0])
          }
        });

        console.log("datttaa", data)
        // console.log("duplicate records", duplicate.join(","))
        /* Update state */
        setData(data);

        setCols(make_cols(ws['!ref']));
        // console.log('resetting load');

        setLoading(false);
        // console.log(data, "duplicate", duplicate)
        if (duplicate.length > 0) {
          setSuccess(true);
          setFileUploadError(true);
          setFileUploadErrorMessage(
            `Duplicate records - '${duplicate.join(', ')}' found in one or more rows`
          );
          return;
        }
        // checking all file upload errors here
        if (data.length === 0 || !data) {
          console.log('columns empty file', data);
          setSuccess(true);
          setFileUploadError(true);
          setFileUploadErrorMessage(FILE_UPLOAD_EMPTY_FILE_MESSAGE);
          return;
        }

        if (Object.keys(data[0]).indexOf('agrement_info_no') === -1) {
          setSuccess(true);
          setFileUploadError(true);
          setFileUploadErrorMessage(FILE_UPLOAD_AGG_ID_MISSING_MESSAGE);
          return;
        }

        if (data.length > FILE_UPLOAD_ROW_COUNT_ERROR) {
          setSuccess(true);
          setFileUploadError(true);
          setFileUploadErrorMessage(FILE_UPLOAD_ROW_COUNT_ERROR_MESSAGE);
          return;
        }
        setFileUploadSuccess(true);
        setSuccess(true);
        // this.setState({ data: data, cols: make_cols(ws['!ref']) });
      };
      if (rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    } catch (err) {
      setError(true);
      throw err;
    }

    // fileUploader.current.value = '';
  };

  const make_cols = (refstr) => {
    let o = [],
      C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i)
      o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
  };

  const stepContent = (stepNumber) => {
    switch (stepNumber) {
      case 0: {
        return (
          <div className={classes.steps}>
            <input
              type='file'
              ref={fileUploader}
              style={{ display: 'none' }}
              accept={'.csv, .xls, .xlsx'}
              onChange={(e) => handleFileUpload(e)}
            />
            {!loading && !success && (
              <div
                onClick={(e) => fileUploader.current.click()}
                className={classes.uploadArea}
              >
                <UploadFileIcon
                  className={'uploadIcon'}
                />
                <div className={classes.stepsText}>Select a file</div>
                <div className={classes.stepsSubText}>
                  (.csv, .xls, .xlsx.)
                </div>
              </div>
            )}
            {loading && (
              <CircularProgress className={"progressbar"} />
            )}
            {!loading && success && (
              <div className={classes.successArea}>
                {!fileUploadError && (
                  <Check
                    // icon={CheckCircleFilled}
                    className={clsx(
                      classes.uploadArea,
                      classes.uploadIcon,
                      classes.successIcon,
                      classes.animatedItem
                    )}
                  />
                )}
                {fileUploadError && (
                  <div className={classes.uploadArea}>
                    <Cancel
                      // icon={ExclamationCircleOutlined}
                      className={clsx(
                        classes.uploadIcon,
                        classes.errorIcon,
                        classes.animatedItem
                      )}
                    />
                    <div
                      className={classes.uploadArea}
                      onClick={(e) => {
                        fileUploader.current.click();
                        setTimeout(() => {
                          handleReset();
                        }, 500);
                      }}
                    >
                      Click here to try again
                    </div>
                  </div>
                )}
                {!fileUploadError && (
                  <div className={classes.stepsText}>
                    {data.length} rows found. Let's start reviewing
                  </div>
                )}
                {fileUploadError && (
                  <div className={classes.stepsTextError}>
                    {fileUploadErrorMessage}
                  </div>
                )}
                {
                  newPartner && <Link to='/partner'>Create Partner</Link>
                }
                {
                  newClient && <Link to='/client'>Create Client</Link>
                }
                {
                  newSupplier && <Link to='/suppliers'>Create Supplier</Link>
                }
              </div>
            )}
          </div>
        );
      }
      case 1: {
        return (
          <div className={classes.successArea}>
            {!loading && !success && (
              <div>
                <div className={classes.stepsText}>
                  {assetIDRows.length} records with a matching Agreement ID
                </div>
              </div>
            )}

            {loading && updateRecordsCount > 0 && <div className={classes.recordsCount}>{updateRecordsCount} of {assetIDRows.length} records completed.</div>}
            {loading && <CircularProgressWithLabel className={"progressbar"} value={Math.round((updateRecordsCount * 100) / assetIDRows.length)} />}
            {/* {loading && <Progress type="circle" style={{ paddingTop: '30px', margin: '0 auto' }} strokeColor={{ '0%': '#f80059', '100%': '#87d068', }} percent={Math.round((updateRecordsCount * 100) / assetIDRows.length)} />} */}
            {!loading && success && (
              <div>
                <div className={classes.stepsText}>Update Successful</div>
              </div>
            )}
            {!loading && error && (
              <div>
                <div className={classes.stepsTextError}>
                  {fileUploadErrorMessage}
                </div>
              </div>
            )}
          </div>
        );
      }
      case 2: {
        return (
          <div className={classes.successArea}>
            {!loading && !success && (
              <div>
                <div className={classes.stepsText}>
                  {nonAssetIDRows.length} new records found
                </div>
              </div>
            )}
            {loading && <CircularProgress color='#003366' size={30} />}
            {!loading && success && (
              <div>
                <div className={classes.stepsText}>Insertion Successful</div>
              </div>
            )}
            {!loading && error && (
              <div>
                <div className={classes.stepsTextError}>
                  {fileUploadErrorMessage}
                </div>
              </div>
            )}
          </div>
        );
      }
      default: {
        <div>Successful</div>;
      }
    }
  };
  // console.log("assetIDRows", assetIDRows)
  return (
    <Dialog
      open={open}
      //   onClose={getNewData(p)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='md'
    //   className={classes.dialogRoot}
    >
      <div className={classes.dialogRoot}>
        <DialogTitle id='alert-dialog-title' className={classes.dialogTitle}>
          <div className={classes.dialogTitleText}>
            <div>{title}</div>
          </div>
          {/* <i className="fas fa-times" {...props} ref={ref} /> */}
          <Cancel
            className={classes.actionIcon}
            onClick={() => setOpen(false)}
          />
        </DialogTitle>

        <DialogContent className={[classes.dialogContent, "fileupload"].join(" ")}>
          <DialogContentText id='alert-dialog-description'>
            <Stepper
              activeStep={currentStep}
              alternativeLabel
            // style={{ width: '700px', height: '400px' }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </DialogContentText>
          <div className={classes.stepContent}>{stepContent(currentStep)}</div>
        </DialogContent>
        <DialogActions>
          <div className={classes.buttonContainer}>
            <Button
              variant='contained'
              onClick={() => handlePrevious()}
              color='secondary'
              disabled={currentStep === 0}
              className={classes.button}
            >
              Back
            </Button>

            {currentStep !== 2 && (
              <Button
                variant='contained'
                onClick={() => handleNext()}
                color='primary'
                disabled={
                  !assetIDRows.length === 0 ||
                  !fileUploadSuccess ||
                  (assetIDRows.length > 0 &&
                    (loading || fileUploadError || !success))
                }
                className={classes.button}
              >
                Next
                {/* {loading && (
                <CircularProgress
                  size='1rem'
                  className={classes.buttonProgress}
                /> 
              )}*/}
              </Button>
            )}

            {currentStep === 1 && !success && assetIDRows.length > 0 && (
              <Button
                variant='contained'
                onClick={() => handleUpdate()}
                color='primary'
                disabled={loading}
                className={classes.button}
              >
                Update
                {loading && (
                  <CircularProgress
                    size='1rem'
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            )}
            {currentStep === 2 && !success && nonAssetIDRows.length > 0 && (
              <Button
                variant='contained'
                onClick={() => handleInsert()}
                color='primary'
                disabled={loading}
                className={classes.button}
              >
                Insert
                {loading && (
                  <CircularProgress
                    size='1rem'
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            )}
            {currentStep === 2 &&
              (nonAssetIDRows.length === 0 ||
                (nonAssetIDRows.length > 0 && success)) && (
                <Button
                  variant='contained'
                  onClick={() => {
                    getNewData(parentGridApi);
                    setOpen(false);
                  }}
                  color='primary'
                  disabled={loading}
                  className={classes.button}
                >
                  Finish
                  {loading && (
                    <CircularProgress
                      size='1rem'
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              )}
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default FileUploadDialog;
